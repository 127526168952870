import React from 'react';
import classNames from 'classnames';
import styles from './ContactCard.module.scss';
import { Icon, Heading, Picture } from 'ui/components';
import { ReactComponent as EmailIcon } from 'ui/icons/email-icon.svg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-icon.svg';
import { ReactComponent as PersonIcon } from 'ui/icons/icon-default-person.svg';

export interface ContactCardProps {
	className?: string;
	name: string | null;
	jobTitle: string | null;
	branch: string | null;
	phoneNumbers: Array<string> | null;
	email: string | null;
	categories: Array<string> | null;
	picture: Umbraco.Image | null;
	hideContactInfo?: boolean;
}

export const ContactCard: React.FC<ContactCardProps> = ({
	className,
	name,
	jobTitle,
	branch,
	phoneNumbers,
	email,
	hideContactInfo = false,
	picture,
}) => {
	return (
		<div className={classNames(styles.ContactCard, className)}>
			{picture ? (
				<section className={styles.ContactCard_mediabox}>
					<Picture relativeUrl={picture?.relativeUrl} sizes="(min-width: 768px) 50vw, 100vw" aspectRatio={1} />
				</section>
			) : (
				<div className={styles.ContactCard_placeholder}>
					<PersonIcon />
				</div>
			)}

			<section className={styles.ContactCard_details}>
				<article className={styles.ContactCard_header}>
					<Heading headingLevel="h3" style="default">
						{name}
					</Heading>
					{jobTitle && <p>{jobTitle}</p>}
					{branch && <p>{branch}</p>}
				</article>

				<article className={styles.ContactCard_body}>
					{!hideContactInfo && (
						<>
							{phoneNumbers?.length > 0 && (
								<section className={styles.ContactCard_row}>
									<Icon size="md" aria-hidden={false} className={styles.ContactCard_icon}>
										<PhoneIcon viewBox="0 1 20 17" />
									</Icon>

									{phoneNumbers?.map((phone, index) => (
										<a key={index} href={`tel:${phone}`} className={styles.ContactCard_link}>
											{phone}
										</a>
									))}
								</section>
							)}
							{email && (
								<section className={styles.ContactCard_row}>
									<Icon size="md" aria-hidden={false} className={styles.ContactCard_icon}>
										<EmailIcon viewBox="0 -0.5 20 13" />
									</Icon>

									<a href={`mailto:${email}`} className={styles.ContactCard_link}>
										{email}
									</a>
								</section>
							)}
						</>
					)}
				</article>
			</section>
		</div>
	);
};
