import classNames from 'classnames';
import styles from './Campaign.module.scss';
import { Heading, Picture, LinkButton, Icon, Container, Grid, GridCell, RichText } from 'ui/components';
import React, { useEffect } from 'react';
import { parallax } from 'helpers/parallax';
import { ReactComponent as PatternNine } from 'ui/icons/icon-pattern-nine.svg';

export interface CampaignProps {
	className?: string;
	kicker?: string;
	heading?: string;
	image?: Umbraco.Image;
	text?: string;
	link?: Umbraco.Link;
	secondaryLink?: Umbraco.Link;
	style?: 'min';
}

export const Campaign: React.FC<CampaignProps> = ({ className, heading, image, text, style, link, kicker }) => {
	useEffect(() => {
		parallax();
	}, []);
	return (
		<div className={styles.Campaign_wrapper}>
			<Container width="Standard" className={classNames(styles.Campaign, styles[`Campaign___${style}`], className)} data-container>
				<Grid wrap>
					<Icon className={styles.Campaign_pattern}>
						<PatternNine />
					</Icon>
					{image && (
						<GridCell className={styles.Campaign_media}>
							<span className={styles.Campaign_mediabox} data-parallax>
								<Picture
									className={styles.Campaign__picture}
									relativeUrl={image?.relativeUrl}
									properties={image.properties}
									focalPoint={image.focalPoint}
									aspectRatio={0.79}
									sizes="(min-width: 960px) 50vw, 100vw"
								/>
							</span>
						</GridCell>
					)}
					<aside className={styles.Campaign_contentWrapper}>
						<GridCell desktopWidth="100" className={styles.Campaign_content}>
							{kicker && <span className={styles.Campaign_kicker}>{kicker}</span>}
							<Heading headingLevel="h3" className={styles.Campaign_heading}>
								{heading}
							</Heading>
							<RichText className={classNames(styles.Campaign_text, 'RichText', className)} content={text} />
							<aside className={styles.Campaign_cta}>
								{link && (
									<LinkButton className={styles.Campaign_link} url={link.url} style="negative">
										{link.name}
									</LinkButton>
								)}
							</aside>
						</GridCell>
					</aside>
				</Grid>
			</Container>
		</div>
	);
};
