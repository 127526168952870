import classNames from 'classnames';
import styles from './SuperHeroCard.module.scss';
import { Heading, Picture, Countdown, Paragraph, Icon, RichText, SoMeLinkProps } from 'ui/components';
import { ReactComponent as PatternOne } from 'ui/icons/icon-pattern-one.svg';
import { ReactComponent as Corp } from 'ui/icons/icon-corp-brand.svg';
import React, { useEffect } from 'react';
import { parallax } from 'helpers/parallax';

export interface SuperHeroCardProps {
	cardActionLinkText?: string;
	className?: string;
	heading?: string;
	image?: Umbraco.Image;
	kicker?: string;
	text?: string;
	stickerboxheading?: string;
	stickerboxtext?: string;
	navSoMeLinks?: SoMeLinkProps[];
	style?: 'min';
}

export const SuperHeroCard: React.FC<SuperHeroCardProps> = ({
	cardActionLinkText,
	className,
	heading,
	image,
	kicker,
	text,
	stickerboxheading,
	stickerboxtext,
	style,
}) => {
	useEffect(() => {
		parallax;
	}, []);
	console.log("image",image)
	return (
		<header className={classNames(styles.SuperHeroCard, styles[`SuperHeroCard___${style}`], className)} data-container>
			<article className={styles.SuperHeroCard_textbox}>
				<div className={styles.SuperHeroCard_kicker}>{kicker}</div>
				<Heading headingLevel="h1" className={styles.SuperHeroCard_heading}>
					{heading}
				</Heading>
				<RichText className={classNames(styles.SuperHeroCard_text, 'RichText RichText___negative', className)} content={text} />
				<div className={classNames(styles.SuperHeroCard_action, 'Action Action___dark Action__block')}>{cardActionLinkText}</div>
				{image && (
					<div className={styles.SuperHeroCard_mediabox_wrapper} data-image>
						<Picture relativeUrl={image?.relativeUrl} properties={image.properties} sizes="100vw" className={styles.SuperHeroCard_mediabox} />
					</div>
				)}
			</article>
			<Icon className={styles.SuperHeroCard_Corp}>
				<Corp />
			</Icon>
			{stickerboxheading && (
				<article className={styles.SuperHeroCard_stickerboxcontent}>
					<Heading headingLevel="h2" className={styles.SuperHeroCard_stickerboxheading}>
						{stickerboxheading}
					</Heading>
					<Paragraph>{stickerboxtext}</Paragraph>
				</article>
			)}
			<Countdown />
			<Icon className={styles.SuperHeroCard_pattern}>
				<PatternOne />
			</Icon>
		</header>
	);
};
