import classNames from 'classnames';
import { RichText, Container } from 'ui/components';
import styles from './ArticleText.module.scss';
export interface ArticleTextProps {
	content: string;
	className?: string;
}

export const ArticleText: React.FC<ArticleTextProps> = ({ className, content }) => (
	<div className={classNames(styles.ArticleText, 'u-grid__cell u-grid__cell--width-100')}>
		<Container width="Standard">
			<RichText className={classNames('RichText', className)} content={content} />
		</Container>
	</div>
);
