import classNames from 'classnames';
import styles from './Accordion.module.scss';
import React from 'react';

import { AccordionItem } from './AccordionItem';

export interface AccordionProps {
	className?: string;
	allowMultiple?: boolean;
	accordionTitle?: string;
	accordionItems: Models.AccordionItem[];
}

export const Accordion: React.FC<AccordionProps> = ({ className, accordionItems, accordionTitle }) => {
	return (
		<div className={classNames(styles.Accordion, className)}>
			<h2 className={styles.Accordion_title}>{accordionTitle}</h2>
			{accordionItems.map((accordionItem, index) => (
				<AccordionItem {...accordionItem} key={index} />
			))}
		</div>
	);
};
