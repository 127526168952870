import classNames from 'classnames';
import { Container, Grid, GridCell, Icon, RichText, SocialShare } from 'ui/components';
import { ReactComponent as PatternTwo } from 'ui/icons/icon-pattern-two.svg';
import styles from './ArticleTextNews.module.scss';

export interface ArticleTextNewsProps {
	className?: string;
	content: string;
	currentUrl: string;
	ogTitle: string;
}

export const ArticleTextNews: React.FC<ArticleTextNewsProps> = ({ className, content, currentUrl, ogTitle }) => {
	return (
		<>
			<Container width="Standard" className={classNames(styles.ArticleTextNews, className)}>
				<Grid wrap>
					<GridCell desktopWidth="25" mobileWidth="100">
						<div className={styles.ArticleTextNews_social}>
							<SocialShare currentUrl={currentUrl} ogTitle={ogTitle} />
						</div>
					</GridCell>
					<GridCell desktopWidth="75" mobileWidth="100">
						<RichText className={classNames(styles.ArticleText, 'RichText')} content={content} />
					</GridCell>
				</Grid>
			</Container>
			<Icon className={styles.ArticleTextNews_pattern}>
				<PatternTwo />
			</Icon>
		</>
	);
};
