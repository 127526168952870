import classNames from 'classnames';
import styles from './AboutSection.module.scss';
import { Heading, Paragraph, Picture, LinkButton, Container, Grid, GridCell } from 'ui/components';

import { shouldShowContent } from 'helpers/audiencehelper';
import { Spinner } from 'ui/components/1-atoms/Media/Spinner';
export interface AboutSectionProps {
	className?: string;
	header?: string;
	moduleLink?: Umbraco.Link;
	text?: string;
	image: Umbraco.Image;
	styleRevert?: boolean;
	styleFlip?: boolean;
	audiencePicker: Umbraco.AudiencePicker[];
}

export const AboutSection: React.FC<AboutSectionProps> = ({ className, header, text, image, moduleLink, styleRevert, styleFlip, audiencePicker }) => {
	const target = moduleLink?.target || moduleLink?.target == '' ? moduleLink?.target : '_self';
	return (
		<section
			className={classNames(
				styles.AboutSection,
				{ [styles.AboutSection___revert]: styleRevert },
				{ [styles.AboutSection___flip]: styleFlip },
				className,
			)}
		>
			<Container width="Standard">
				<Grid wrap className={styles.AboutSection_grid}>
					<GridCell desktopWidth="50" className={styles.AboutSection_contentWrapper}>
						<Heading className={styles.AboutSection_heading} headingLevel="h3">
							{' '}
							{header}{' '}
						</Heading>
						{text && <Paragraph className={styles.AboutSection_text}>{text}</Paragraph>}
						<div className={styles.AboutSection_buttonWrapper}>
							{moduleLink && (
								<LinkButton className={styles.AboutSection_link} url={moduleLink.url} style="secondary" target={target}>
									{moduleLink.name}
								</LinkButton>
							)}
						</div>
					</GridCell>
					<GridCell desktopWidth="50" className={styles.AboutSection_imageWrapper}>
						{image && (
							<span className={styles.AboutSection_mediabox}>
								<Picture
									relativeUrl={image?.relativeUrl}
									properties={image.properties}
									focalPoint={image.focalPoint}
									aspectRatio={0.79}
									sizes="(min-width: 960px) 50vw, 100vw"
								/>
							</span>
						)}
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};

/*
const shouldShowContent1 = (audiencePicker: Umbraco.AudiencePicker[]) : boolean=> {
	// Check if audiencePicker exists and has elements
	let show = true;
	// checking if Audience picker exists, and has elements 
	if (audiencePicker && audiencePicker.length > 0)
		{
			const profileContext = useContext(ProfileContext);
	
			const auds = profileContext?.audiences
			console.log("auds",auds)
			// checking on audiences from API, as to not get null pointers 
			if (auds)
			{
				
			const overlappingaudience =  getOverlappingAudience(audiencePicker,auds)
			if (overlappingaudience && !overlappingaudience.content.properties.Exclude)
			{
				show = true;
			} else // If either user is not in a audience mentioned, or he is, but the audience is excluded
			{
				show = false;
			}
			}
			
		}

		return show;
  };
  
 const shouldShowContent = (audiencePicker: Umbraco.AudiencePicker[]) : boolean=> {
	// Check if audiencePicker exists and has elements
	if (!audiencePicker || audiencePicker.length === 0) return false;
	const profileContext = useContext(ProfileContext);
	// Extract audiences from profile context, if available
	const availableAudiences = profileContext?.audiences;
	if (!availableAudiences) 
		{
			return false;
		}
  
	// Determine if there is an overlapping audience that is not excluded
	const overlappingAudience = getOverlappingAudience(audiencePicker, availableAudiences);
	const isAudienceValid = overlappingAudience && (!overlappingAudience.content?.properties?.Exclude ?? false);
  
	return isAudienceValid;
  };

const getOverlappingAudience = (
	audiencePicker: Umbraco.AudiencePicker[],
	audiencesForUser: { audiences: { name: string; id: string }[] }
  ): Umbraco.AudiencePicker | undefined => {

	if (!audiencePicker || audiencePicker.length === 0) return undefined;
  
	return audiencePicker?.find((aud1) =>
	  audiencesForUser.audiences.some(
		(aud2) => aud2.name.toLowerCase() === 
		aud1?.content?.properties?.link?.properties?.audienceName.toLowerCase()
	  )
	);
  };  */
