import classNames from 'classnames';
import styles from './ListNewsModule.module.scss';
import { NewsModuleCard } from 'ui/components';

export interface ListNewsModuleProps {
	className?: string;
	items: Models.NewsCard[];
	desktopWidth?: Models.GridCellWidth;
	mobileWidth?: Models.GridCellWidth;
	link?: Umbraco.Link;
}

export const ListNewsModule: React.FC<ListNewsModuleProps> = ({ className, items }) => {
	if (!items) return null;

	return (
		<>
			{items.slice(0, 6).map((args, index) => (
				<NewsModuleCard index={index} key={index} {...args} />
			))}
		</>
	);
};
