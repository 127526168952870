import { getProfilesAdapter, GetProfilesResult } from 'application/adapters/profiles/profilesAdapter';

export type Profiles = {
	firstName: string;
	lastName: string;
	address: {
		line1: string;
		line2: string;
		postNumber: string;
		city: string;
		country: string;
		secretAddress: boolean;
	};
	membership: {
		id: string;
		startDate: string;
		category: string;
	};
	branch: {
		id: string;
		name: string;
	};
	notificationCount: number;
	education: {
		start: string;
		completed: string;
		expectedCompleted: string;
		name: string;
		school: string;
	};
	employment: {
		date: string;
		employer: {
			pNumber: string;
			postCode: string;
			cvr: string;
			name: string;
			agreement: {
				id: number;
				name: string;
				link: string;
			};
		};
		jobTitle: string;
	};
	duties: string[];
	privateContact: {
		email: string;
		phone: string;
	};
	workContact: {
		email: string;
		phone: string;
	};
	insurance: string;
	unionRepresentative: {
		firstname: string;
		lastname: string;
		duty: string;
		workContactInformation: {
			email: string;
			phone: string;
		};
		privateContactInformation: {
			email: string;
			phone: string;
		};
	};
	healthAndSafetyRepresentative: {
		firstname: string;
		lastname: string;
		duty: string;
		workContactInformation: {
			email: string;
			phone: string;
		};
		privateContactInformation: {
			email: string;
			phone: string;
		};
	};
	canSeeUnionRepresentativeWebsite: boolean;
};
export const getProfiles = async (hostname: string): Promise<Profiles> => {
	const profiles = await getProfilesAdapter(hostname);
	return profiles;
};
