import classNames from 'classnames';
import { ComponentProps } from 'react';
import styles from './Button.module.scss';

type ButtonProps = ComponentProps<'button'> & {
	style?: 'primary' | 'secondary' | 'tertiary' | 'negative';
};
export const Button: React.FC<ButtonProps> = ({
	style = 'primary',
	className,
	type = 'button',
	disabled,
	'aria-disabled': ariaDisabled = disabled,
	children,
	...restProps
}) => (
	<button
		className={classNames(styles.Button, styles[`Button___${style}`], className)}
		type={type}
		disabled={disabled}
		aria-disabled={ariaDisabled}
		{...restProps}
	>
		<span>{children}</span>
	</button>
);
