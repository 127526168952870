import React from 'react';
import { VerticalTabMenu } from '../VerticalTabMenu';
import { TabMenu } from '../TabMenu';
import { AgreementContent } from './AgreementContent';
import styles from './Agreement.module.scss';
import { Container } from 'ui/components';

type ChapterMetaData = {
	id: string;
	title: string;
};

type SectionMetaData = {
	id: string;
	title: string;
};

type AgreementProps = {
	chapters: ChapterMetaData[];
	selectedChapterValue: string;
	onChapterChange: (chapterId: string) => void;
	sections: SectionMetaData[];
	selectedSectionValue: string;
	onSectionChange: (sectionId: string) => void;
	contentHtml: string;
	expandButtonText: string;
	collapseButtonText: string;
};
export const Agreement: React.VFC<AgreementProps> = ({
	chapters,
	selectedChapterValue,
	onChapterChange,
	sections,
	selectedSectionValue,
	onSectionChange,
	contentHtml,
	expandButtonText,
	collapseButtonText,
}) => {
	return (
		<Container width="Standard" className={styles.agreementWrapper}>
			<TabMenu
				items={chapters.map((chapter) => ({
					value: chapter.id,
					label: chapter.title,
				}))}
				selectedItemValue={selectedChapterValue}
				onItemClick={(value) => onChapterChange(value)}
			/>
			<div className={styles.chapterContentWrapper}>
				<div className={styles.verticalTabMenuWrapper}>
					<VerticalTabMenu
						items={sections.map((section) => ({
							value: section.id,
							label: section.title,
						}))}
						selectedItemValue={selectedSectionValue}
						onItemClick={(value) => onSectionChange(value)}
					/>
				</div>
				<AgreementContent content={contentHtml} expandButtonText={expandButtonText} collapseButtonText={collapseButtonText} />
			</div>
		</Container>
	);
};
