import classNames from 'classnames';
import styles from './NewsModuleCard.module.scss';
import { Picture, Heading, Container, Grid, GridCell } from 'ui/components';

import { formatDate, formatTime } from 'helpers/date';
import { database } from 'faker';

interface NewsModuleCardInterface extends Models.NewsModuleCard {
	useDate?: boolean; //use date created and ignore updated date
}

export const NewsModuleCard: React.FC<NewsModuleCardInterface> = ({
	link,
	className,
	heading,
	kicker,
	date,
	image,
	index,
	updatedDate,
	useDate = false,
}) => {
	const formatedDate = formatDate(date, 'dd.mm.yyyy');
	const nullDate = formatedDate === '31.12.0';
	const dateToUse = date;
	if (index === 0) {
		return (
			<article className={styles.NewsModuleCard_latest}>
				<a className={styles.NewsModuleCard_link} href={link?.url} target={link?.target} title={link?.name}>
					{image && (
						<div className={styles.NewsModuleCard_media}>
							<span className={styles.NewsModuleCard_mediabox}>
								<Picture
									aspectRatio={0.5625}
									relativeUrl={image?.relativeUrl}
									properties={image.properties}
									focalPoint={image.focalPoint}
									sizes="100vw"
								/>
							</span>
						</div>
					)}
					<div className={styles.NewsModuleCard_textbox}>
						{kicker && <div className={styles.NewsModuleCard_kicker}>{kicker}</div>}
						<div className={styles.NewsModuleCard_date}>
							{formatDate(dateToUse, 'dd.mm')} kl. {formatTime(dateToUse, 'hh.mm')}
						</div>
						<h3 className={styles.NewsModuleCard_headingLarge}>{heading}</h3>
					</div>
				</a>
			</article>
		);
	}
	return (
		<article className={styles.NewsModuleCard_list}>
			<a className={styles.NewsModuleCard_link} href={link?.url} target={link?.target} title={link?.name}>
				<div className={styles.NewsModuleCard_textbox}>
					{kicker && <div className={styles.NewsModuleCard_kicker}>{kicker}</div>}
					{/* <div className={styles.NewsModuleCard_date}>
						{formatDate(dateToUse, 'dd.mm')} kl. {formatTime(dateToUse, 'hh.mm')}
					</div> */}
					{!nullDate ? (
						<div className={styles.NewsModuleCard_date}>
							{formatDate(date, 'dd.mm')} KL. {formatTime(date, 'hh.mm')}
						</div>
					) : (
						<div className={styles.NewsModuleCard_date}>
							{formatDate(updatedDate, 'dd.mm')} KL. {formatTime(updatedDate, 'hh.mm')}
						</div>
					)}
					<Heading headingLevel="h3">{heading}</Heading>
				</div>
			</a>
		</article>
	);
};
