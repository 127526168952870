import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import styles from './Picture.module.scss';
import { GetImageUrl, GetSrcSetString } from 'helpers/getImageUrl';
import config from 'helpers/imageSizes';
import { inView } from 'helpers/inView';

export interface PictureProps extends Umbraco.Image {
	sizes?: string;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	isCover?: boolean;
	aspectRatio?: number;
	className?: string;
	useCustomPosition?: boolean;
	style?: any;
	height?: number;
}

export const Picture: React.FC<PictureProps> = ({
	isCover = true,
	sizes = '100vw',
	relativeUrl,
	lazyload = true,
	objectFitFallback = true,
	properties,
	focalPoint,
	aspectRatio,
	className,
	useCustomPosition,
	style,
	height,
}) => {
	const { thumbnailSize, fallbackSize } = config.images;

	const thumbnailImageUrl = GetImageUrl({ relativeUrl, width: thumbnailSize, height });
	const thumbnailImageUrlWebp = GetImageUrl({ relativeUrl, width: thumbnailSize, format: 'webp', height });

	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);

	const pictureRef = useRef(null);

	useEffect(() => {
		if (objectFitFallback) objectFit();
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
	}, [relativeUrl]);

	const reveal = () => {
		const srcSetString = GetSrcSetString({ relativeUrl, focalPoint, aspectRatio });
		const srcSetStringWebp = GetSrcSetString({ relativeUrl, focalPoint, format: 'webp', aspectRatio });
		const srcString = GetImageUrl({ relativeUrl, width: fallbackSize, height });

		setImgSrcSet(srcSetString);
		setSourceSrcSet(srcSetStringWebp);
		setSrc(srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};
	const leftCoordinationPercent = focalPoint?.left * 100;
	const topCoordinationPercent = focalPoint?.top * 100;
	return (
		<picture
			ref={pictureRef}
			className={classNames(styles.Picture, { [styles.Picture___cover]: isCover }, { [styles.isFallback]: isFallback }, className)}
			style={style}
		>
			{!isFallback && (
				<>
					<source sizes={sizes} type="image/webp" srcSet={sourceSrcSet} />
					<img
						src={src}
						alt={properties?.altText}
						className={styles.Picture_asset}
						srcSet={imgSrcSet}
						sizes={sizes}
						style={
							useCustomPosition && {
								objectPosition: `${leftCoordinationPercent?.toString()}% ${topCoordinationPercent?.toString()}% `,
							}
						}
					/>
				</>
			)}
		</picture>
	);
};
