import axios, { AxiosResponse } from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from './constants';

export type GetContactResult = {
	defaultBranch: string;
	branches: { label: string; value: string; address: string, memberPortalUrl:string }[];
};
export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};
export const getContact = async (hostname: string): Promise<AxiosResponse<GetContactResult>> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl(`branch/GetBranches`);
	//const href = 'data/contact.json';
	return axios.get<GetContactResult>(href, { headers: headers });
};
