import classNames from 'classnames';
import styles from './ArrangementCard.module.scss';
import { ReactComponent as Clock } from 'ui/icons/icon-clock.svg';
import { ReactComponent as Date } from 'ui/icons/icon-date.svg';
import { ReactComponent as Location } from 'ui/icons/icon-location.svg';
import { ReactComponent as Chevron } from 'ui/icons/icon-chevron-circle.svg';
import React from 'react';
import { ConditionalWrapper } from 'ui/components';

export interface ArrangementCardProps {
	id?: number;
	title?: string | null;
	date?: string | null;
	time?: string | null;
	location?: string | null;
	infoText?: string | null;
	url?: string | null;
	labels?: Array<{
		name?: string | null;
		hexCode?: string | null;
	}> | null;
	isLoggedIn?: boolean;
}

export const ArrangementCard: React.FC<ArrangementCardProps> = ({ title, date, time, location, infoText, labels, url, isLoggedIn }) => {
	return (
		<div className={styles.ArrangementCard_wrapper}>
			{labels?.length > 0 &&
				labels.map((label, index) => (
					<div key={index} className={classNames(styles.ArrangementCard_label)} style={{ backgroundColor: label?.hexCode }}>
						{label?.name}
					</div>
				))}

			<ConditionalWrapper
				condition={isLoggedIn}
				wrapper={(children) => {
					if (isLoggedIn) {
						return (
							<a href={url} title={title} className={styles.ArrangementCard_link}>
								{children}
							</a>
						);
					}
					return children;
				}}
			>
				<div
					className={classNames(
						styles.ArrangementCard,
						(!labels || labels?.length == 0) && styles.ArrangementCard_marginTop,
						!isLoggedIn && styles.ArrangementCard_noLinkIcon,
					)}
				>
					<div className={styles.ArrangementCard_heading_container}>
						<h3 className={styles.ArrangementCard_heading}>{title}</h3>
					</div>

					<div className={styles.ArrangementCard_infoLine}>
						<div className={styles.ArrangementCard_icon}>
							<Clock />
						</div>

						<div className={styles.ArrangementCard_text}>{date}</div>
					</div>

					<div className={styles.ArrangementCard_infoLine}>
						<div className={styles.ArrangementCard_icon}>
							<Date />
						</div>

						<div className={styles.ArrangementCard_text}>{`${time?.slice(0, 1)?.toUpperCase()}${time?.slice(1)}`}</div>
					</div>

					<div className={styles.ArrangementCard_infoLine}>
						<div className={styles.ArrangementCard_icon}>
							<Location />
						</div>

						<div className={styles.ArrangementCard_text}>{location}</div>
					</div>

					{infoText && <div className={styles.ArrangementCard_infoText}>{infoText}</div>}

					{isLoggedIn && (
						<div className={styles.ArrangementCard_linkIcon}>
							<Chevron />
						</div>
					)}
				</div>
			</ConditionalWrapper>
		</div>
	);
};
