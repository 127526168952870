import classNames from 'classnames';
import { GridCell } from 'ui/components/4-habitats/Grid';
import styles from './FooterCard.module.scss';

export interface FooterCardProps {
	className?: string;
}

export const FooterCard: React.FC<FooterCardProps> = ({ children, className }) => {
	return (
		<GridCell className={classNames(styles.FooterCard_cells, className)} mobileWidth="100" desktopWidth="25">
			{children}
		</GridCell>
	);
};
