import { FC, ReactNode } from 'react';
import styles from './SectionContentContainer.module.scss';

type SectionContentContainerProps = {
	children: ReactNode;
};
export const SectionContentContainer: FC<SectionContentContainerProps> = ({ children }) => {
	return <div className={styles.container}>{children}</div>;
};

