import { useEffect, useRef, useState } from 'react';
import styles from './Countdown.module.scss';
import classNames from 'classnames';
import { Paragraph } from 'ui/components';
import { checkIfSSR } from 'helpers/checkIfSSR';

export interface CountdownProps {
	className?: string;
}

export const Countdown: React.FC<CountdownProps> = ({ className }) => {
	const currentYear = new Date().getFullYear();

	//event date
	const departure = new Date(`November 8 ${currentYear} 10:00:00`);

	//Update Countdowntime
	function updateCountdown() {
		const currentTime = new Date();
		const diff = departure.valueOf() - currentTime.valueOf();

		const day = Math.floor(diff / 1000 / 60 / 60 / 24);
		const hour = Math.floor(diff / 1000 / 60 / 60) % 24;
		const minute = Math.floor(diff / 1000 / 60) % 60;
		const second = Math.floor(diff / 1000) % 60;

		if (!checkIfSSR()) {
			document.querySelector('#days').innerHTML = '' + day;
			document.querySelector('#hours').innerHTML = '' + hour;
			document.querySelector('#minutes').innerHTML = '' + minute;
			document.querySelector('#seconds').innerHTML = '' + second;
		}
	}

	setInterval(updateCountdown, 1000);

	return (
		<article id="countdown" className={classNames(styles.Countdown, className)}>
			<Paragraph className={styles.Countdown_Headline}>Er du klar?</Paragraph>
			<div className={styles.Countdown_Wrapper}>
				<div className={styles.Countdown_Time}>
					<h2 className={styles.Countdown_Digits} id="days"></h2>
					<small className={styles.Countdown_Text}>Dage</small>
				</div>

				<div className={styles.Countdown_Time}>
					<h2 className={styles.Countdown_Digits} id="hours"></h2>
					<small className={styles.Countdown_Text}>Timer</small>
				</div>

				<div className={styles.Countdown_Time}>
					<h2 className={styles.Countdown_Digits} id="minutes"></h2>
					<small className={styles.Countdown_Text}>Minutter</small>
				</div>

				<div className={styles.Countdown_Time}>
					<h2 className={styles.Countdown_Digits} id="seconds"></h2>
					<small className={styles.Countdown_Text}>Sekunder</small>
				</div>
			</div>
		</article>
	);
};
