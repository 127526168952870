import styles from './Filter.module.scss';
import { Form, FormfieldSelect } from 'ui/components';

export interface FilterProps {
	className?: string;
	primaryRadio?: string;
	secondaryRadio?: string;
	primaryValue?: string;
	secondaryValue?: string;
	toggleChange: any;
	sortChange: any;
}

export const Filter: React.FC<FilterProps> = ({
	className,
	primaryRadio,
	secondaryRadio,
	primaryValue,
	secondaryValue,
	toggleChange,
	sortChange,
}) => {
	return (
		<section className={styles.Filter}>
			<div className={styles.Filter_filterTabs}>
				<input value="Nyhed" className={styles.Filter_filterInput} onChange={toggleChange} type="radio" id="radio-1" name="tabs" />
				<label className={styles.Filter_filterTab} htmlFor="radio-1">
					Nyheder
				</label>
				<input value="Presse" className={styles.Filter_filterInput} onChange={toggleChange} type="radio" id="radio-2" name="tabs" />
				<label className={styles.Filter_filterTab} htmlFor="radio-2">
					Presse
				</label>
				<span className={styles.Filter_filterGlider}></span>
				<Form className={styles.Filter_form}>
					<FormfieldSelect
						className={styles.Filter_select}
						id="select"
						name="select"
						label=""
						defaultValue=""
						onChange={sortChange}
						options={[
							{
								value: '',
								text: 'Alle dage',
							},
							{
								value: 'Dag 1',
								text: 'Dag 1',
							},
							{
								value: 'Dag 2',
								text: 'Dag 2',
							},
							{
								value: 'Dag 3',
								text: 'Dag 3',
							},
							{
								value: 'Dag 4',
								text: 'Dag 4',
							},
						]}
					/>
				</Form>
			</div>
		</section>
	);
};

