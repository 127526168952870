import config from 'helpers/imageSizes';
import { getFullImageUrl } from './getFullImageUrl';

interface ImageProps extends Umbraco.Image {
	width: number;
	height?: number;
	format?: 'webp';
}

export const GetImageUrl = ({ relativeUrl, focalPoint, width, height = 0, format }: ImageProps): string => {
	if (!relativeUrl) return '';

	let imageUrl = `${getFullImageUrl(relativeUrl)}?width=${width}`;

	if (height > 0 && focalPoint)
		imageUrl = `${imageUrl}&height=${height}&mode=crop&rxy=${focalPoint.left.toString().replace(',', '.')},${focalPoint.top
			.toString()
			.replace(',', '.')}`;

	if (format) imageUrl = `${imageUrl}&format=webp`;

	return imageUrl;
};

export const GetSrcSetString = ({
	relativeUrl,
	focalPoint,
	format,
	aspectRatio,
}: {
	relativeUrl: string;
	focalPoint: { left: number;top: number; };
	format?: 'webp';
	aspectRatio?: number;
}): string => {
	const { imageSizes } = config.images;

	let string = '';

	imageSizes.map((width) => {
		let height = 0;

		if (aspectRatio) {
			height = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			relativeUrl,
			focalPoint,
			height,
			width: width,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};
