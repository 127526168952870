import classNames from 'classnames';
import { Container, Grid, GridCell, NewsModuleCard, Spinner } from 'ui/components';
import { Heading, LinkButton } from 'ui/components/1-atoms';
import styles from './LatestNewsModule.module.scss';

/*
 *
 * This component is used on all sites except kongres.def.dk. For kongress, see NewsModuleFeature.
 *
 */

export interface LatestNewsModuleProps {
	className?: string;
	header?: string;
	moduleLink?: Umbraco.Link;
	newsFromApi?: Models.NewsCard[];
}

export const LatestNewsModule: React.FC<LatestNewsModuleProps> = ({
	className,
	header,
	moduleLink,
	newsFromApi = null, // default to null if not specified, so we can use it in a ternary
}) => {
	return (
		<section className={classNames(styles.LatestNewsModule, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell>
						<section className={styles.LatestNewsModule_wrapper}>
							<div className={styles.LatestNewsModule_container}>
								<Heading className={styles.LatestNewsModule_heading} headingLevel="h3">
									{' '}
									{header}{' '}
								</Heading>
								<div className={styles.LatestNewsModule_contentWrapper}>
									{!newsFromApi ? (
										<Spinner color="white" />
									) : newsFromApi.length > 0 ? (
										<>
											{newsFromApi.slice(0, 6).map((args, index) => (
												<NewsModuleCard index={index} key={args?.id} {...args} useDate />
											))}
										</>
									) : (
										<p>Ingen nyheder fundet</p>
									)}
								</div>
								<div className={styles.LatestNewsModule_buttonWrapper}>
									{moduleLink && (
										<LinkButton className={styles.ThumbnailList_link} url={moduleLink.url} style="secondary">
											{moduleLink.name}
										</LinkButton>
									)}
								</div>
							</div>
						</section>
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
