import classNames from 'classnames';
import { ComponentProps } from 'react';
import styles from './TabMenuItem.module.scss';

type TabMenuItemProps = ComponentProps<'button'> & {
	selected: boolean;
	onClick: () => void;
	className?: string;
};
export const TabMenuItem: React.FC<TabMenuItemProps> = ({ selected, onClick, className, children, ...restProps }) => {
	return (
		<button
			className={classNames(
				styles.button,
				{
					[styles.button___selected]: selected,
				},
				className,
			)}
			onClick={onClick}
			{...restProps}
		>
			<div className={styles.contentWrapper}>{children}</div>
		</button>
	);
};
