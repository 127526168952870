import classNames from 'classnames';
import { BlurbDownloadCard, Grid, GridCell, Container, BlurbDownloadCardProps, Icon } from 'ui/components';

import { ReactComponent as PatternFive } from 'ui/icons/icon-pattern-five.svg';

import styles from './ListDownload.module.scss';

export interface ListDownloadProps {
	className?: string;
	items: BlurbDownloadCardProps[];
}

export const ListDownload: React.FC<ListDownloadProps> = ({ className, items }) => {
	if (!items) return null;

	return (
		<Container width="Standard" className={classNames(styles.ListDownload, className)}>
			<Grid full wrap>
				<Icon className={styles.List_pattern}>
					<PatternFive />
				</Icon>
				<GridCell className={styles.List_rows}>
					{items.map((args, index) => (
						<BlurbDownloadCard key={index} {...args} />
					))}
				</GridCell>
			</Grid>
		</Container>
	);
};
