import classNames from 'classnames';
import { Icon, Link } from 'ui/components';
import { ReactComponent as CorpBrandSmallIcon } from 'ui/icons/icon-corp-brand-small.svg';
import styles from './NavigationMember.module.scss';

export interface NavigationMemberProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItemsMember?: Models.NavigationItem[];
}

export const NavigationMember: React.FC<NavigationMemberProps> = ({ className, style, navigationItemsMember }) => {
	return (
		<nav className={classNames(styles.NavigationMember, styles[`NavigationMember___${style}`], className)} role="NavigationMember">
			<ul className={styles.NavigationMember_list}>
				{navigationItemsMember &&
					navigationItemsMember.map((navigationMemberItem: Models.NavigationItem, index) => (
						<li key={index} className={classNames(styles.NavigationMember_item)}>
							<Link
								className={classNames(styles.NavigationMember_link, {
									[styles.isActive]: navigationMemberItem.state === 'isActive',
								})}
								url={navigationMemberItem.url}
								title={navigationMemberItem.title}
							>
								{/* Because of umbraco implementation the svg is used as src on a img tag */}
								<img
									className={classNames(styles.NavigationMember_icon, {
										[styles.isActive]: navigationMemberItem.state === 'isActive',
									})}
									src={navigationMemberItem?.icon}
									alt="navigationIcon"
								/>

								<img
									className={classNames(styles.NavigationMember_iconHover, {
										[styles.isActive]: navigationMemberItem.state === 'isActive',
									})}
									src={navigationMemberItem?.iconHover}
									alt="navigationIcon"
								/>

								<span className={styles.NavigationMember_title}>{navigationMemberItem.title}</span>
							</Link>
						</li>
					))}
				<li>
					<Icon className={styles.NavigationMember_corpBrand} size="md">
						<CorpBrandSmallIcon />
					</Icon>
				</li>
			</ul>
		</nav>
	);
};
