export const getFullImageUrl = (url: string): string => {
	const contentApiUrl = process.env.NEXT_PUBLIC_CONTENT_API_URL;

	let imageUrl = url ?? '';

	if (!imageUrl.includes('http')) {
		imageUrl = contentApiUrl + imageUrl;
	}
	return imageUrl;
};
