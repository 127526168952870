import classNames from 'classnames';
import { Icon, Link } from 'ui/components';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import styles from './NavigationPortal.module.scss';

export interface NavigationPortalProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItemsPortal?: Models.NavigationItem[];
}

export const NavigationPortal: React.FC<NavigationPortalProps> = ({ className, style, navigationItemsPortal }) => (
	<nav className={classNames(styles.NavigationPortal, styles[`NavigationPortal___${style}`], className)} role="NavigationPortal">
		<ul className={styles.NavigationPortal_list}>
			{navigationItemsPortal &&
				navigationItemsPortal.map((navigationPortalItem: Models.NavigationItem, index) => (
					<li key={index} className={classNames(styles.NavigationPortal_item, 'u-grid__cell')}>
						<Link
							className={classNames(styles.NavigationPortal_link, {
								[styles.isActive]: navigationPortalItem.state === 'isActive',
							})}
							url={navigationPortalItem.url}
							title={navigationPortalItem.title}
						>
							{/* Because of umbraco implementation the svg is used as src on a img tag */}
							<img
								className={classNames(styles.NavigationPortal_icon, {
									[styles.isActive]: navigationPortalItem.state === 'isActive',
								})}
								src={navigationPortalItem?.icon}
								alt="navigationIcon"
							/>

							<span className={styles.NavigationPortal_title}>{navigationPortalItem.title}</span>

							<Icon className={styles.NavigationPortal_chevron} size="md">
								<ChevronRight />
							</Icon>
						</Link>
					</li>
				))}
		</ul>
	</nav>
);
