import { Breadcrumb } from '../Navigation';
import { useContext } from 'react';
import { PageContext } from 'application/adapters/context/PageContext';
import { useRouter } from 'next/router';
import { Container } from 'ui/components/4-habitats/Container';
import { Console } from 'console';
import Page from 'pages/404';

export interface BreadcrumbsProps {
	className?: string;
	withText?: boolean;
	sectionWidth?:Umbraco.SectionWidth;
}


export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ sectionWidth,className }) => {
	const pageContext = useContext(PageContext);
	let isGlobalContent = false;
	const router = useRouter()
	const path = router?.asPath
	let  width = sectionWidth  ? sectionWidth : "Standard"
	if(pageContext.template == 'GlobalNews')
	{
		isGlobalContent = true;
		width = "Small";
	} 


	const isBreadCrumbOn = pageContext.breadCrumbOn;
	let breadcrumbs = pageContext.breadCrumb;
	
	if (path !== '/' &&  breadcrumbs?.length ) {
		return (
			<>
				{isBreadCrumbOn && (
					<Container width={width}>
						<Breadcrumb isGlobalContent={isGlobalContent} url='' breadcrumbItems={breadcrumbs} rootName={pageContext.rootname} />
					</Container>
				)
				}
			</>

		)
	}
	else {
		return null;
	}
}