import classNames from 'classnames';
import styles from './Footer.module.scss';
import { Container, Newsletter, NewsletterProps, FooterCard, Grid, Logo, Icon, Link, Paragraph } from 'ui/components';
import React from 'react';
import { FooterCard as FooterCardWrapper } from './FooterCard';
import { GridCell } from 'ui/components/4-habitats/Grid';
import { ReactComponent as Corp } from 'ui/icons/icon-corp-brand-secondary.svg';
import { ReactComponent as FacebookIcon } from 'ui/icons/facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from 'ui/icons/linkedin-icon.svg';

export interface FooterProps {
	className?: string;
	emailLabel?: string;
	moduleLink?: Umbraco.Link;
}

const Footer: React.FC<FooterProps> & { Card: React.FC } = ({ className, children, emailLabel, moduleLink }) => {
	return (
		<footer className={classNames(styles.Footer, className)} role="contentinfo">
			<Container className={styles.Footer_container} width="Standard">
				<Grid className={styles.Footer_inner} wrap={true} container={true}>
					<GridCell mobileWidth="100" desktopWidth="10">
						<article className={styles.Footer_logoWrapper}>
							<Logo withText={false} />
						</article>
					</GridCell>
					{children}
				</Grid>
			</Container>
			<Container width="Standard">
				<Grid className={styles.Footer_containerSoMe} center={true} wrap={true} container={true}>
					<GridCell mobileWidth="100" desktopWidth="10">
						<aside className={styles.Footer_SoMe}>
							<Link className={styles.Footer_SoMeLink} url="https://www.facebook.com/DanskElForbund">
								<Icon className={styles.Footer_SoMeLinkIcon}>
									<FacebookIcon />
								</Icon>
								{/* <Paragraph className={styles.Footer_SoMeLinkText}>Facebook</Paragraph> */}
							</Link>
							<Link className={styles.Footer_SoMeLink} url="https://www.linkedin.com/company/danskelforbund/">
								<Icon className={styles.Footer_SoMeLinkIcon}>
									<LinkedinIcon />
								</Icon>
								{/* <Paragraph className={styles.Footer_SoMeLinkText}>LinkedIn</Paragraph> */}
							</Link>
						</aside>
					</GridCell>
				</Grid>
			</Container>
		</footer>
	);
};

Footer.Card = FooterCardWrapper;

export { Footer };
