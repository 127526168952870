import { checkIfSSR } from 'helpers/checkIfSSR';
import Script from 'next/script';
import React, { useEffect } from 'react';
import styles from './CludoArrangementsResults.module.scss';

export interface CludoArrangementsResultsProps {
	heading?: string;
}

export const CludoArrangementsResults: React.FC<CludoArrangementsResultsProps> = ({ heading }) => {
	return (
		<div className={styles.CludoArrangementsResults}>
			{heading && <h2>{heading}</h2>}
			<Script src="/js/cludoArrangements.js" strategy="afterInteractive" />
			<div id="cludo-search-results" className="cludo-search-results">
				<div className="cludo-input-form__events" role="search" id="cludo-search-input__events">
					<input
						className="cludo-input-form__input"
						title="Search the page"
						name="searchrequest"
						type="search"
						autoComplete="off"
						placeholder="Søg i arrangementer"
						aria-autocomplete="list"
						aria-haspopup="true"
					/>
					<button type="submit" title="Search" className="cludo-input-form__search-button">
						<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M16.991 16.992l8.384 8.383M11.033 19.44a8.408 8.408 0 100-16.815 8.408 8.408 0 000 16.815z"
								stroke="#fff"
								strokeWidth="2"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							></path>
						</svg>
					</button>
				</div>

				<div className="cludo-search-results__layout">
					<div className="cludo-search-results__did-you-mean search-did-you-mean"></div>
					<div className="cludo-search-results__facets search-filters" aria-controls="search-results"></div>

					<div className="cludo-search-results__results-wrapper">
						<div className="cludo-search-results__search-result-count search-result-count" role="status"></div>
						<div
							className="cludo-search-results__results search-results"
							role="region"
							id="search-results"
							aria-live="polite"
							aria-label="Search results"
						></div>
						<div className="cludo-hidden" id="cludo-load-more">
							<button className="view-more cludo-search__top-hits-view-more cludo-view-all-button">Vis flere</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
