export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
export const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * 
 * @param string: The original string
 * @param from: The position 'from' in the string
 * @param position: The position, where a new text/string should be added in the original string
 * @param text: The text/string that needed to be added
 * @returns for example "The original string with 'this new text'"
 */
export const addText = (string: string, from: number, position: number, text: string): string =>
	[string.slice(from, position), text, string.slice(position)].join(' ');
