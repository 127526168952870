import classNames from 'classnames';
import { Container, Grid, GridCell, SituationCard, Heading, SituationCardProps } from 'ui/components';
import styles from './SituationBanner.module.scss';

export interface SituationBannerProps {
	className?: string;
	heading?: string;
	link?: Umbraco.Link;
	situationItems: Umbraco.SituationCard[];
}

export const SituationBanner: React.FC<SituationBannerProps> = ({ className, situationItems, heading, link }) => {
	return (
		<section className={classNames(styles.SituationBanner, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell className={styles.SituationBanner_top}>
						{heading && (
							<Heading className={styles.SituationBanner_heading} headingLevel="h3">
								{heading}
							</Heading>
						)}
						{link && (
							<a href={link?.url} className={styles.SituationBanner_link}>
								{link?.name}
							</a>
						)}
					</GridCell>
					<GridCell className={styles.SituationBanner_bottom}>
						{situationItems?.map((situation, index) => {
							return <SituationCard key={index} {...situation?.properties} parent="SituationBanner" />;
						})}
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
