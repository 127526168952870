import React, { useState } from 'react';
import classNames from 'classnames';
import { Container, MemberCard, Button, Icon, GridCell, Spinner, ViewToggler, FilterWrapper } from 'ui/components';
import { formatDate } from 'helpers/date';
import { Member } from 'application/repositories/membersRepository';
import { FilterList } from 'ui/components/2-molecules/FilterList';
import { ReactComponent as IconDownload } from 'ui/icons/icon-download.svg';
import { ReactComponent as Minus } from 'ui/icons/icon-minus.svg';
import { ReactComponent as Plus } from 'ui/icons/icon-plus.svg';

import * as XLSX from 'xlsx';
import styles from './ListMembers.module.scss';

export interface ListMembersProps {
	members: Member[];
	isDownloadable: boolean;
	filename: string;
	primaryLabel?: string;
	secondaryLabel?: string;
	showMoreText?: string;
	showLessText?: string;
}

export const ListMembers: React.FC<ListMembersProps> = ({ members, isDownloadable, filename, primaryLabel, secondaryLabel }) => {
	const [showMore, setShowMore] = useState(false);
	const [isListView, setIsListView] = useState(false);

	const toggleShowMore = () => {
		setShowMore((prevValue) => !prevValue);
	};
	const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
	const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

	const handleChangeRole = (e) => {
		if (e.target.checked) {
			setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, e.target.value]);
		} else {
			setSelectedRoles((prevSelectedRoles) => prevSelectedRoles.filter((role) => role !== e.target.value));
		}
	};

	const handleChangeStatus = (e) => {
		if (e.target.checked) {
			setSelectedStatuses((prevSelectedStatuses) => [...prevSelectedStatuses, e.target.value]);
		} else {
			setSelectedStatuses((prevSelectedStatuses) => prevSelectedStatuses.filter((status) => status !== e.target.value));
		}
	};
	// Arrange members by alphabet
	const alphabetMembers = members?.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
	});
	const filteredMembers = alphabetMembers?.filter((member) => {
		if (selectedRoles.length === 0 && selectedStatuses.length === 0) return true;

		if (selectedRoles.length > 0) {
			let hasSelectedRole = false;
			selectedRoles.forEach((role) => {
				member.chairedTitles?.forEach((chairedTitle) => {
					if (chairedTitle.chairedTitle === role) {
						hasSelectedRole = true;
					}
				});
			});
			if (hasSelectedRole) return true;
		}

		if (selectedStatuses.length > 0 && selectedStatuses.includes(member.event?.type)) {
			return true;
		}

		return false;
	});

	const flattenedMembers = filteredMembers?.map((member) => {
		const flatMember = {
			memberId: member.memberId ?? '',
			name: member.name ?? '',
			job: member.job ?? '',
			company: member.company ?? '',
			pNumber: member.pNumber,
			linkedAgreements: member.linkedAgreements.length > 0 ? member.linkedAgreements[0].name : '',
			employedOn: member.employedOn ? formatDate(member.employedOn, 'dd-mm-yyyy') : '',
			birthday: member.birthday ? formatDate(member.birthday, 'dd-mm-yyyy') : '',
			emails: member.emails.length > 0 ? member.emails[0] : '',
			privateEmails: member.privateEmails.length > 0 ? member.privateEmails[0] : '',			
			phone: member.phones.length > 0 ? member.phones[0] : '',
			privatePhone: member.privatePhones.length > 0 ? member.privatePhones[0] : '',
			eventType: member.event?.type ?? '',
			eventDate: member.event?.date ? formatDate(member.event?.date, 'dd-mm-yyyy') : '',
			chairedTitleFirstTitle: member.chairedTitles.length > 0 ? member.chairedTitles[0].chairedTitle : '',
			chairedTitleFirstActingMemberPeriod:
				member.chairedTitles.length > 0 ? formatDate(member.chairedTitles[0].actingMemberPeriod, 'dd-mm-yyyy') : '',
			chairedTitleSecondTitle: member.chairedTitles.length > 1 ? member.chairedTitles[1].chairedTitle : '',
			chairedTitleSecondActingMemberPeriod:
				member.chairedTitles.length > 1 ? formatDate(member.chairedTitles[1].actingMemberPeriod, 'dd-mm-yyyy') : '',
				chairedTitleThirdTitle: member.chairedTitles.length > 2 ? member.chairedTitles[2].chairedTitle : '',
			chairedTitleThirdActingMemberPeriod:
				member.chairedTitles.length > 2 ? formatDate(member.chairedTitles[2].actingMemberPeriod, 'dd-mm-yyyy') : '',
				chairedTitleFourthTitle: member.chairedTitles.length > 3 ? member.chairedTitles[3].chairedTitle : '',
			chairedTitleFourthActingMemberPeriod:
				member.chairedTitles.length > 3 ? formatDate(member.chairedTitles[3].actingMemberPeriod, 'dd-mm-yyyy') : '',
				chairedTitleFifthTitle: member.chairedTitles.length > 4 ? member.chairedTitles[4].chairedTitle : '',
			chairedTitleFifthActingMemberPeriod:
				member.chairedTitles.length > 4 ? formatDate(member.chairedTitles[4].actingMemberPeriod, 'dd-mm-yyyy') : '',
				chairedTitleAdditionalTitles: member.chairedTitles.length > 5 ?
  				member.chairedTitles.slice(5).map(title => title.chairedTitle).join('-') : '',
		};

		return flatMember;
	});

	const headerRow = [
		'Medlemsnummer',
		'Navn',
		'Stilling',
		'Virksomhed',
		'P-nummer',
		'Overenskomst',
		'Ansættelsesdato',
		'Fødselsdag',		
		'E-mail arbejde',
		'E-mail privat',		
		'Telefon arbejde',
		'Telefon privat',
		'Begivenhed',
		'Dato for begivenhed',
		'Tillidshverv 1',
		'Tillidshverv 1 - dato for valg',
		'Tillidshverv 2',
		'Tillidshverv 2 - dato for valg',
		'Tillidshverv 3',
		'Tillidshverv 3 - dato for valg',
		'Tillidshverv 4',
		'Tillidshverv 4 - dato for valg',
		'Tillidshverv 5',
		'Tillidshverv 5 - dato for valg',
		'Øvrige hverv',
	];

	const downloadExcel = () => {
		/* generate worksheet and workbook */
		const worksheet = XLSX.utils.json_to_sheet(flattenedMembers);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Medlemmer');

		/* fix headers */
		XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: 'A1' });

		/* calculate column width */
		const max_width = flattenedMembers.reduce((w, r) => Math.max(w, r.name.length), 10);
		worksheet['!cols'] = [
			{ wch: 14 },
			{ wch: max_width },
			{ wch: 14 },
			{ wch: 13 },
			{ wch: 11 },
			{ wch: 13 },
			{ wch: 13 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 18 },
			{ wch: 13 },
			{ wch: 13 },
			{ wch: 11 },
			{ wch: 11 },
			{ wch: 13 },
			{ wch: 22 },
			{ wch: 13 },
			{ wch: 22 },
			{ wch: 13 },
			{ wch: 22 },
			{ wch: 13 },
			{ wch: 22 },
			{ wch: 13 },
			{ wch: 22 },
			{ wch: 22 },
		];

		/* create an XLSX file and try to save to medlemmer.xlsx */
		XLSX.writeFile(workbook, 'medlemmer.xlsx', { compression: true });
	};

	return (
		<>
			{!members ? (
				<Spinner />
			) : (
				<GridCell>
					<Container width="Standard" className={styles.ListMember}>
						<div className={styles.ListMember_actionsMenu}>
							{isDownloadable ? (
								<>
									<Button type="button" style="tertiary" disabled={!isDownloadable} className={styles.ListMember_download} onClick={downloadExcel}>
										<div className={styles.ListMember_button_label}>
											Download som liste
											<Icon size="md" ariaHidden={false} className={styles.ListMember_download_icon}>
												<IconDownload viewBox="0 2 20 15" />
											</Icon>
										</div>
									</Button>
								</>
							) : null}
							<FilterWrapper>
								<FilterList
									members={members}
									primaryLabel={primaryLabel}
									secondaryLabel={secondaryLabel}
									onChangeRole={handleChangeRole}
									onChangeStatus={handleChangeStatus}
									selectedRoles={selectedRoles}
									selectedStatuses={selectedStatuses}
								/>
							</FilterWrapper>
							<div className={styles.ListMember_actionsMenu_right}>
								<ViewToggler setViewAsList={setIsListView} isListView={isListView} />
							</div>
						</div>
						<Container
							width="Standard"
							className={classNames(
								styles.ListMember_container,
								{
									[styles.ListMember_container___isDownloadable]: isDownloadable,
									[styles.ListMember_container___isShowMore]: showMore,
								},
								isListView && styles.isListView,
							)}
						>
							{filteredMembers.map((member) => (
								<MemberCard
									key={member.memberId}
									member={member}
									isListView={isListView}
									className={classNames(styles.ListMember_cards, { [styles.ListMember_alignment]: !member?.event })}
									setIsListView={setIsListView}
								/>
							))}
						</Container>
						{filteredMembers.length > 12 && (
							<aside className={styles.ListMember_linkWrapper} onClick={toggleShowMore}>
								<button className={styles.ListMember_link}>
									{!showMore && (
										<>
											<span>Vis mere</span>
											<Icon className={styles.ListMember_linkIconPlus}>
												<Plus />
											</Icon>
										</>
									)}
									{showMore && (
										<>
											<span>Vis mindre</span>
											<Icon className={styles.ListMember_linkIconMinus}>
												<Minus />
											</Icon>
										</>
									)}
								</button>
							</aside>
						)}
					</Container>
				</GridCell>
			)}
		</>
	);
};
