import { ThumbnailCard, Container, Grid, GridCell, Paragraph, Heading, ThumbnailCardProps, LinkButton, Icon } from 'ui/components';
import { ReactComponent as PatternTwo } from 'ui/icons/icon-pattern-two.svg';
import classNames from 'classnames';

import styles from './ThumbnailList.module.scss';

export interface ThumbnailListProps {
	className?: string;
	columns: number;
	items: ThumbnailCardProps[];
	desktopWidth?: Models.GridCellWidth;
	mobileWidth?: Models.GridCellWidth;
	moduleLink?: Umbraco.Link;
	thumbnailListHeading: string;
	thumbnailListSubHeading: string;
}

export const ThumbnailList: React.FC<ThumbnailListProps> = ({
	className,
	items,
	moduleLink,
	columns,
	thumbnailListHeading,
	thumbnailListSubHeading,
}) => {
	const getColumnWidth = (columns: number) => {
		if (columns > 4) columns = 4;
		if (!columns || columns === 1) columns = 2;
		const columnsWidth = Math.round(100 / columns);
		return JSON.stringify(columnsWidth) as Models.GridCellWidth;
	};
	const columnWidth = getColumnWidth(columns);

	const imageSizes = '(max-width: 768px) 50vw, ' + columnWidth + 'vw';
	if (!items) return null;

	const grid = (
		<section className={styles.ThumbnailList_inner}>
			{items.map((args, index) => {
				return (
					<ThumbnailCard className={styles.ThumbnailList_items} key={index} {...args} columnWidth={columnWidth} imageSizes={imageSizes} />
				);
			})}
		</section>
	);

	return (
		<section className={classNames(styles.ThumbnailList, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell>
						{thumbnailListHeading && (
							<Heading className={styles.ThumbnailList_heading} headingLevel="h2">
								{thumbnailListHeading}
							</Heading>
						)}
						{thumbnailListSubHeading && <Paragraph className={styles.ThumbnailList_subheading}>{thumbnailListSubHeading}</Paragraph>}
					</GridCell>
					<GridCell>{grid}</GridCell>
					<GridCell>
						{moduleLink && (
							<LinkButton className={styles.ThumbnailList_link} url={moduleLink.url} style="primary">
								{moduleLink.name}
							</LinkButton>
						)}
					</GridCell>
				</Grid>
			</Container>
			<Icon className={styles.ThumbnailList_pattern}>
				<PatternTwo />
			</Icon>
		</section>
	);
};
