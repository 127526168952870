import classNames from 'classnames';
import styles from './ThumbnailCard.module.scss';
import { GridCell, Picture } from 'ui/components';
import { Heading, RichText } from 'ui/components';

export interface ThumbnailCardProps {
	className?: string;
	image?: Umbraco.Image;
	columnWidth: Models.GridCellWidth;
	imageSizes: string;
	link?: Umbraco.Link;
	heading?: string;
	text?: string;
}

export const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
	className,
	image,
	link,
	heading,
	text,

	...props
}) => {
	const { imageSizes, columnWidth } = props || {};

	const contentInner = (
		<>
			{image && (
				<div className={styles.ThumbnailCard_media}>
					<span className={styles.ThumbnailCard_mediabox}>
						<Picture
							className={styles.ThumbnailCard_picture}
							sizes={imageSizes}
							aspectRatio={0.75}
							relativeUrl={image?.relativeUrl}
							properties={image.properties}
							focalPoint={image.focalPoint}
						/>
					</span>
				</div>
			)}
			<div className={styles.ThumbnailCard_textbox}>
				<Heading className={styles.ThumbnailCard_heading} headingLevel="h3">
					{heading}
				</Heading>
				<RichText className={classNames(styles.ThumbnailCard_text, 'RichText', className)} content={text} />
			</div>
			<div></div>
		</>
	);

	let content;

	if (link) {
		content = (
			<GridCell desktopWidth={columnWidth} mobileWidth="50" className={classNames(styles.ThumbnailCard, className)}>
				<a
					className={classNames(styles.ThumbnailCard, styles.ThumbnailCard___link, className)}
					href={link.url}
					target={link.target}
					title={link.name}
				>
					{contentInner}
				</a>
			</GridCell>
		);
	} else {
		content = <div className={classNames(styles.ThumbnailCard, className)}>{contentInner}</div>;
	}

	return content;
};
