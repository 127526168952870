import { RichText } from 'ui/components/1-atoms/RichText';
import { useState } from 'react';
import styles from './AccordionItem.module.scss';
import { generateId } from 'helpers/id';
import { Heading, Paragraph, Picture, LinkButton, Container, Grid, GridCell } from 'ui/components';
import { ReactComponent as AccordianIconOpen } from 'ui/icons/accordian-icon-open.svg';
import { ReactComponent as AccordianIconClosed } from 'ui/icons/accordian-icon-closed.svg';

// TODO: Mangler håndtering af allowMultiple: true/false
export const AccordionItem: React.FC<Models.AccordionItem> = ({
	title,
	htmlContent,
	image,
	imageName,
	imageTitle,
	subTitle,
	openByDefault = false,
}) => {
	const id = generateId();
	const [open, isOpen] = useState(openByDefault);
	const toggle = () => isOpen(!open);

	return (
		<>
			<h3 className={styles.AccordionItem_triggerWrapper} aria-expanded={open}>
				<button onClick={toggle} aria-expanded={open} className={styles.AccordionItem_trigger} aria-controls={`panel-${id}`} id={`trigger-${id}`}>
					<span className={styles.AccordionItem_title}>
						{subTitle && <span className={styles.AccordionCalendarItem_date}>{subTitle}</span>}

						{title}
						{open ? <AccordianIconOpen className={styles.AccordionItem_icon} /> : <AccordianIconClosed className={styles.AccordionItem_icon} />}
					</span>
				</button>
			</h3>
			<article id={`panel-${id}`} role="region" aria-labelledby={`trigger-${id}`} className={styles.AccordionItem_panel} hidden={!open}>
				<div className={styles.AccordionItem_contentWrapper}>
					{image ? (
						<>
							<section className={styles.AccordionItem_textWrapper}>
								<RichText content={htmlContent} className={styles.AccordionItem_content} />
							</section>

							<section className={styles.AccordionItem_imageWrapper}>
								<span className={styles.AccordionItem_mediabox}>
									<Picture
										relativeUrl={image?.relativeUrl}
										properties={image.properties}
										focalPoint={image.focalPoint}
										aspectRatio={0.75}
										sizes="100vw"
									/>
								</span>
								<span className={styles.AccordionItem_imageName}>{imageName}</span>
								<span className={styles.AccordionItem_imageTitle}>{imageTitle}</span>
							</section>
						</>
					) : (
						<section className={styles.AccordionItem_textWrapperSingle}>
							<RichText content={htmlContent} className={styles.AccordionItem_content} />
						</section>
					)}
				</div>
			</article>
		</>
	);
};
