import classNames from 'classnames';
import { LinkButton } from 'ui/components';
import styles from './Links.module.scss';

export interface LinksProps {
	className?: string;
	link?: Umbraco.Link;
}

export const Links: React.FC<LinksProps> = ({ className, link, children }) => {
	if (link == null){
		return null
	}
	return (
		<LinkButton className={classNames(styles.Links, className)} url={link.url} style="tertiary" target={link.target}>
			{link.name}
			{children}
		</LinkButton>
	);
};
