import { getToken, GetTokenParams } from 'next-auth/jwt';
import { getSession } from 'next-auth/react';
import { DXPSession, DXPToken } from 'pages/api/auth/[...nextauth]';

export const getDXPToken = async (params: GetTokenParams): Promise<DXPToken> => {
	return (await getToken(params)) as DXPToken;
};

export const getAccessToken = async (req: GetTokenParams['req']): Promise<string | null> => {
	const token = await getDXPToken({ req });
	return token?.accessToken;
};

export const getDXPSession = async (): Promise<DXPSession> => {
	return (await getSession()) as DXPSession;
};

export const getAccessTokenBrowser = async (): Promise<string | null> => {
	const session = await getDXPSession();
	return session?.accessToken;
};
