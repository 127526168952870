import classNames from 'classnames';
import styles from './Link.module.scss';

export interface LinkProps {
	children: React.ReactNode;
	style?: 'primary' | 'sm';
	ariaLabel?: string;
	className?: string;
	url?: string;
	title?: string;
	asText?: boolean;
	download?: string;
	target?: string;
}

export const Link: React.FC<LinkProps> = ({ children, style, ariaLabel, className, url, asText, download, title, target }) => (
	<>
		{asText ? (
			<p className={classNames(styles.Link, styles[`Link___${style}`], className)}>{children}</p>
		) : (
			<a
				title={title}
				href={url}
				aria-label={ariaLabel}
				className={classNames(styles.Link, styles[`Link___${style}`], className)}
				download={download}
				target={target}
			>
				{children}
			</a>
		)}
	</>
);
