import classNames from 'classnames';
import styles from './NewsList.module.scss';
import { useState } from 'react';
import { Container, Grid, GridCell, Icon, Pagination, Spinner, LinkButton, NewsCardGlobalNews } from 'ui/components';
import { ReactComponent as PatternFive } from 'ui/icons/icon-pattern-five.svg';

/*
 *
 * This component is for all sites except kongres.def.dk
 *
 */

export interface NewsListProps {
	className?: string;
	heading?: string;
	kicker?: string;
	link?: Umbraco.Link;
	newsItems?: Models.NewsCard[];
}

export const NewsList: React.FC<NewsListProps> = ({ className, heading, link, newsItems }) => {
	const pageSize = 9; // Number of items to be shown before the Pagination kicks in
	const [currentPage, setCurrentPage] = useState(1);

	const newsItemsPaginated = newsItems.slice((currentPage - 1) * pageSize, currentPage * pageSize);

	return !newsItems || newsItems.length < 1 ? (
		<Spinner />
	) : (
		<Container width="Standard" className={classNames(styles.NewsList, className)}>
			<Grid full wrap>
				<GridCell>
					<h1 className={styles.NewsList_heading}>{heading}</h1>
				</GridCell>
				<Icon className={styles.NewsList_pattern}>
					<PatternFive />
				</Icon>
				{link?.url && (
					<GridCell className={styles.NewsList_linkButton}>
						<LinkButton className={styles.NewsList_linkButtonWide} style="negative" url={link?.url} title={link?.name}>
							{link?.name}
						</LinkButton>
					</GridCell>
				)}
				<GridCell>
					<span className={styles.NewsList_hits}>{`(${newsItems.length} ${newsItems.length > 1 ? 'resultater' : 'resultat'})`}</span>
				</GridCell>
				<article className={styles.NewsList_newsList}>
					{newsItemsPaginated.map((args) => (
						<NewsCardGlobalNews key={args?.id} {...args} />
					))}
				</article>
			</Grid>
			{newsItems.length > pageSize && (
				<Pagination
					numItems={newsItems.length}
					pageSize={pageSize}
					currentPage={currentPage}
					prevClickHandler={() => setCurrentPage((page) => page - 1)}
					nextClickHandler={() => setCurrentPage((page) => page + 1)}
					theme="dark"
				/>
			)}
		</Container>
	);
};
