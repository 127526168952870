import classNames from 'classnames';
import { Links, Grid, GridCell, Container, LinksProps, Heading, Paragraph, Icon } from 'ui/components';
import styles from './ListLinks.module.scss';
import { ReactComponent as IconChevronRight } from 'ui/icons/icon-chevron-right.svg';
import { ReactComponent as IconChevronExternal } from 'ui/icons/icon-chevron-external.svg';
import { ReactComponent as IconChevronCircle } from 'ui/icons/icon-chevron-circle.svg';

export interface ListLinksProps {
	className?: string;
	heading?: string;
	text?: string;
	items: LinksProps[];
	style?: 'Small' | 'Large' | 'External';
	useBackground?: boolean;
}

export const ListLinks: React.FC<ListLinksProps> = ({ className, items, heading, text, style = 'Small', useBackground = false }) => {
	const styleIsDefault = style === 'Small';
	const styleIsRevert = style === 'Large';
	const styleIsShort = style === 'External';

	return (
		<Container
			width="Standard"
			className={classNames(
				styles.ListLinks,
				{ [styles.ListLinks___default]: styleIsDefault },
				{ [styles.ListLinks___revert]: styleIsRevert },
				{ [styles.ListLinks___short]: styleIsShort },
				{ [styles.ListLinks__useBackground]: useBackground },
				className,
			)}
		>
			<Grid full wrap>
				<GridCell className={styles.ListLinks_top}>
					{heading && (
						<Heading className={styles.ListLinks_heading} headingLevel="h3">
							{heading}
						</Heading>
					)}
					{text && <Paragraph className={styles.ListLinks_text}>{text}</Paragraph>}
				</GridCell>
				<GridCell className={styles.ListLinks_bottom}>
					{items.map((args, index) => (
						<Links
							key={index}
							{...args}
							className={classNames(
								styles.Links,
								{ [styles.Links___default]: styleIsDefault },
								{ [styles.Links___revert]: styleIsRevert },
								{ [styles.Links___short]: styleIsShort },
								className,
							)}
						>
							<Icon className={styles.Links_iconRight} size="md">
								<IconChevronRight />
							</Icon>
							<Icon className={styles.Links_iconExternal} size="md">
								<IconChevronExternal />
							</Icon>
							<Icon className={styles.Links_iconCircle} size="md">
								<IconChevronCircle />
							</Icon>
						</Links>
					))}
				</GridCell>
			</Grid>
		</Container>
	);
};
