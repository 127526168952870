import { useEffect } from 'react';
import classNames from 'classnames';
import styles from './Modal.module.scss';
import { Icon, Logo } from 'ui/components';
import { ReactComponent as CloseIcon } from 'ui/icons/icon-close.svg';
import FocusTrap from 'focus-trap-react';
import { Button } from 'ui/components';

export interface ModalProps {
	className?: string;
	title: string;
	acceptText?: string;
	ariaClose: string;
	children: React.ReactNode;
	handleClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ className, title, acceptText, ariaClose, children, handleClose }) => {
	const closeEscape = (event) => {
		if (event.keyCode === 27) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', closeEscape);

		return () => {
			document.removeEventListener('keydown', closeEscape);
		};
	});

	return (
		<FocusTrap>
			<div className={classNames(styles.Modal, className)} role="dialog" aria-modal={true} aria-describedby="dialog-description">
				<div className={classNames(styles.Modal_overlay, 'u-center-absolute')} onClick={handleClose}></div>
				<div className={styles.Modal_modal}>
					<div className={styles.Modal_top}>
						<Logo className={styles.Modal_logo} withText={true} />
						<button className={styles.Modal_close} onClick={handleClose} aria-label={ariaClose}>
							<Icon className={styles.Modal_iconClose}>
								<CloseIcon />
							</Icon>
						</button>
						<h2 className={styles.Modal_title} id="dialog-description">
							{title}
						</h2>
					</div>
					{children}
					{acceptText && (
						<Button style="primary" className={styles.Modal_accept} onClick={handleClose}>
							{' '}
							{acceptText}{' '}
						</Button>
					)}
				</div>
			</div>
		</FocusTrap>
	);
};
