import classNames from 'classnames';
import styles from './FooterModule.module.scss';
import { Heading, Icon, Container, Grid, GridCell, RichText } from 'ui/components';
import { ReactComponent as PatternThree } from 'ui/icons/icon-pattern-three.svg';
import { ReactComponent as PatternSix } from 'ui/icons/icon-pattern-six.svg';

export interface FooterModuleProps {
	className?: string;
	poster?: Umbraco.Image;
	heading?: string;
	text?: string;
	mediaURL?: string;
}

export const FooterModule: React.FC<FooterModuleProps> = ({ className, mediaURL, heading, text }) => {
	return (
		<section className={classNames(styles.FooterModule, className)}>
			<Container width="Standard" className={styles.FooterModule_inner}>
				<Grid full wrap>
					<Icon className={styles.FooterModule_pattern}>
						<PatternThree />
					</Icon>
					<Icon className={styles.FooterModule_pattern_secondary}>
						<PatternSix />
					</Icon>
					<section>
						<GridCell desktopWidth="33" mobileWidth="100" className={styles.FooterModule_textbox}>
							<Heading headingLevel="h2" className={styles.FooterModule_title}>
								{heading}
							</Heading>
							<RichText className={classNames(styles.FooterModule_text, 'RichText', className)} content={text} />
						</GridCell>
						<GridCell desktopWidth="50" mobileWidth="100" className={styles.FooterModule_media}>
							<span className={styles.FooterModule_mediabox}>
								<iframe
									className={styles.FooterModule_link}
									src={`https://www.facebook.com/plugins/page.php?href=${mediaURL}&tabs=timeline&width=500&height=350&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
									frameBorder="0"
									scrolling="no"
									allowFullScreen={true}
									//clipboard-write // TODO: Fix. This attribute is invalid.
									//encrypted-media // TODO: Fix. This attribute is invalid.
									//picture-in-picture // TODO: Fix. This attribute is invalid.
									//web-share // TODO: Fix. This attribute is invalid.
									width="500"
									height="350"
								></iframe>
							</span>
						</GridCell>
					</section>
				</Grid>
			</Container>
		</section>
	);
};
