import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Contact, Icon, Link as ComponentLink, LinkButton, Newsletter } from 'ui/components';
import styles from './FooterDef.module.scss';
import { AppStateContext } from 'application/adapters/appState/appState';

import { getContact, GetContactResult } from 'application/adapters/contacts/contactsAdapter';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { ReactComponent as EmailIcon } from 'ui/icons/email-icon.svg';
import { ReactComponent as FacebookIcon } from 'ui/icons/facebook-icon.svg';
import { ReactComponent as OKLogo } from 'ui/icons/icon-corp-brand-small.svg';
import { ReactComponent as LinkedinIcon } from 'ui/icons/linkedin-icon.svg';
import { ReactComponent as DEFLogo } from 'ui/icons/logo-primary.svg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-icon.svg';
import { ProfileContext } from 'application/adapters/context/Profile';

export interface FooterDefProps {
	className?: string;
	textArea?: string;
	navigationHeading?: string;
	navigationLinks: Umbraco.Link[];
	contactInfoHeading?: string;
	contactInfoPhone?: string;
	contactInfoEmail?: string;
	addressHeading?: string;
	addressFirstLine?: string;
	addressSecondLine?: string;
	newsletterHeading?: string;
	newsletterLink?: Umbraco.Link;
	socialMediaHeading?: string;
	facebookLink?: Umbraco.Link;
	linkedinLink?: Umbraco.Link;
	ctaOne?: Umbraco.Link;
	ctaTwo?: Umbraco.Link;
	legalLinks?: Umbraco.Link[];
}

export const FooterDef: React.FC<FooterDefProps> = ({
	children,
	className,
	textArea,
	navigationHeading,
	navigationLinks,
	contactInfoHeading,
	contactInfoPhone,
	contactInfoEmail,
	addressHeading,
	addressFirstLine,
	addressSecondLine,
	legalLinks,
	newsletterLink,
	newsletterHeading,
	socialMediaHeading,
	facebookLink,
	linkedinLink,
	ctaOne,
	ctaTwo,
}) => {
	const [departments, setDepartments] = useState<GetContactResult>(null);
	const [defaultDepartment, setDefaultDepartment] = useState<string>('');
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const { status } = useSession();
	const { toggleShowLoginModal } = useContext(AppStateContext);

	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);

	const profileContext = useContext(ProfileContext);
	const profile = profileContext.profile;
	useEffect(() => {
		if (isLoggedIn && profile) {
			setDefaultDepartment(profile?.branch?.name);
			getContact(getCMSHostname())
				.then((response) => {
					setDepartments(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [isLoggedIn, profile]);

	return (
		<footer className={classNames(styles.FooterDef, className)} role="contentinfo">
			<div className={styles.FooterDef_container}>
				<div className={styles.FooterDef_containerLogo}>
					<article className={styles.FooterDef_logoWrapper}>
						<DEFLogo />
						<OKLogo />
					</article>

					{textArea && <div className={styles.FooterDef_textBox}>{textArea}</div>}
				</div>
				<div className={styles.FooterDef_containerNav}>
					<div className={styles.FooterDef_nav}>
						<div className={styles.FooterDef_heading}>{navigationHeading}</div>

						<ul className={styles.FooterDef_navList}>
							{navigationLinks?.map((link) => (
								<li key={link?.name} className={styles.FooterDef_navListItem}>
									<Link href={link?.url}>{link?.name}</Link>
								</li>
							))}
						</ul>
					</div>
					{isLoggedIn ? (
						<div className={styles.FooterDef_nav}>
							<div className={styles.FooterDef_heading}>{addressHeading}</div>

							<Contact departments={departments} selectValue={defaultDepartment ?? ''} />
						</div>
					) : (
						<>
							<div className={styles.FooterDef_nav}>
								<div className={styles.FooterDef_heading}>{addressHeading}</div>

								<div className={styles.FooterDef_address}>
									{addressFirstLine}
									<br />
									{addressSecondLine}
								</div>
							</div>
							<div className={classNames(styles.FooterDef_nav, styles.FooterDef_fullHeight)}>
								<div className={styles.FooterDef_heading}>{contactInfoHeading}</div>

								<div className={styles.FooterDef_contactInfo}>
									<div className={styles.FooterDef_contactInfoItem}>
										<div className={styles.FooterDef_contactInfoItemIcon}>
											<PhoneIcon />
										</div>

										<a href={`tel:${contactInfoPhone}`} className={styles.FooterDef_contactInfoLink}>
											{contactInfoPhone}
										</a>
									</div>

									<div className={styles.FooterDef_contactInfoItem}>
										<div className={styles.FooterDef_contactInfoItemIcon}>
											<EmailIcon />
										</div>
										<div className={styles.FooterDef_contactInfoItemText}>
											<a
												className={styles.FooterDef_contactInfoLink}
												href={`mailto:${contactInfoEmail}`}
												title={`Send email til ${contactInfoEmail}`}
											>
												{contactInfoEmail}
											</a>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>

				<div className={styles.FooterDef_containerNewsletter}>
					<div className={styles.FooterDef_subheading}>{newsletterHeading}</div>

					<div className={styles.FooterDef_newsletter}>
						<Newsletter moduleLink={newsletterLink} hideCorp={true} />
					</div>
				</div>

				<div className={styles.FooterDef_containerSoMe}>
					{!isLoggedIn && (
						<div className={styles.FooterDef_contactInfoButtons}>
							{ctaOne && (
								<LinkButton style="primary" url={ctaOne?.url} title={ctaOne?.name}>
									{ctaOne?.name}
								</LinkButton>
							)}

							{ctaTwo && (
								<LinkButton style="secondary" title="Bliv medlem" onClick={toggleShowLoginModal}>
									<span>{ctaTwo?.name}</span>
								</LinkButton>
							)}
						</div>
					)}

					<div>
						<aside className={styles.FooterDef_SoMe}>
							<div className={styles.FooterDef_SoMeHeading}>{socialMediaHeading}</div>
							<ComponentLink className={styles.FooterDef_SoMeLink} title={facebookLink?.name} url={`${facebookLink?.url ?? ''}`}>
								<Icon className={styles.FooterDef_SoMeLinkIcon}>
									<FacebookIcon />
								</Icon>
							</ComponentLink>

							<ComponentLink className={styles.FooterDef_SoMeLink} title={linkedinLink?.name} url={`${linkedinLink?.url ?? ''}`}>
								<Icon className={styles.FooterDef_SoMeLinkIcon}>
									<LinkedinIcon />
								</Icon>
							</ComponentLink>
						</aside>
					</div>
				</div>
			</div>
			<div className={styles.FooterDef_containerLegal}>
				<div className={styles.FooterDef_legal}>
					{legalLinks?.map((link) => (
						<div className={styles.FooterDef_legalItem} key={link?.name}>
							<Link href={link?.url}>{link?.name}</Link>
						</div>
					))}
				</div>
			</div>
		</footer>
	);
};
