import classNames from 'classnames';
import styles from './Pagination.module.scss';
import { NewsModuleCard, Icon } from 'ui/components';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'ui/icons/icon-chevron-left.svg';
// import 'react-pagination-bar/dist/index.css';

export interface PaginationProps {
	className?: string;
	numItems: number;
	pageSize: number;
	currentPage: number;
	theme?: string;
	firstClickHandler?: any;
	prevClickHandler: any;
	nextClickHandler: any;
}

export const Pagination: React.FC<PaginationProps> = ({
	className,
	numItems,
	pageSize,
	currentPage,
	firstClickHandler,
	prevClickHandler,
	nextClickHandler,
	theme,
}) => {
	const currentPageIndex = currentPage - 1;
	const startPage = currentPageIndex * pageSize + 1;
	let endPage = startPage + pageSize - 1;

	// Scenario 2 for endPage
	endPage = endPage > numItems ? numItems : endPage;

	// If number of items is less than pageSize (10 or 5)
	// OR number of items  is the same as end of page
	const noMoreItems = numItems < pageSize || numItems === endPage;

	return (
		<div className={classNames(styles.Pagination, className)}>
			{/* <button
				className="m-simple-pagination__button m-simple-pagination__button--prev-end"
				disabled={currentPage === 1}
				onClick={firstClickHandler}
			>
				Prev End
				<Icon className={styles.Navigation_icon} size="md">
					<ChevronRight />
				</Icon>{' '}
			</button> */}
			<button className={styles.Pagination__buttonPrev} disabled={currentPage === 1} onClick={prevClickHandler}>
				<Icon className={styles.Navigation_icon} size="md">
					<ChevronLeft />
				</Icon>{' '}
			</button>

			<div className={styles.Pagination__text}>
				{startPage}-{endPage}
			</div>
			<button className={styles.Pagination__buttonNext} disabled={noMoreItems} onClick={nextClickHandler}>
				<Icon className={styles.Navigation_icon} size="md">
					<ChevronRight />
				</Icon>{' '}
			</button>
		</div>
	);
};
