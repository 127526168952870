import classNames from 'classnames';
import styles from './ArticleHeader.module.scss';
import { Heading, Container, GridCell } from 'ui/components';

export interface ArticleHeaderProps {
	className?: string;
	kicker?: string;
	teaser?: string;
	heading?: React.ReactNode;
	headerWidth?: Umbraco.SectionWidth;
}
export const ArticleHeader: React.FC<ArticleHeaderProps> = ({ className, teaser, heading, kicker, headerWidth }) => (
	<Container width={headerWidth ? headerWidth : "Standard"} className={classNames(styles.ArticleHeader, className)}>
		<GridCell>
			<div className={styles.ArticleHeader_kicker}>{kicker}</div>
			<Heading className={styles.ArticleHeader_heading} headingLevel="h1" style="xl">
				{heading}
			</Heading>
			<div className={styles.ArticleHeader_lead}>{teaser}</div>
		</GridCell>
	</Container>
);
