import axios, { AxiosResponse } from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from './constants';
export type AudiencesResult = {
	audiences: [
		{
			name: string;
			id: string;
		},
	];
};

export type RequestHeaders = {
	hostname: string;
	memberId: string;
	Authorization?: string;
};

export type ActitivyResult = {
	Activity: {
		MemberId: string;
		Type: string;
	};
	Error: string;
};

export type Actitivy = {
	MemberId: string;
	Type: string;
};
export const getAudiencesAdapter = async (hostname: string, memberId: string): Promise<AudiencesResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
		memberId: memberId,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl('Personalization/Audience');
	const { data: profiles } = await axios.get<AudiencesResult>(href, { headers: headers });
	return profiles;
};

export const CreateActivityAdapterAuthtoken = async (auth: string, type: String): Promise<AxiosResponse<ActitivyResult>> => {
	var payload = {
		MemberId: null,
		type: type,
	};

	const { href } = getApiUrl('Personalization/Activity');
	return axios.post<ActitivyResult>(href, payload, {
		headers: { Authorization: 'Bearer ' + auth, 'Content-Type': 'application/json' },
	});
};
