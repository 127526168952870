import classNames from 'classnames';
import React from 'react';
import { AgreementCard, AgreementCardProps, Container, GridCell } from 'ui/components';
import styles from './ListAgreements.module.scss';

export interface ListAgreementsProps {
	className?: string;
	agreements: any;
	items?: AgreementCardProps[];
}

export const ListAgreements: React.FC<ListAgreementsProps> = ({ className, items, agreements }) => {
	// sorting agreements alphabetically
	agreements?.sort(function (a, b) {
		if (a?.agreementLink?.link?.name < b?.agreementLink?.link?.name) return -1;
		if (a?.agreementLink?.link?.name > b?.agreementLink?.link?.name) return 1;

		return 0;
	});

	return (
		<Container width="Standard" className={classNames(styles.ListAgreement, className)}>
			<GridCell>
				{agreements?.map((args, index) => (
					<AgreementCard key={index} {...args} />
				))}
			</GridCell>
		</Container>
	);
};
