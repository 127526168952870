import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import styles from './HeaderPortal.module.scss';
import { Logo, SkipToMain, NavigationPortal, Spinner, Container, Grid, GridCell, ProfileCard, Button, Icon, SearchCludo, Link } from 'ui/components';
import { Member } from 'application/repositories/membersRepository';
import { ReactComponent as ChevronRightIcon } from 'ui/icons/icon-chevron-right.svg';
import { ReactComponent as CorpBrandSmallIcon } from 'ui/icons/icon-corp-brand-xs.svg';
import { ReactComponent as LogOutIcon } from 'ui/icons/icon-log-out.svg';
import { signOut } from 'next-auth/react';
import { ReactComponent as IconLoop } from 'ui/icons/icon-loop.svg';
export interface HeaderPortalProps {
	className?: string;
	member: Member;
	navHeading?: string;
	navigationItemsPortal?: Models.NavigationItem[];
}

export type navMemberState = boolean;
export type navMemberStateDesktop = boolean;

export const HeaderPortal: React.FC<HeaderPortalProps> = ({ className, member, navHeading, navigationItemsPortal }) => {
	const [navOpen, setNavOpen] = useState<navMemberState>(false);
	const checkboxRef = useRef(null);

	const handleKeyboardNav = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (keyDownEvent.keyCode === 32) {
			if (!checkboxRef.current.checked) {
				checkboxRef.current.checked = true;
				setNavOpen(true);
			} else {
				checkboxRef.current.checked = false;
				setNavOpen(false);
			}
		}
	};

	const [navOpenDesktop, setNavOpenDesktop] = useState<navMemberStateDesktop>(false);
	const checkboxRefDesktop = useRef(null);
	const [searchOpen, setSearchOpen] = useState(false);
	const { status, data } = useSession();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const headerRef = useRef(null);
	const handleKeyboardNavDesktop = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (keyDownEvent.keyCode === 32) {
			if (!checkboxRefDesktop.current.checked) {
				checkboxRefDesktop.current.checked = true;
				setNavOpenDesktop(true);
			} else {
				checkboxRefDesktop.current.checked = false;
				setNavOpenDesktop(false);
			}
		}
	};
	const overlayClick = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (!checkboxRefDesktop.current.checked) {
			checkboxRefDesktop.current.checked = true;
			setNavOpenDesktop(true);
		} else {
			checkboxRefDesktop.current.checked = false;
			setNavOpenDesktop(false);
		}
	};
	const signOutClick = () => {
		signOut({
			redirect: false,
		}).then(() => {
			window.location.href = `https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${process.env.NEXT_PUBLIC_SIGNOUT_URL}`;
		});
	};
	const searchClick = () => {
		setSearchOpen(!searchOpen);
	};
	const handleClickClose = (event) => {
		const element = headerRef.current;
		if (!element?.contains(event.target)) {
			setSearchOpen(false);
		}
	};
	const handleCloseSearch = () => {
		setSearchOpen(false);
	};
	const handleOpenSearch = () => {
		setSearchOpen(!searchOpen);
	};
	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
		if (searchOpen) {
			document.addEventListener('click', handleClickClose);
		}
		return () => document.removeEventListener('click', handleClickClose);
	}, [status, searchOpen]);
	return (
		<section aria-expanded={searchOpen} ref={headerRef}>
			{isLoggedIn && <SearchCludo handleCloseSearch={handleCloseSearch} theme="portal" />}
			{isLoggedIn && (
				<div className={classNames(styles.HeaderPortal_search, className)} onClick={searchClick}>
					<Link className={styles.HeaderPortal_searchLink}>
						<span className={styles.HeaderPortal_title}>Søg</span>
						<Icon className={styles.HeaderPortal_iconLoop} size="md">
							<IconLoop />
						</Icon>
					</Link>
				</div>
			)}
			<header className={classNames(styles.HeaderPortal, className)} aria-expanded={navOpenDesktop}>
				<div className={styles.HeaderPortal_overlay} aria-expanded={navOpenDesktop} onClick={(e) => overlayClick(e)}></div>
				<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
					Skip to content
				</SkipToMain>

				<Button className={styles.HeaderPortal_navButton}>
					<input
						onChange={(event: any) => (event.target.checked ? setNavOpenDesktop(true) : setNavOpenDesktop(false))}
						className={styles.HeaderPortal_navControllerDesktop}
						type="checkbox"
						id="nav-controller-desktop"
						data-ref="HeaderPortal__navControllerDesktop"
						ref={checkboxRefDesktop}
					/>
					<label className={styles.HeaderPortal_navToggleDesktop} htmlFor="nav-controller-desktop">
						<span
							className={styles.HeaderPortal_icon}
							role="navigation"
							aria-haspopup="true"
							aria-expanded={navOpenDesktop}
							tabIndex={0}
							onKeyDown={(e) => handleKeyboardNavDesktop(e)}
						>
							<Icon className={styles.HeaderPortal_expandIcon} size="md">
								<ChevronRightIcon />
							</Icon>
						</span>
					</label>
				</Button>
				<Container width="Full width" className={styles.HeaderPortal_container} ariaExpanded={navOpen}>
					<Grid className={styles.HeaderPortal_grid} nogutter={true} center={true}>
						<Logo className={styles.HeaderPortal_logo} withText={true} />
						<Icon className={styles.HeaderPortal_corpBrand} size="md">
							<CorpBrandSmallIcon />
						</Icon>
						{navigationItemsPortal && (
							<div className={classNames(styles.HeaderPortal_navigation)}>
								<input
									onChange={(event: any) => {
										if (event.target.checked) {
											document.body.style.overflow = 'hidden';
											setNavOpen(true);
										} else {
											document.body.style.overflow = 'auto';
											setNavOpen(false);
										}
									}}
									className={styles.HeaderPortal_navController}
									type="checkbox"
									id="nav-controller"
									data-ref="HeaderPortal__navController"
									ref={checkboxRef}
								/>
								<label className={styles.HeaderPortal_navToggle} htmlFor="nav-controller">
									<span
										className={styles.HeaderPortal_icon}
										role="navigation"
										aria-haspopup="true"
										aria-expanded={navOpen}
										tabIndex={0}
										onKeyDown={(e) => handleKeyboardNav(e)}
									>
										<span className={styles.HeaderPortal_iconBar}></span>
										<span className={styles.HeaderPortal_iconBar}></span>
										<span className={styles.HeaderPortal_iconBar}></span>
										<span className={styles.HeaderPortal_iconBar}></span>
									</span>
								</label>
								<div
									className={classNames(styles.HeaderPortal_nav, navOpen && styles.HeaderPortal_nav___active)}
									data-ref="HeaderPortal__navPanel"
								>
									<Grid nogutter={true} wrap={true}>
										<GridCell className={styles.HeaderPortal_navContainer}>
											<div className={styles.HeaderPortal_profileCardWrapper}>
												{!member ? (
													<Spinner />
												) : (
													<ProfileCard
														profileName={member.name}
														profileTitle={member.job}
														profilePosition={member?.chairedTitles[0]?.chairedTitle}
														profilePositionSecondary={member?.chairedTitles[1]?.chairedTitle}
													/>
												)}
											</div>
											<NavigationPortal heading={navHeading} navigationItemsPortal={navigationItemsPortal} style="primary" />
											<nav className={styles.HeaderPortal_service} role="ServiceNavigation">
												<ul className={styles.HeaderPortal_serviceList}>
													<li className={classNames(styles.HeaderPortal_serviceItem, 'u-grid__cell')}>
														<button
															className={classNames(styles.HeaderPortal_serviceLink)}
															title="Log out"
															onClick={signOutClick}
														>
															<Icon className={styles.HeaderPortal_serviceIcon} size="md">
																<LogOutIcon />
															</Icon>
															<span className={styles.HeaderPortal_serviceText}>Log out</span>
														</button>
													</li>
												</ul>
											</nav>
										</GridCell>
									</Grid>
								</div>
							</div>
						)}
					</Grid>
				</Container>
			</header>
		</section>
	);
};
