import { LinkButton, Icon, GridCell } from 'ui/components';
import styles from './Newsletter.module.scss';
import { ReactComponent as Corp } from 'ui/icons/icon-corp-brand-secondary.svg';
export interface NewsletterProps {
	emailLabel?: string;
	moduleLink?: Umbraco.Link;
	hideCorp?: boolean;
}

export const Newsletter: React.FC<NewsletterProps> = ({ emailLabel, moduleLink, hideCorp = false }) => {
	return (
		// <GridCell mobileWidth="100" desktopWidth="33">
		<article className={styles.NewsLetter}>
			{/* <p className={styles.Newsletter_label}>{emailLabel}</p> */}
			{!hideCorp && (
				<Icon className={styles.NewsLetter_corp}>
					<Corp />
				</Icon>
			)}

			<LinkButton
				className={styles.Newsletter_link}
				url="https://us14.list-manage.com/subscribe?u=f9d52c146d70670a986cb83e6&id=55b9b016dc"
				style="primary"
			>
				{moduleLink?.name}
			</LinkButton>
		</article>
		// </GridCell>
	);
};
