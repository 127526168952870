import classNames from 'classnames';
import styles from './ArticleByline.module.scss';
import { Grid, GridCell } from 'ui/components';

export interface ArticleBylineProps {
	className?: string;
	fullName?: string;
	workTitle?: string;
}

export const ArticleByline: React.FC<ArticleBylineProps> = ({ className, fullName, workTitle }) => (
	<Grid full wrap className={classNames(styles.ArticleTextNews, className)}>
		<GridCell>
			<div className={classNames(styles.ArticleByline, className)}>
				<div className={styles.ArticleByline_name}>Af {fullName}</div>
				<div className={styles.ArticleByline_title}>{workTitle}</div>
			</div>
		</GridCell>
	</Grid>
);
