import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { Icon } from 'ui/components';
import styles from './ViewToggler.module.scss';
import { ReactComponent as ListIcon } from 'ui/icons/icon-list.svg';
import { ReactComponent as GridIcon } from 'ui/icons/icon-grid.svg';

export interface ViewTogglerProps {
	isListView: boolean;
	setViewAsList: (isListView: boolean) => void;
}

export const ViewToggler: React.FC<ViewTogglerProps> = ({ isListView, setViewAsList }) => {
	return (
		<div className={styles.ViewToggler}>
			<button
				className={classNames(styles.ViewToggler_button, { [styles.ViewToggler_button_isActive]: !isListView })}
				onClick={() => setViewAsList(false)}
			>
				{/* <span className={styles.ViewToggler_buttonText}>Filter listevisning</span> */}
				<Icon className={styles.ViewToggler_icon} size="md" aria-label="Open dialog">
					<GridIcon />
				</Icon>
			</button>
			<button
				className={classNames(styles.ViewToggler_button, { [styles.ViewToggler_button_isActive]: isListView })}
				onClick={() => setViewAsList(true)}
			>
				{/* <span className={styles.ViewToggler_buttonText}>Filter listevisning</span> */}
				<Icon className={styles.ViewToggler_icon} size="md" aria-label="Open dialog">
					<ListIcon />
				</Icon>
			</button>
		</div>
	);
};
