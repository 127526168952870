import classNames from 'classnames';
import { ReactComponent as DEFLogo } from 'ui/icons/logo-primary.svg';
import { ReactComponent as DEFLogoSmall } from 'ui/icons/logo-secondary.svg';
import styles from './Logo.module.scss';
import { Icon } from 'ui/components';

export interface LogoProps {
	className?: string;
	withText?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ className, withText }) => (
	<a className={classNames(styles.Logo, className)} href="/">
		<Icon className={styles.Logo_asset} size="xl">
			{withText ? <DEFLogo /> : <DEFLogoSmall />}
		</Icon>
	</a>
);
