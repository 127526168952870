import classNames from 'classnames';
import styles from './BlurbDownloadCard.module.scss';
import { Heading, GridCell, LinkButton, RichText } from 'ui/components';
import { formatDatePlain } from 'helpers/date';

export interface BlurbDownloadCardProps {
	className?: string;
	content: {
		properties: {
			heading?: string;
			text?: string;
			date?: Date;
			session?: string;
			link: Umbraco.Link;
		};
	};
}

export const BlurbDownloadCard: React.FC<BlurbDownloadCardProps> = ({ content, className }) => {
	const heading = content.properties.heading;
	const text = content.properties.text;
	const date = content.properties.date;
	const session = content.properties.session;
	const link = content.properties.link;

	return (
		<GridCell className={classNames(styles.BlurbDownloadCard, className)}>
			<div className={classNames(styles.BlurbDownloadCard_inner, className)}>
				<div className={styles.BlurbDownloadCard_textbox}>
					<Heading headingLevel="h3">{heading}</Heading>
					<div className={styles.BlurbDownloadCard_meta}>
						<span className={styles.BlurbDownloadCard_session}>{session}</span>
						{formatDatePlain(new Date(date), 'dd.mm.yyyy')}
					</div>
					<RichText className={classNames(styles.BlurbDownloadCard_text, 'RichText', className)} content={text} />
				</div>
				{link && (
					<LinkButton className={styles.ThumbnailList_link} url={link.url} target="_blank" style="tertiary">
						{link?.name}
					</LinkButton>
				)}
			</div>
		</GridCell>
	);
};
