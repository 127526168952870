import classNames from 'classnames';
import React, { useState } from 'react';
import { Timeline, Grid, GridCell, Container, TimelineProps, Heading, Paragraph, Icon, Button } from 'ui/components';
import styles from './ListTimeline.module.scss';
import { ReactComponent as Minus } from 'ui/icons/icon-minus.svg';
import { ReactComponent as Plus } from 'ui/icons/icon-plus.svg';

export interface ListTimelineProps {
	className?: string;
	heading?: string;
	text?: string;
	items: TimelineProps[];
	amountToShow?: number;
	style?: string;
	showMoreText?: string;
	showLessText?: string;
}

export const ListTimeline: React.FC<ListTimelineProps> = ({ className, items, heading, text, showMoreText, showLessText, amountToShow = 3 }) => {
	const [showMore, setShowMore] = useState(false);

	const toggleShowMore = (e) => {
		setShowMore((prevValue) => !prevValue);
	};
	return (
		<Container width="Standard" className={classNames(styles.ListTimeline, className)}>
			<Grid full wrap className={styles.ListTimeline_grid}>
				<GridCell className={styles.ListTimeline_top}>
					{heading && (
						<Heading className={styles.ListTimeline_heading} headingLevel="h3">
							{heading}
						</Heading>
					)}
					{text && <Paragraph className={styles.ListTimeline_text}>{text}</Paragraph>}
				</GridCell>
				<GridCell className={styles.ListTimeline_bottom}>
					<ul
						className={classNames(styles.ListTimeline_items, {
							[styles.ListTimeline_items___isShowMore]: showMore,
						})}
					>
						{items.map(
							(args, index) =>
								(index < amountToShow || showMore) && (
									<Timeline className={styles.ListTimeline_item} key={index} {...args}></Timeline>
								),
						)}
					</ul>
				</GridCell>
				{items.length > amountToShow && (
					<aside className={styles.ListTimeline_linkWrapper} onClick={toggleShowMore}>
						{showMoreText && (
							<button className={styles.ListTimeline_link}>
								{!showMore && <span>{showMoreText}</span>}
								{showMore && <span>{showLessText}</span>}
							</button>
						)}
					</aside>
				)}
			</Grid>
		</Container>
	);
};
