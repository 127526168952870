import classNames from 'classnames';
import styles from './TopBanner.module.scss';
import { Heading, Picture, LinkButton, Icon, Container, Grid, GridCell, RichText } from 'ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { parallax } from 'helpers/parallax';
import { scrollDetector } from 'helpers/scrollDetector';
import { ReactComponent as CorpBrandSmallIcon } from 'ui/icons/icon-corp-brand-small.svg';

export interface TopBannerProps {
	className?: string;
	heading?: string;
	image?: Umbraco.Image;
	text?: string;
	primaryLink?: Umbraco.Link;
	secondaryLink?: Umbraco.Link;
	style?: 'min';
}

export const TopBanner: React.FC<TopBannerProps> = ({ className, heading, image, text, style, primaryLink, secondaryLink }) => {
	const ctaContainer = useRef(null);
	const [ctaMultiline, setCtaMultilien] = useState(false);

	useEffect(() => {
		parallax();
		scrollDetector();
		if (ctaContainer.current.clientHeight > 60) {
			setCtaMultilien(true);
		}
	}, [ctaMultiline]);
	return (
		<header className={classNames(styles.TopBanner, styles[`TopBanner___${style}`], className)} data-container>
			<div className={styles.TopBanner_bgFilter} />
			<Container width="Standard" className={styles.TopBanner_content}>
				<Grid wrap>
					<GridCell desktopWidth="100" className={styles.AboutSection_contentWrapper}>
						<Icon className={styles.TopBanner_corpBrand} size="md">
							<CorpBrandSmallIcon />
						</Icon>
						<Heading headingLevel="h1" className={styles.TopBanner_heading}>
							{heading}
						</Heading>
						<RichText className={classNames(styles.TopBanner_text, 'RichText RichText___negative', className)} content={text} />

						<aside className={classNames(styles.TopBanner_cta, ctaMultiline ? styles.TopBanner_cta___stacked : '')} ref={ctaContainer}>
							{primaryLink && (
								<LinkButton className={styles.TopBanner_linkPrimary} url={primaryLink.url} style="primary">
									{primaryLink.name}
								</LinkButton>
							)}
							{secondaryLink && (
								<LinkButton className={styles.TopBanner_linkSecondary} url={secondaryLink.url} style="primary">
									{secondaryLink.name}
								</LinkButton>
							)}
						</aside>
					</GridCell>
				</Grid>
			</Container>
			{image && (
				<div className={styles.TopBanner_mediabox_wrapper} data-parallax>
					<Picture
						relativeUrl={image?.relativeUrl}
						properties={image.properties}
						focalPoint={image.focalPoint}
						aspectRatio={0.79}
						sizes="(min-width: 960px) 50vw, 100vw"
						className={styles.TopBanner_mediabox}
						lazyload={false}
						useCustomPosition={true}
					/>
				</div>
			)}
		</header>
	);
};
