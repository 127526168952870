import classNames from 'classnames';
import { ComponentProps } from 'react';
import styles from './DesktopVerticalTabMenuItem.module.scss';

type DesktopVerticalTabMenuItemProps = ComponentProps<'button'> & {
	selected: boolean;
	className?: string;
	children: string;
};
export const DesktopVerticalTabMenuItem: React.FC<DesktopVerticalTabMenuItemProps> = ({ selected, className, children, ...restProps }) => {
	return (
		<button
			className={classNames(
				styles.button,
				{
					[styles.button_selected]: selected,
				},
				className,
			)}
			{...restProps}
			title={children}
		>
			{children}
		</button>
	);
};
