import classNames from 'classnames';
import { Icon, Logo } from 'ui/components';
import { useState } from 'react';
import styles from './SearchCludo.module.scss';
import { ReactComponent as IconLoop } from 'ui/icons/icon-loop.svg';

export interface SearchCludoProps {
	className?: string;
	handleCloseSearch?: () => void;
	theme?: 'def' | 'member' | 'portal';
}

export const SearchCludo: React.FC<SearchCludoProps> = ({ className, handleCloseSearch, theme = 'def' }) => {
	return (
		<aside className={classNames(styles.SearchCludo, styles[theme])}>
			<Logo className={styles.SearchCludo_logo} withText={true} />
			<span className={styles.SearchCludo_iconClose} role="navigation" aria-haspopup="true" tabIndex={0} onClick={handleCloseSearch}>
				<span className={styles.SearchCludo_iconBar}></span>
				<span className={styles.SearchCludo_iconBar}></span>
				<span className={styles.SearchCludo_iconBar}></span>
				<span className={styles.SearchCludo_iconBar}></span>
			</span>
			<span className={styles.SearchCludo_iconCloseText}>Luk</span>

			<div id="cludoNav" className={styles.SearchCludo_inputWrapper}>
				<input type="search" className={styles.SearchCludo_input} placeholder="Indtast din søgning her" />
				<button type="submit" className={styles.SearchCludo_button} aria-hidden="true">
					<Icon className={styles.SearchCludo_iconLoop} size="md">
						<IconLoop />
					</Icon>
				</button>
			</div>
		</aside>
	);
};
