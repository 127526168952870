import styles from './FilterList.module.scss';
import { useState } from 'react';
import { Form, Icon, FormFieldCheckbox } from 'ui/components';
import { Member } from 'application/repositories/membersRepository';

export interface FilterListProps {
	className?: string;
	primaryLabel: string;
	secondaryLabel: string;
	onChangeRole: (e) => void;
	onChangeStatus: (e) => void;
	members?: Member[];
	selectedRoles?: string[];
	selectedStatuses?: string[];
}

export const FilterList: React.FC<FilterListProps> = ({
	primaryLabel,
	secondaryLabel,
	onChangeRole,
	onChangeStatus,
	members,
	selectedRoles,
	selectedStatuses,
	className,
}) => {
	const memberRoles = new Set<string>();
	const memberStatus = new Set<string>();
	members.forEach((element) => {

		element.chairedTitles.forEach(chairedtitle => {
			if(chairedtitle?.chairedTitle != undefined){
			memberRoles.add(chairedtitle?.chairedTitle);
			}	
		});
		
		if (element.event?.type != undefined) {
			memberStatus.add(element.event?.type);
		}
	});
	return (
		<>
			<Form className={styles.FilterList}>
				<fieldset className={styles.FilterList_fieldset}>
					<aside className={styles.FilterList_primary}>
						<legend className={styles.FilterList_legend}>{primaryLabel}</legend>
						{Array.from(memberRoles).map((memberRole, index) => {
							return (
								
								<FormFieldCheckbox
									key={index}
									id={memberRole}
									name={memberRole}
									label={memberRole}
									checked={selectedRoles.includes(memberRole)}
									onChange={onChangeRole}
									className={styles.FilterList_checkbox}
								/>
							);
						})}
					</aside>
				</fieldset>
				<fieldset className={styles.FilterList_fieldset}>
					<aside className={styles.FilterList_secondary}>
						<legend className={styles.FilterList_legend}>{secondaryLabel}</legend>
						{Array.from(memberStatus).map((memberStatus, index) => {
							return (
								<FormFieldCheckbox
									key={index}
									id={memberStatus}
									name={memberStatus}
									label={memberStatus}
									checked={selectedStatuses.includes(memberStatus)}
									onChange={onChangeStatus}
									className={styles.FilterList_checkbox}
								/>
							);
						})}
					</aside>
				</fieldset>
			</Form>
		</>
	);
};
