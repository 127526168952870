import classNames from 'classnames';
import styles from './NewsModule.module.scss';
import { Heading, LinkButton } from 'ui/components/1-atoms';
import { ListNewsModule, Container, Grid, GridCell } from 'ui/components';
import { formatDate, formatTime } from 'helpers/date';

/*
 *
 * This component is only used kongres.def.dk. See LatestNewsModuleFeature for other sites.
 *
 */

export interface NewsModuleProps {
	className?: string;
	header?: string;
	moduleLink?: Umbraco.Link;
	rootFolder?: Umbraco.Page;
	count?: number;
	offset?: number;
	link?: Umbraco.Link;
	newsFetcher?: (rootId: number, limit: number, offset: number) => Models.NewsCard[]; // on kongres.def.dk this will return the NewsCards via GraphQL
}

export const NewsModule: React.FC<NewsModuleProps> = ({ className, header, moduleLink, rootFolder, count, offset, link, newsFetcher }) => {
	const rootId = rootFolder ? rootFolder.id : 0;
	const limitQuery = count === 0 ? null : count;
	const offsetQuery = offset >= 0 ? offset : null;
	const NewsModule = newsFetcher(rootId, limitQuery, offsetQuery);

	NewsModule.map((obj) => {
		// Null check is done by a a string comparison due to umbraco returning a string rather than Date
		const formatedDate = formatDate(obj.updatedDate, 'dd.mm.yyyy');
		const nullDate = formatedDate === '31.12.0';

		if (!nullDate) {
			obj.date = obj.updatedDate;
		}
		return { ...obj, date: new Date(obj.date) };
	});
	// The value of "sortedNews" is used to load the page with the news already sorted based on "Date" or "UpdatedNewsDate"
	const sortedNews = NewsModule.sort((objA, objB) => Number(objB.date) - Number(objA.date));

	if (!NewsModule) return <></>;

	return (
		<section className={classNames(styles.NewsModule, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell>
						<section className={styles.NewsModule_wrapper}>
							<div className={styles.NewsModule_container}>
								<Heading className={styles.NewsModule_heading} headingLevel="h3">
									{' '}
									{header}{' '}
								</Heading>
								<div className={styles.NewsModule_contentWrapper}>
									<ListNewsModule items={sortedNews} desktopWidth="100" mobileWidth="100" link={link}></ListNewsModule>
								</div>
								<div className={styles.NewsModule_buttonWrapper}>
									{moduleLink && (
										<LinkButton className={styles.ThumbnailList_link} url={moduleLink.url} style="secondary">
											{moduleLink.name}
										</LinkButton>
									)}
								</div>
							</div>
						</section>
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
