import React from 'react';
import { ProductCard, ProductCardProps } from './ProductCard';
import { Story, Meta } from '@storybook/react';

export default {
	title: 'Molecules/Card/ProductCard',
	component: ProductCard,
} as Meta;

const Template: Story<ProductCardProps> = (args) => <ProductCard {...args} />;

export const Default: Story<ProductCardProps> = Template.bind({});
Default.args = {
	columnWidth: '50',
	imageSizes: '(max-width: 768px) 50vw, 50vw',
	product: {
		id: '1',
		name: 'productName',
		price: 400,
		category: 'category',
		image: {
			url: 'https://picsum.photos/300/300',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			url: '#',
		},
		variants: [
			{
				id: '1',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
			},
		],
	} as Models.Product,
};

export const ManyVariants: Story<ProductCardProps> = Template.bind({});
ManyVariants.args = {
	...Default.args,
	product: {
		id: '2',
		name: 'Produkt1',
		price: 1055,
		category: 'Clothing',
		image: {
			url: 'https://picsum.photos/300/300',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '2',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
			},
			{
				id: '21',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 5,
			},
			{
				id: '22',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
			},
			{
				id: '23',
				type: 'sizes',
				name: 'XL',
				price: 1552,
				quantity: 5,
			},
			{
				id: '24',
				type: 'sizes',
				name: 'XXL',
				price: 22,
				quantity: 5,
			},
		],
	},
};

export const FewVariants: Story<ProductCardProps> = Template.bind({});
FewVariants.args = {
	...Default.args,
	product: {
		id: '3',
		name: 'Produkt1',
		price: 155,
		category: 'Clothing',
		image: {
			url: 'https://picsum.photos/300/300',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '3',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
			},
			{
				id: '31',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 5,
			},
			{
				id: '32',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
			},
		],
	},
};

export const SoldOut: Story<ProductCardProps> = Template.bind({});
SoldOut.args = {
	...Default.args,
	product: {
		id: '4',
		name: 'Produkt1',
		price: 550,
		category: 'Clothing',
		image: {
			url: 'https://picsum.photos/300/300',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '41',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 0,
			},
			{
				id: '42',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 0,
			},
			{
				id: '43',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
			},
		],
	},
};
