import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { Form, Spinner } from 'ui/components';
import styles from './Contact.module.scss';

export interface ContactProps {
	className?: string;
	title?: string;
	text?: string;
	departments?: {
		defaultBranch: string;
		branches: { label: string; value: string; address: string }[];
	};
	selectValue?: string;
}

export const Contact: React.FC<ContactProps> = ({ className, title, departments, selectValue, text }) => {
	const [changedSelectValue, setChangedSelectValue] = useState('');

	useEffect(() => {
		setChangedSelectValue(selectValue);
	}, [selectValue]);

	const changeSelectStory = (e) => {
		setChangedSelectValue(e.target.value);
	};

	return (
		<>
			{!departments ? null : (
				<section className={classNames(styles.Contact, className)}>
					{title && <p className={styles.Contact_formTitle}>{title}</p>}
					<Form className={styles.Contact_form}>
						<div className={styles.Contact_selectWrapper}>
							<select defaultValue={selectValue} onChange={changeSelectStory} className={styles.Contact_select}>
								{departments?.branches.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
							<div
								className={(styles.Contact_result, className, 'RichText')}
								dangerouslySetInnerHTML={{
									__html: departments?.branches.find((option) => option.value === changedSelectValue)?.address,
								}}
							></div>
						</div>
					</Form>
				</section>
			)}
		</>
	);
};
