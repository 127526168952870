import classNames from 'classnames';
import { GridCell, Heading, Picture } from 'ui/components';
import styles from './NewsCard.module.scss';

import { formatDate, formatTime } from 'helpers/date';

export const NewsCard: React.FC<Models.NewsCard> = ({ link, className, heading, kicker, date, image, session, updatedDate }) => {
	const dateToUse =  date;

	return (
		<GridCell className={classNames(styles.NewsCard, className)}>
			<a className={styles.NewsCard_link} href={link?.url} target={link?.target} title={link?.name}>
				{image && (
					<div className={styles.NewsCard_media}>
						<span className={styles.NewsCard_mediabox}>
							<Picture
								className={styles.NewsModuleCard_picture}
								aspectRatio={0.5625}
								relativeUrl={image?.relativeUrl}
								properties={image.properties}
								focalPoint={image.focalPoint}
								sizes="100vw"
							/>
						</span>
					</div>
				)}
				<div className={styles.NewsCard_textbox}>
					{session && <div className={styles.NewsCard_session}>{session}</div>}
					{kicker && <div className={styles.NewsCard_kicker}>{kicker}</div>}

					<div className={styles.NewsCard_date}>
						<span data-date={formatDate(dateToUse, 'dd.mm.yyyy')}>{formatDate(dateToUse, 'dd.mm.yy')}</span> kl. {formatTime(dateToUse, 'hh.mm')}
					</div>

					<Heading headingLevel="h3" className={styles.NewsCard_heading}>
						{heading}
					</Heading>
				</div>
			</a>
		</GridCell>
	);
};
