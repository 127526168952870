import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import styles from './MemberCard.module.scss';

import { CardLabel, Icon, Heading } from 'ui/components';
import { ReactComponent as IconDown } from 'ui/icons/icon-chevron-down.svg';
import { ReactComponent as IconUp } from 'ui/icons/icon-chevron-up.svg';
import { ReactComponent as DateIcon } from 'ui/icons/date-icon.svg';
import { ReactComponent as EmailIcon } from 'ui/icons/email-icon.svg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-icon.svg';
import { formatDate } from 'helpers/date';
import { Member } from 'application/repositories/membersRepository';

export interface MemberCardProps {
	className?: string;
	member: Member;
	isListView?: boolean;
	setIsListView?: (isListView: boolean) => void;
}

export const MemberCard: React.FC<MemberCardProps> = ({ className, member, isListView }) => {

	const { chairedTitles, emails, job, name, phones, event, privatePhones,privateEmails } = member;
	const [isExpand, setIsExpand] = React.useState(false);

	const onExpand = () => {
		// Delay to match with css transition
		setTimeout(() => {
			setIsExpand(!isExpand);
		}, 270);
	};

	const eventColor: ComponentProps<typeof CardLabel>['backgroundColor'] =
		event?.type === 'Udlært'
			? 'var(--color-tag-blue)'
			: event?.type === 'Nyansat'
			? 'var(--color-tag-green)'
			: event?.type === 'Jubilæum'
			? 'var(--color-tag-orange)'
			: event?.type === 'Fødselsdag'
			? 'var(--color-tag-red)'
			: event?.type === 'Nyt medlem'
			? 'var(--color-primary)'
			: null;

	return (
		<div className={classNames(styles.MemberCard_container, { [styles.isListView]: isListView }, className)}>
			{event?.type.length > 0 && <CardLabel title={`${event?.type} ${formatDate(event?.date, 'dd.mm.yy')}`} backgroundColor={eventColor} />}

			<div className={styles.MemberCard}>
				<div className={styles.MemberCard_header}>
					<button onClick={onExpand} className={styles.MemberCard_expandable}>
						<Icon size="md" ariaHidden={false} className={styles.MemberCard_expandable_icon}>
							{isExpand ? <IconUp viewBox="0 0 20 5" /> : <IconDown viewBox="0 0 20 5" />}
						</Icon>
					</button>

					<div>
						{/* TODO: which title should be shown when a member has 2? */}
						{chairedTitles?.map((title, index) => (
							<Heading headingLevel="h5" key={index} style="default" className={styles.chairedTitle}>
								{title?.chairedTitle}
							</Heading>
						))}

						<Heading headingLevel="h3" style="default">
							{name}
						</Heading>

						<Heading headingLevel="h4" style="default" className={styles.jobTitle}>
							{job}
						</Heading>
					</div>
				</div>

				<div className={classNames(styles.MemberCard_body, isExpand && styles.isExpand)}>
					<div className={styles.MemberCard_contact_info}>
						<div className={styles.MemberCard_contact_info_row}>
							<div className={styles.MemberCard_contact_info_icon}>
								<Icon size="md" aria-hidden={false} className={styles.MemberCard_contact_info_row_icon}>
									<PhoneIcon viewBox="0 1 20 17" />
								</Icon>
							</div>
							<div>
								{privatePhones?.map((phone, index) => (
									<a key={index} href={`tel:${phone}`} className={styles.MemberCard_contact_info_row_data}>
										{phone}
									</a>
								))}
							</div>
						</div>
						<div className={styles.MemberCard_contact_info_row}>
							<div>
								<Icon size="md" aria-hidden={false} className={styles.MemberCard_contact_info_row_icon}>
									<EmailIcon viewBox="0 -0.5 20 13" />
								</Icon>
							</div>
							<div>
								<div className={styles.MemberCard_contact_info_row_data_wrapper}>
									{privateEmails?.map((email, index) => (
										<a
											key={index}
											href={`mailto:${email}`}
											className={classNames(styles.MemberCard_contact_info_row_data_email_link, styles.MemberCard_contact_info_row_data)}
										>
											{email}
										</a>
									))}
								</div>
							</div>
						</div>
						<div className={styles.MemberCard_contact_info_row}>
							<div>
								<Icon size="md" aria-hidden={false} className={styles.MemberCard_contact_info_row_icon}>
									<DateIcon viewBox="1 2 20 16" />
								</Icon>
							</div>
							<div>
								<p className={styles.MemberCard_contact_info_row_data}>{formatDate(member?.birthday, 'dd.mm.yyyy')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
