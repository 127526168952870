import classNames from 'classnames';
import { Container, Grid, GridCell, SituationCard, Heading } from 'ui/components';
import styles from './ListSituation.module.scss';

export interface ListSituationProps {
	className?: string;
	heading?: string;
	situationItems: Umbraco.SituationCard[];
}

export const ListSituation: React.FC<ListSituationProps> = ({ className, situationItems, heading }) => {
	return (
		<section className={classNames(styles.ListSituation, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell className={styles.ListSituation_top}>
						{heading && (
							<Heading className={styles.ListSituation_heading} headingLevel="h3">
								{heading}
							</Heading>
						)}
					</GridCell>
					<GridCell className={styles.ListSituation_bottom}>
						{situationItems?.map((situation, index) => {
							// Talk to the client about a logic that makes sense for the highlighted cards :)
							const highlighted = index + 1 === 5 || index + 1 === 13 || index + 1 === 21 || index + 1 === 29 || index + 1 === 37 || index + 1 === 45;
							return <SituationCard key={index} {...situation?.properties} isHighlighted={highlighted} />;
						})}
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
