import { Link, Icon, Button } from 'ui/components';
import styles from './Breadcrumb.module.scss';
import classNames from 'classnames';
import { ReactComponent as IconHome } from 'ui/icons/icon-home.svg';
import { useState } from 'react';

export interface BreadcrumbProps {
	breadcrumbItems: Array<BreadcrumbItemProps>;
	url: string;
	isGlobalContent: boolean;
	rootName : string;
}

interface BreadcrumbItemProps {
	url?: string;
	title: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ isGlobalContent, rootName, breadcrumbItems, url }) => {
	const [breadCrumbToggle, setbreadCrumbToggle] = useState(false);
	const contentClassname = breadCrumbToggle ? 'no' : '';
	return (
		<>
			<ul className={styles.Breadcrumb} data-hidden={contentClassname}>
				<li className={styles.Breadcrumb_item}>
					<a href="/"className={styles.Breadcrumb_url}>
						{isGlobalContent ? rootName : breadcrumbItems[0].title}
					</a>
				</li>
				{breadcrumbItems.length >  2 &&
				<li className={styles.Breadcrumb_dots} onClick={() => setbreadCrumbToggle(!breadCrumbToggle)}>
					<span className={styles.Breadcrumb_dots__bg}>...</span>
				</li>}
				{breadcrumbItems?.map((breadcrumbItem: BreadcrumbItemProps, index) =>
					index === 0 && !isGlobalContent ? null : (
						<li key={index} className={styles.Breadcrumb_item}>
							{breadcrumbItem.url ? (
								<a
									className={styles.Breadcrumb_url}
									href={index === 0 && breadcrumbItems.length > 2 ? `/${breadcrumbItem.url}` : breadcrumbItem.url}
								>
									{breadcrumbItem.title.split('-').join(' ')}
								</a>
							) : (
								<p className={classNames(styles.Breadcrumb_url, styles.Breadcrumb_url___current)}>{breadcrumbItem.title}</p>
							)}
						</li>
					),
				)}
			</ul>
		</>
	);
};
