import classNames from 'classnames';
import React from 'react';
import { Paragraph } from 'ui/components';
import styles from './ProfileCard.module.scss';

export interface ProfileCardProps {
	className?: string;
	profileName: string;
	profileTitle: string;
	profilePosition: string;
	profilePositionSecondary: string;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({ className, profileName, profileTitle, profilePosition, profilePositionSecondary }) => {
	return (
		<article className={classNames(styles.ProfileCard, className)}>
			<Paragraph className={styles.ProfileCard_name}>{profileName}</Paragraph>
			<Paragraph className={styles.ProfileCard_title}>{profileTitle}</Paragraph>
			<Paragraph className={styles.ProfileCard_position}>{profilePosition}</Paragraph>
			<Paragraph className={styles.ProfileCard_position}>{profilePositionSecondary}</Paragraph>
		</article>
	);
};
