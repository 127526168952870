import React, { useCallback } from 'react';
import {
	GoogleMap,
	GoogleMapProps,
	LoadScriptNext,
} from '@react-google-maps/api';

export interface MapWrapperProps extends GoogleMapProps {
	apiKey: string;
	zoom?: number;
	style?: { width: string; height: string };
	cluster?: boolean;
	children: React.ReactElement<Models.MapsMarker>[];
}

export const MapWrapper: React.FC<MapWrapperProps> = ({
	apiKey,
	zoom = 4,
	center = null,
	style,
	options = {},
	cluster = false,
	children,
}) => {
	const centerAndZoomOnMarkers = (
		markers: React.ReactElement<Models.MapsMarker>[],
		map: google.maps.Map<Element>,
	) => {
		const bounds = new window.google.maps.LatLngBounds();

		markers.forEach((marker) => {
			bounds.extend(
				new window.google.maps.LatLng(
					marker.props.latitude,
					marker.props.longitude,
				),
			);
		});

		map.fitBounds(bounds);
	};

	const onLoad = useCallback(
		(map: google.maps.Map) => {
			if (!center) centerAndZoomOnMarkers(children, map);
		},
		[center, children],
	);

	return (
		<>
			<LoadScriptNext googleMapsApiKey={apiKey}>
				<GoogleMap
					mapContainerStyle={style}
					zoom={zoom}
					center={center}
					onLoad={onLoad}
					options={options}
				>
					{!cluster && children}
				</GoogleMap>
			</LoadScriptNext>
		</>
	);
};
