import { Member } from 'application/repositories/membersRepository';
import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Container, GridCell, Heading, Spinner, Icon } from 'ui/components';
import { ReactComponent as EmailIcon } from 'ui/icons/email-icon.svg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-icon.svg';
import { ReactComponent as AddressIcon } from 'ui/icons/icon-address.svg';
import styles from './BranchContactInfo.module.scss';
export interface BranchContactInfoProps {
	className?: string;
	contactInfoHeadline?: string;
	phoneTimesHeadline?: string;
	openingHoursHeadline?: string;
	branchName?: string;
	address?: string;
	phoneNumbers?: { label: string; number: string }[];
	phoneOpeningHours?: { label: string; period: string }[];
	openingHours?: { label: string; period: string }[];
	email?: string;
	shortAddress?: string;
	branchId?: string;
}

export const BranchContactInfo: React.FC<BranchContactInfoProps> = ({
	className,
	contactInfoHeadline,
	phoneTimesHeadline,
	openingHoursHeadline,
	branchName,
	address,
	phoneNumbers,
	phoneOpeningHours,
	openingHours,
	email,
	shortAddress,
	branchId,
}) => {
	return (
		<>
			{!branchName ? (
				<Spinner />
			) : (
				<Container className={classNames(styles.BranchContactInfo, className)} width="Standard">
					<GridCell>
						<div className={styles.BranchContactInfo_sectionWrapper}>
							<section className={styles.BranchContactInfo_section}>
								<p className={styles.BranchContactInfo_sectionTitle}>{contactInfoHeadline}</p>
								<div className={styles.BranchContactInfo_phoneWrapper}>
									<Icon size="md" aria-hidden={false} className={styles.BranchContactInfo_iconPhone}>
										<PhoneIcon viewBox="0 1 20 17" />
									</Icon>
									<aside className={styles.BranchContactInfo_phoneNumbers}>
										{phoneNumbers &&
											phoneNumbers.map((p) => (
												<p
													key={p.label}
													className={classNames(
														styles.BranchContactInfo_weekdaysItems,
														styles.BranchContactInfo_weekdaysItems___phoneNumbers,
													)}
												>
													{p.label && (
														<span
															className={classNames(
																styles.BranchContactInfo_weekdays,
																styles.BranchContactInfo_weekdays___phoneNumbers,
															)}
														>
															{p.label}
														</span>
													)}
													<a className={styles.BranchContactInfo_phone} href={`tel:${p.number}`}>
														{p.number}
													</a>
												</p>
											))}
									</aside>
								</div>
								<div className={styles.BranchContactInfo_emailWrapper}>
									<Icon size="md" aria-hidden={false} className={styles.BranchContactInfo_iconEmail}>
										<EmailIcon viewBox="0 -0.5 20 13" />
									</Icon>
									<a className={styles.BranchContactInfo_email} href={`mailto:${email}`}>
										{email}
									</a>
								</div>
								<div className={styles.BranchContactInfo_addressWrapper}>
									<Icon size="md" aria-hidden={false} className={styles.BranchContactInfo_iconAddress}>
										<AddressIcon viewBox="0 -0.5 20 13" />
									</Icon>
									<span className={styles.BranchContactInfo_addressDetail}>{shortAddress}</span>
								</div>
							</section>
							<section className={styles.BranchContactInfo_section}>
								<p className={styles.BranchContactInfo_sectionTitle}>{phoneTimesHeadline}</p>
								{phoneOpeningHours &&
									phoneOpeningHours.map((p) => (
										<p key={p.label} className={styles.BranchContactInfo_weekdaysItems}>
											<span className={styles.BranchContactInfo_weekdays}>{p.label}</span>
											<span className={styles.BranchContactInfo_weekdaysHours}>{p.period}</span>
										</p>
									))}
							</section>
							<section className={styles.BranchContactInfo_section}>
								<p className={styles.BranchContactInfo_sectionTitle}>{openingHoursHeadline}</p>
								{openingHours &&
									openingHours.map((p) => (
										<p key={p.label} className={styles.BranchContactInfo_weekdaysItems}>
											<span className={styles.BranchContactInfo_weekdays}>{p.label}</span>
											<span className={styles.BranchContactInfo_weekdaysHours}>{p.period}</span>
										</p>
									))}
							</section>
						</div>
					</GridCell>
				</Container>
			)}
		</>
	);
};
