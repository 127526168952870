import useMediaQuery from './usehooks-ts/useMediaQuery';

/**
 * Breakpoints as defined in Website/src/ui/styles/0-globals/_globals.breakpoints.scss
 */
const breakpoints = <const>{
	xs: '320px',
	sm: '640px',
	md: '768px',
	lg: '1248px',
	xl: '1440px',
};

export const useMinWidth = (breakpoint: keyof typeof breakpoints): boolean => {
	return useMediaQuery('(min-width: ' + breakpoints[breakpoint] + ')');
};

export const useIsMobile = (): boolean => !useMinWidth('md');
