import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { Select } from 'ui/components/1-atoms/Select/Select';
import styles from './MobileVerticalTabMenu.module.scss';

export type MobileVerticalTabMenuItemValue = string;

type InheritedSelectProps = Omit<ComponentProps<typeof Select>, 'options'>;

type MobileVerticalTabMenuProps = InheritedSelectProps & {
	items: Array<{
		value: MobileVerticalTabMenuItemValue;
		label: string;
	}>;
	selectedItemValue: MobileVerticalTabMenuItemValue;
	onItemClick: (value: MobileVerticalTabMenuItemValue) => void;
};
export const MobileVerticalTabMenu: React.VFC<MobileVerticalTabMenuProps> = ({ items, selectedItemValue, className, onItemClick, ...restProps }) => {
	return (
		<Select
			defaultValue={selectedItemValue}
			className={classNames(styles.select, className)}
			options={items}
			onChange={(e) => onItemClick(e.target.value)}
			{...restProps}
		/>
	);
};
