import classNames from 'classnames';
import styles from './VideoCard.module.scss';
import { Heading, Player, RichText } from 'ui/components';
import { getFullImageUrl } from 'helpers/getFullImageUrl';

export interface VideoCardProps {
	className?: string;
	poster?: Umbraco.Image;
	heading?: string;
	text?: string;
	mediaURL: string;
	disableLightPlayer: boolean;
	playing: boolean;
	loop: boolean;
	controls: boolean;
}

export const VideoCard: React.FC<VideoCardProps> = ({ className, poster, mediaURL, heading, text, disableLightPlayer, playing, loop, controls }) => {
	return (
		<section className={styles.Video_wrapper}>
			<section className={classNames(styles.Video, className)}>
				<div className={styles.Video_textbox}>
					<Heading headingLevel="h2" className={styles.Video_title}>
						{heading}
					</Heading>
					<RichText className={classNames(styles.Video_text, 'RichText', className)} content={text} />
				</div>
				<div className={styles.Video_media}>
					<span className={styles.Video_mediabox}>
						<Player
							url={mediaURL}
							poster={poster?.relativeUrl && getFullImageUrl(poster.relativeUrl)}
							isLight={!disableLightPlayer}
							playing={playing}
							loop={loop}
							controls={!controls}
						/>
					</span>
				</div>
			</section>
		</section>
	);
};
