import classNames from 'classnames';
import styles from './SuperHeroSecondaryCard.module.scss';
import { Heading, Picture, Icon, LinkButton, RichText } from 'ui/components';
import { ReactComponent as PatternOne } from 'ui/icons/icon-pattern-one.svg';
import { ReactComponent as Corp } from 'ui/icons/icon-corp-brand.svg';
import React, { useEffect } from 'react';
import { parallax } from 'helpers/parallax';

export interface SuperHeroSecondaryCardProps {
	className?: string;
	heading?: string;
	image?: Umbraco.Image;
	kicker?: string;
	text?: string;
	moduleLink?: Umbraco.Link;
	style?: 'min';
}

export const SuperHeroSecondaryCard: React.FC<SuperHeroSecondaryCardProps> = ({ className, heading, image, kicker, text, style, moduleLink }) => {
	useEffect(() => {
		parallax;
	}, []);
	return (
		<header className={classNames(styles.SuperHeroSecondaryCard, styles[`SuperHeroSecondaryCard___${style}`], className)} data-container>
			<article className={styles.SuperHeroSecondaryCard_textbox}>
				<div className={styles.SuperHeroSecondaryCard_kicker}>{kicker}</div>
				<Heading headingLevel="h1" className={styles.SuperHeroSecondaryCard_heading}>
					{heading}
				</Heading>
				<RichText className={classNames(styles.SuperHeroSecondaryCard_text, 'RichText RichText___negative', className)} content={text} />
				{image && (
					<div className={styles.SuperHeroSecondaryCard_mediabox_wrapper} data-image>
						<Picture
							relativeUrl={image?.relativeUrl}
							properties={image.properties}
							sizes="100vw"
							className={styles.SuperHeroSecondaryCard_mediabox}
						/>
					</div>
				)}
			</article>
			<Icon className={styles.SuperHeroSecondaryCard_Corp}>
				<Corp />
			</Icon>
			{moduleLink && (
				<LinkButton className={styles.SuperHeroSecondaryCard_link} url={moduleLink.url} style="primary">
					{moduleLink.name}
				</LinkButton>
			)}
			<Icon className={styles.SuperHeroSecondaryCard_pattern}>
				<PatternOne />
			</Icon>
		</header>
	);
};
