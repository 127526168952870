import React, { useState, useEffect, useRef } from 'react';
import styles from './AccordionCalendarItem.module.scss';
import { Accordion } from '../../Accordion/Accordion';

export type AccordionCalendarItemProps = {
	title: string;
	tabSubTitle: string;
	items: Models.AccordionItem[];
	activeTab: (itemNumber: number) => void;
	itemNumber: number;
	newHeight?: number;
	ref?: React.RefObject<HTMLElement>;
	isActive: boolean;
};

// TODO: Mangler håndtering af allowMultiple: true/false
export const AccordionCalendarItem: React.FC<AccordionCalendarItemProps> = ({ itemNumber, activeTab, isActive, title, tabSubTitle, items }) => {
	const [open, isOpen] = useState(itemNumber === 0);
	const [height, setHeight] = useState();
	const itemHeights = useRef(null);
	const wrapperHeight = useRef(null);

	const clickHandle = () => {
		isOpen(!open);
	};

	useEffect(() => {
		// Due to Absolute position of the Accordion items the container needs to get a new height after each item expansion
		const newHeight = itemHeights.current?.clientHeight;
		setHeight(newHeight);
	}, [open]);

	return (
		<div
			ref={wrapperHeight}
			style={{ height: height }}
			className={styles.AccordionCalendarItem_wrapper}
			onClick={clickHandle}
			aria-selected={isActive}
		>
			<div
				aria-selected={isActive}
				className={styles.AccordionCalendarItem_title}
				onClick={() => {
					activeTab(itemNumber);
				}}
			>
				<span className={styles.AccordionCalendarItem_text}>{title}</span>
				<span className={styles.AccordionCalendarItem_date}>{tabSubTitle}</span>
			</div>
			<div className={styles.AccordionCalendarItem_content} style={{ display: isActive ? 'block' : 'none' }} onClick={clickHandle}>
				<div ref={itemHeights}>
					<Accordion accordionItems={items} />
				</div>
			</div>
		</div>
	);
};
