import classNames from 'classnames';
import styles from './AccordionFaq.module.scss';
import React from 'react';
import { Heading } from 'ui/components/1-atoms';
import { AccordionFaqItem, AccordionFaqItemProps } from '../AccordionFaq/AccordionFaqItem';

export interface AccordionFaqProps {
	className?: string;
	allowMultiple?: boolean;
	accordionItems: AccordionFaqItemProps[];
	header?: string;
}

export const AccordionFaq: React.FC<AccordionFaqProps> = ({ className, header, accordionItems }) => {
	return (
		<section className={classNames(styles.AccordionFaq_container, className)}>
			<div className={classNames(styles.Accordion, className)}>
				{header && (
					<Heading className={styles.AccordionFaq_header} headingLevel="h2">
						{' '}
						{header}{' '}
					</Heading>
				)}
				{accordionItems.map((accordionItem, index) => (
					<AccordionFaqItem {...accordionItem} key={index} />
				))}
			</div>
		</section>
	);
};
