import { createContext, useState } from 'react';

interface Props {
	children: React.ReactNode;
}

export interface AppState {
	showLoginModal: boolean;
	toggleShowLoginModal: () => void;
}

export const AppStateContext = createContext(null);

export const AppStateProvider: React.FC<Props> = ({ children }) => {
	const [showLoginModal, setShowLoginModal] = useState(false);

	const toggleShowLoginModal = () => {
		// Disbale body scroll when login modal is open
		if (!showLoginModal) {
			document.body.className = 'u-overflow-hidden';
		} else {
			document.body.className = '';
		}

		setShowLoginModal((showLoginModal) => !showLoginModal);
	};

	return <AppStateContext.Provider value={{ showLoginModal, toggleShowLoginModal }}>{children}</AppStateContext.Provider>;
};
