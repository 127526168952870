import { AppStateContext } from 'application/adapters/appState/appState';
import classNames from 'classnames';
import { parallax } from 'helpers/parallax';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, FormfieldString, Grid, GridCell, Heading, Icon, Link, LinkButton, Paragraph, Picture } from 'ui/components';
import { ReactComponent as IconKey } from 'ui/icons/icon-key.svg';
import styles from './Payment.module.scss';
import { RichText, Form } from 'ui/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Fail } from 'ui/icons/icon-fail.svg';



export interface PaymentProps {
	className?: string;
	paymentBody?: string;
	nameTitle?: string;
	namePlaceholder?:string,
	nameValidationError?:string,
	cPRTitle?: string;
	cPRplaceholder?:string;
	cPRValidationError?:string;
	belobTekst?: string;
	belobPlaceholder?:string;
	belobValidationError?:string;
	submitText?: string;
	termsLink?: Umbraco.Link;
	checkoutLink?: Umbraco.Link;
	ServerError?: string;
	isSuccess: boolean;
	isFail: boolean;
	cprIsFail:boolean;
	NameIsFail:boolean;
	AmountIsFail:boolean;
	isLoading: boolean;
	onClick: (data: formData) => void;
}
export interface formData {
	textareaContent: string;
	urlLocation: string;
	CPR: string;
	termsUrl: string;
	checkoutUrl: string;
	receipturl: string;
}
export const Payment: React.FC<PaymentProps> = ({
	className,
	paymentBody,
	nameTitle,
	namePlaceholder,
	nameValidationError,
	belobTekst,
	belobPlaceholder,
	belobValidationError,
	submitText,
	cPRTitle,
	cPRplaceholder,
	cPRValidationError,
	termsLink,
	checkoutLink,
	ServerError,
	isSuccess,
	isFail,
	cprIsFail,
	AmountIsFail,
	NameIsFail,
	isLoading,
	onClick
}) => {
	var cprIsError = false;

	const { toggleShowLoginModal } = useContext(AppStateContext);
	const [value, setValue] = useState('');


	useEffect(() => {
		parallax();
	}, []);


	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<formData>();
	const onSubmit: SubmitHandler<formData> = (data) => {
		onClick(data);
	};


	return (
		<>
   			<section className={classNames(styles.payment__secondary, className)}>


				<RichText className={classNames('RichText', className)} content={paymentBody} /></section>

			<section className={classNames(styles.Payment, className)}>

				<Form className={styles.Feedback_form} onSubmit={handleSubmit(onSubmit)}>
					<Paragraph className={styles.Feedback_inputHeading}>{nameTitle}
					{NameIsFail && (
					<>
					<a className={styles.Payment_failstar}>*</a>
					</>
				)}
					
					</Paragraph>
					<FormfieldString
						type='text'
						id='name'
						name='name'
						placeholder={namePlaceholder}
						className={styles.FormField_input}
						register={register} />

					<Paragraph className={styles.Feedback_inputHeading}>{cPRTitle} 
					
					{cprIsFail && (
					<>
					<a className={styles.Payment_failstar}>*</a>
					</>
				)}
					</Paragraph>

					<FormfieldString
						type='text'
						id='name'
						name='CPR'
						errorMessage='Udfyld et validt CPR-nummer'
						pattern='"^(?:(?:31(?:0[13578]|1[02])|(?:30|29)(?:0[13-9]|1[0-2])|(?:0[1-9]|1[0-9]|2[0-8])(?:0[1-9]|1[0-2]))\d{2}|290200[4-9]|2902(?:(?!00)[02468][048]|[13579][26])[0-3])\d{3}|0000000000$|^\S+$'
						placeholder={cPRplaceholder}
						className={styles.FormField_input}
						register={register} />



					<Paragraph className={styles.Feedback_inputHeading}>{belobTekst}
					{AmountIsFail && (
					<>
					<a className={styles.Payment_failstar}>*</a>
					</>
				)}
					</Paragraph>
					<FormfieldString
						type='text'
						id='Amount'
						name='Amount'
						placeholder={belobPlaceholder}
						className={styles.FormField_input}
						register={register} />

					<input
						type="hidden"
						name="termsurl"
						value={typeof window !== 'undefined' ? termsLink?.url : ''}
						{...register('termsUrl', { required: true })} />

					<input
						type="hidden"
						name="checkouturl"
						value={typeof window !== 'undefined' ? checkoutLink?.url : ''}
						{...register('checkoutUrl', { required: true })} />


					{errors.textareaContent && <span className={styles.Feedback_failText}>submitValidation</span>}
					<Button className={styles.Feedback_button} style="primary" type="submit" aria-label="Send">
						{submitText}
					</Button>



				</Form>
				{cprIsFail && (
					<span className={styles.Payment_failText}>
						<Icon className={styles.Feedback__messageIcon}>
							<Fail />
						</Icon>
						 {cPRValidationError}
					</span>
				)}
				{NameIsFail && (
					<span className={styles.Payment_failText}>
						<Icon className={styles.Feedback__messageIcon}>
							<Fail />
						</Icon>
						 {nameValidationError}
					</span>
				)}
				{AmountIsFail && (
					<span className={styles.Payment_failText}>
						<Icon className={styles.Feedback__messageIcon}>
							<Fail />
						</Icon>
						 {belobValidationError}
					</span>
				)}
				{isFail && (
					<span className={styles.Payment_failText}>
						<Icon className={styles.Feedback__messageIcon}>
							<Fail />
						</Icon>
						 {ServerError}
					</span>
				)}

			</section>
		</>
	);
};
