import classNames from 'classnames';
import styles from './Video.module.scss';
import { Heading, Player, Container, Grid, GridCell, RichText } from 'ui/components';
import { getFullImageUrl } from 'helpers/getFullImageUrl';

export interface VideoProps {
	className?: string;
	poster?: Umbraco.Image;
	heading?: string;
	text?: string;
	mediaURL: string;
}

export const Video: React.FC<VideoProps> = ({ className, poster, mediaURL, heading, text }) => {
	return (
		<Container width="Standard" className={classNames(styles.Video, className)}>
			<Grid full wrap>
				<GridCell>
					<div className={styles.Video_textbox}>
						<Heading headingLevel="h2" className={styles.Video_title}>
							{heading}
						</Heading>
						<RichText className={classNames(styles.Video_text, 'RichText', className)} content={text} />
					</div>
					<div className={styles.Video_media}>
						<span className={styles.Video_mediabox}>
							<Player url={mediaURL} poster={poster?.url && getFullImageUrl(poster.relativeUrl)} />
						</span>
					</div>
				</GridCell>
			</Grid>
		</Container>
	);
};
