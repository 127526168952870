import classNames from 'classnames';
import React from 'react';
import styles from './SocialShare.module.scss';
import { Paragraph, Icon, Link } from 'ui/components';
import { ReactComponent as IconFacebook } from 'ui/icons/icon-share-fb.svg';
import { ReactComponent as IconLinkedIn } from 'ui/icons/icon-share-linkedin.svg';
import { ReactComponent as IconTwitter } from 'ui/icons/icon-share-twitter.svg';
export interface SocialShareProps {
	className?: string;
	currentUrl: string;
	ogTitle: string;
}

export const SocialShare: React.FC<SocialShareProps> = ({ className, currentUrl, ogTitle }) => {
	const items = [
		{
			type: 'facebook',
			title: 'Facebook',
			url: `https://www.facebook.com/sharer.php?u=${currentUrl}`,
		},
		{
			type: 'twitter',
			title: 'Twitter',
			url: `https://twitter.com/share?url=${currentUrl}&text=${ogTitle}`,
		},
		{
			type: 'linkedin',
			title: 'LinkedIn',
			url: `https://www.linkedin.com/shareArticle?url=${currentUrl}&title=${ogTitle}`,
		},
	];

	const renderIcon = (type: string) => {
		switch (type) {
			case 'facebook':
				return <IconFacebook aria-hidden="true" />;
			case 'twitter':
				return <IconTwitter aria-hidden="true" />;
			case 'linkedin':
				return <IconLinkedIn aria-hidden="true" />;
		}
	};

	return (
		<div className={classNames(styles.SocialShare, className)}>
			<ul className={styles.SocialShare_container}>
				{items.map((item, index) => (
					<li key={index} className={styles.SocialShare_items}>
						<Link className={styles.SocialShare_links} ariaLabel={item.title} url={item.url} target="_blank">
							<Icon className={styles.SocialShare_icon} title={item.title}>
								{renderIcon(item.type)}
							</Icon>
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};
