import { Profiles } from 'application/repositories/profilesRepository';
import classNames from 'classnames';
import React from 'react';
import { Container, Grid, GridCell, Heading, Icon, Spinner } from 'ui/components';
import { ReactComponent as EmailIcon } from 'ui/icons/email-icon.svg';
import { ReactComponent as PatternEight } from 'ui/icons/icon-pattern-eight.svg';
import { ReactComponent as PhoneIcon } from 'ui/icons/phone-icon.svg';
import styles from './ListVisitCard.module.scss';

export interface ListVisitCardProps {
	className?: string;
	profiles: Profiles;
	heading?: string;
	notificationText?: string;
}

export const ListVisitCard: React.FC<ListVisitCardProps> = ({ className, heading, notificationText, profiles }) => {
	const hasHealthAndSafetyRepresentative = profiles?.healthAndSafetyRepresentative?.firstname ? true : false;
	const hasUnionRepresentative = profiles?.unionRepresentative?.firstname ? true : false;
	const showComponent = hasHealthAndSafetyRepresentative || hasUnionRepresentative;
	const showNotification = !hasHealthAndSafetyRepresentative || !hasUnionRepresentative;

	return (
		showComponent && (
			<section className={classNames(styles.ListVisitCard)}>
				<Icon className={styles.ListVisitCard_pattern}>
					<PatternEight />
				</Icon>
				<Container width="Standard" className={styles.ListVisitCard_container}>
					<Grid wrap>
						<GridCell>
							{heading && (
								<Heading className={styles.ListVisitCard_heading} headingLevel="h2">
									{heading}
								</Heading>
							)}
							{showNotification && <p className={styles.ListVisitCard_manchet}>{notificationText}</p>}
						</GridCell>
						<GridCell>
							<section className={styles.ListVisitCard_wrapper}>
								{hasUnionRepresentative && (
									<div className={classNames(styles.ListVisitCard_ContactCard, className)}>
										<section className={styles.ListVisitCard_details}>
											<article className={styles.ListVisitCard_header}>
												<Heading headingLevel="h3" style="default">
													{profiles?.unionRepresentative?.firstname} {profiles?.unionRepresentative?.lastname}
												</Heading>
												<p>{profiles?.unionRepresentative?.duty}</p>
											</article>

											<article className={styles.ListVisitCard_body}>
												<section className={styles.ListVisitCard_row}>
													<Icon size="md" aria-hidden={false} className={styles.ListVisitCard_icon}>
														<PhoneIcon viewBox="0 1 20 17" />
													</Icon>

													<a href={`tel:${profiles?.unionRepresentative?.workContactInformation?.phone}`} className={styles.ListVisitCard_link}>
														{profiles?.unionRepresentative?.privateContactInformation?.phone}
													</a>
												</section>
												<section className={styles.ListVisitCard_row}>
													<Icon size="md" aria-hidden={false} className={styles.ListVisitCard_icon}>
														<EmailIcon viewBox="0 -0.5 20 13" />
													</Icon>

													<a href={`mailto:${profiles?.unionRepresentative?.workContactInformation?.email}`} className={styles.ListVisitCard_link}>
														{profiles?.unionRepresentative?.privateContactInformation?.email}
													</a>
												</section>
											</article>
										</section>
									</div>
								)}
								{hasHealthAndSafetyRepresentative && (
									<div className={classNames(styles.ListVisitCard_ContactCard, className)}>
										<section className={styles.ListVisitCard_details}>
											<article className={styles.ListVisitCard_header}>
												<Heading headingLevel="h3" style="default">
													{profiles?.healthAndSafetyRepresentative?.firstname} {profiles?.healthAndSafetyRepresentative?.lastname}
												</Heading>
												<p>{profiles?.healthAndSafetyRepresentative?.duty}</p>
											</article>

											<article className={styles.ListVisitCard_body}>
												<section className={styles.ListVisitCard_row}>
													<Icon size="md" aria-hidden={false} className={styles.ListVisitCard_icon}>
														<PhoneIcon viewBox="0 1 20 17" />
													</Icon>

													<a
														href={`tel:${profiles?.healthAndSafetyRepresentative?.workContactInformation?.phone}`}
														className={styles.ListVisitCard_link}
													>
														{profiles?.healthAndSafetyRepresentative?.privateContactInformation?.phone}
													</a>
												</section>
												<section className={styles.ListVisitCard_row}>
													<Icon size="md" aria-hidden={false} className={styles.ListVisitCard_icon}>
														<EmailIcon viewBox="0 -0.5 20 13" />
													</Icon>

													<a
														href={`mailto:${profiles?.healthAndSafetyRepresentative?.workContactInformation?.email}`}
														className={styles.ListVisitCard_link}
													>
														{profiles?.healthAndSafetyRepresentative?.privateContactInformation?.email}
													</a>
												</section>
											</article>
										</section>
									</div>
								)}
							</section>
						</GridCell>
					</Grid>
				</Container>
			</section>
		)
	);
};
