import cx from 'classnames';
import { ComponentProps, forwardRef, useEffect } from 'react';
import styles from './RichText.module.scss';

type RichTextProps = ComponentProps<'div'> & {
	content: string;
};
export const RichText: React.FC<RichTextProps> = forwardRef(({ content, className }, ref) => {
	useEffect(() => {
		// wrapping RTE table inside an element to create scroll
		const rteTable = document.querySelectorAll('.RichText table');
		rteTable.forEach((el) => {
			const wrapper = document.createElement('aside');
			wrapper.style.overflowX = 'auto';
			// insert wrapper before el in the DOM tree
			el.parentNode.insertBefore(wrapper, el);
			// move el into wrapper
			wrapper.appendChild(el);
		});
	}, []);
	return <div ref={ref} className={cx(styles.RichText, className)} dangerouslySetInnerHTML={{ __html: content }} />;
});
RichText.displayName = 'RichText';
