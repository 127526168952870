import React from 'react';
import classNames from 'classnames';
import styles from './FileStackCard.module.scss';
import { Heading, Grid, GridCell, Link, Icon } from 'ui/components';
import { ReactComponent as IconDownload } from 'ui/icons/icon-download.svg';
import { capitalizeFirstLetter } from 'helpers/string';

export interface FileStackCardProps {
	className?: string;
	items?: Models.Link[];
}

export const FileStackCard: React.FC<FileStackCardProps> = ({ className, items }) => {
	if (!items) return null;
	const isEndOfArray = items?.length - 1;

	return (
		<div className={classNames(styles.FileStackCard_container)}>
			{items?.map((item, index) => (
				<React.Fragment key={index}>
					<Link
						url={item?.url}
						style="sm"
						className={classNames(styles.FileStackCard_link_container, {
							[styles.FileStackCard_container_separator]: index !== isEndOfArray,
						})}
						download={item?.name}
						ariaLabel={item?.name}
						target="_blank"
					>
						<Grid className={classNames(styles.FileStackCard, className)} center={true}>
							<GridCell mobileWidth="75" desktopWidth="" className={styles.FileStackCard_heading_container}>
								<Heading headingLevel="h4" style="default" className={classNames(styles.FileStackCard_heading)}>
									{capitalizeFirstLetter(item?.name)}
								</Heading>
							</GridCell>
							<GridCell mobileWidth="25" desktopWidth="" className={styles.FileStackCard_icon_container}>
								<Icon size="md" aria-hidden={false} className={styles.FileStackCard_link_icon}>
									<IconDownload />
								</Icon>
								<span className={styles.FileStackCard_link_text}>{item?.fileType?.toUpperCase()}</span>
							</GridCell>
						</Grid>
					</Link>
				</React.Fragment>
			))}
		</div>
	);
};
