const pad = (number: number) => (number < 10 ? `0${number}` : number);

const convertDate = (date) => new Date(date);

export const formatDate = (dateString: Date, format: string): string => {

	if (dateString == undefined)
	{
		return ""
	}
	let formattedDateString = format;

	const jsDate = convertDate(dateString);

	const dd = pad(jsDate.getDate()).toString();
	const mm = pad(jsDate.getMonth() + 1).toString();
	const yyyy = jsDate.getFullYear().toString();
	const yy = jsDate.getFullYear().toString().slice(2);

	formattedDateString = formattedDateString.replace('dd', dd);
	formattedDateString = formattedDateString.replace('mm', mm);
	formattedDateString = formattedDateString.replace('yyyy', yyyy);
	formattedDateString = formattedDateString.replace('yy', yy);

	return formattedDateString;
};

export const formatDatePlain = (dateString: Date, format: string): string => {
	let formattedDateString = format;

	const jsDate = convertDate(dateString);

	const dd = pad(jsDate.getDate()).toString();
	const mm = pad(jsDate.getMonth() + 1).toString();
	const yyyy = jsDate.getFullYear().toString();

	formattedDateString = formattedDateString.replace('dd', dd);
	formattedDateString = formattedDateString.replace('mm', mm);
	formattedDateString = formattedDateString.replace('yyyy', yyyy);

	return formattedDateString;
};

export const formatTime = (dateString: Date, format: string): string => {
	let formattedTimeString = format;

	const jsDate = convertDate(dateString);

	const hh = pad(jsDate.getHours()).toString();
	const mm = pad(jsDate.getMinutes()).toString();

	formattedTimeString = formattedTimeString.replace('hh', hh);
	formattedTimeString = formattedTimeString.replace('mm', mm);

	return formattedTimeString;
};
