import { getAudiencesAdapter,AudiencesResult } from 'application/adapters/CDP/CDPAdapter';

export type Audiences = {
    audiences: 
        {
            name : string,
            id : string
        }[]
    
};
export const getAudiences = async (hostname: string,memberId:string ): Promise<AudiencesResult> => {
	const audiences = getAudiencesAdapter(hostname,memberId)
	return audiences;
};
