import classNames from 'classnames';
import { Container, Grid, GridCell, LinkButton, Picture, Icon } from 'ui/components';
import styles from './Quote.module.scss';
import { ReactComponent as PatternFive } from 'ui/icons/icon-pattern-five.svg';

export interface QuoteProps {
	className?: string;
	text?: string;
	cite?: string;
	quoteLink?: Umbraco.Link;
	image: Umbraco.Image;
}

export const Quote: React.FC<QuoteProps> = ({ className, text, cite, image, quoteLink }) => {
	return (
		<section className={classNames(styles.Quote, className)}>
			<Icon className={styles.Quote_pattern}>
				<PatternFive />
			</Icon>
			<Container width="Standard">
				<Grid wrap>
					<GridCell>
						<figure className={styles.Quote_figure}>
							{image && (
								<span className={styles.Quote_mediabox}>
									<Picture
										relativeUrl={image?.relativeUrl}
										properties={image.properties}
										aspectRatio={1}
										focalPoint={image.focalPoint}
										sizes="20vw"
									/>
								</span>
							)}
							<blockquote className={styles.Quote_blockquote}>{text}</blockquote>
							<figcaption className={styles.Quote_figcaption}>
								<cite className={styles.Quote_cite}>{cite}</cite>
							</figcaption>
							{quoteLink && (
								<LinkButton className={styles.Quote_link} url={quoteLink.url} style="secondary" target="blank">
									{quoteLink.name}
								</LinkButton>
							)}
						</figure>
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
