import { useEffect } from 'react';
import styles from './IframeViscom.module.scss';

export interface IframeViscomProps {
	className?: string;
	iframeViscomSource?: string;
	bodyScript?: string;
}

export const IframeViscom: React.FC<IframeViscomProps> = ({ className, iframeViscomSource }) => {
	useEffect(() => {
		// Auto-height and scroll
		window.addEventListener('message', function (event) {
			if (event.data.event_id === 'vas-height') {
				document.getElementById('iframe').style.height = event.data.data.height + 50 + 'px';
			}
			if (event.data.event_id === 'vas-scroll') {
				const target = document.getElementById(event.data.data.scroll);
				const offset = event.data.data.offset;

				window.scrollTo({
					top: target.offsetTop + offset,
					left: 0,
					behavior: 'smooth',
				});
			}
			console.log(event.data.data);
		});
	}, []);

	return (
		<>
			<iframe id="iframe" className={styles.IframeViscom} src={iframeViscomSource} />
		</>
	);
};
