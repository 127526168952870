import classNames from 'classnames';

import styles from './Container.module.scss';

export type ContainerWidth = 'Small' | 'Standard' | 'Full width' | '';

export interface ContainerProps {
	className?: string;
	width: ContainerWidth;
	children: React.ReactNode;
	ariaExpanded?: boolean;
}

export const Container: React.FC<ContainerProps> = ({ width = 'Standard', children, className, ariaExpanded }) => {
	width = width === '' ? 'Standard' : width;

	return (
		<div
			className={classNames(
				styles.Container,
				{ [styles.Container___narrow]: width === 'Small' },
				{ [styles.Container___standard]: width === 'Standard' },
				{ [styles.Container]: width === 'Full width' },
				className,
			)}
			aria-expanded={ariaExpanded}
		>
			{children}
		</div>
	);
};
