import classNames from 'classnames';
import styles from './NewsThumbnail.module.scss';
import { useState, useEffect, useRef } from 'react';
import { Container, Grid, GridCell, ListCampaign, Filter, Icon, FormfieldSelect, Pagination } from 'ui/components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactComponent as PatternFive } from 'ui/icons/icon-pattern-five.svg';
import { formatDate, formatTime } from 'helpers/date';

/*
 *
 * This component is only used on kongres.def.dk
 *
 */

export interface NewsThumbnailProps {
	className?: string;
	rootFolder?: Umbraco.Page;
	count?: number;
	offset?: number;
	kicker?: string;
	link?: Umbraco.Link;
	newsFetcher: (rootId: number, limit: number, offset: number) => Models.NewsCard[];
	primaryRadio?: string;
	secondaryRadio?: string;
}

export const NewsThumbnail: React.FC<NewsThumbnailProps> = ({ className, rootFolder, count, offset, link, newsFetcher }) => {
	const rootId = rootFolder ? rootFolder.id : 0;
	const limitQuery = count === 0 ? null : count;
	const offsetQuery = offset >= 0 ? offset : null;
	const NewsThumbnail = newsFetcher(rootId, limitQuery, offsetQuery);
	const NewsStringComparision = 'Nyhed';
	const PressStringComparision = 'Presse';
	let listValue = [];

	// Filter All the elements taged with "News"
	const newsArray = NewsThumbnail.filter(function (el) {
		return el.kicker === NewsStringComparision;
	});

	// Filter All the elements taged with "Press"
	const pressArray = NewsThumbnail.filter(function (el) {
		return el.kicker === PressStringComparision;
	});

	const [item, setItem] = useState(NewsStringComparision);
	const toggleChange = (event) => {
		setItem(event.target.value);
		setCurrentPage(1);
	};

	// Filter the list based on the toggleChange selection
	if (item === NewsStringComparision) {
		listValue = newsArray;
	} else {
		listValue = pressArray;
	}

	const [sessionItem, setSessionItem] = useState(newsArray);
	const sortChange = (event) => {
		setSessionItem(event.target.value);
		setCurrentPage(1);
	};

	// Filter the list based on the sortChange selection
	let newsSessionDays = listValue.filter(function (el) {
		return el.session === sessionItem;
	});

	// If none of the selection is chosen from dropdown or "Press" toggle is clicked
	if (sessionItem.length <= 0 || item === PressStringComparision) {
		newsSessionDays = listValue;
	}

	useEffect(() => {
		// newsSessionDays = pressArray;
	});

	// Pagination
	const pageSize = 9; // Number of items to be shown before the Pagination kicks in
	const [currentPage, setCurrentPage] = useState(1);

	newsSessionDays.map((obj) => {

		return { ...obj, date: new Date(obj.date) };
	});

	// The value of "sortedNews" is used to load the page with the news allready sortet based on "Date" or "UpdatedNewsDate"
	const sortedNews = newsSessionDays.sort((objA, objB) => Number(objB.date) - Number(objA.date));

	const sortedNewsPaginated = sortedNews.slice((currentPage - 1) * pageSize, currentPage * pageSize);
	// Reversing the array in which the pagination returns
	const sortedResult = Object.assign([], sortedNewsPaginated).reverse();

	if (!NewsThumbnail) return <></>;

	return (
		<Container width="Standard" className={classNames(styles.NewsThumbnail, className)}>
			<Grid full wrap>
				<Icon className={styles.NewsThumbnail_pattern}>
					<PatternFive />
				</Icon>
				<GridCell>
					<Filter toggleChange={toggleChange} sortChange={sortChange} />
					<span className={styles.NewsThumbnail_hits}>({newsSessionDays.length} resultater)</span>
				</GridCell>
				<article className={styles.NewsThumbnail_newsLIst}>
					<ListCampaign items={sortedResult} desktopWidth="100" mobileWidth="100"></ListCampaign>
				</article>
			</Grid>
			{newsSessionDays.length > pageSize ? (
				<Pagination
					numItems={sortedNews.length}
					pageSize={pageSize}
					currentPage={currentPage}
					firstClickHandler={() => setCurrentPage(1)}
					prevClickHandler={() => setCurrentPage((page) => page - 1)}
					nextClickHandler={() => setCurrentPage((page) => page + 1)}
					theme="dark"
				/>
			) : null}
		</Container>
	);
};
