import classNames from 'classnames';
import styles from './ListCampaign.module.scss';
import { NewsCard } from 'ui/components';

export interface ListCampaignProps {
	className?: string;
	items: Models.NewsCard[];
	desktopWidth?: Models.GridCellWidth;
	mobileWidth?: Models.GridCellWidth;
}

export const ListCampaign: React.FC<ListCampaignProps> = ({ className, items }) => {
	if (!items) return null;

	return (
		<>
			{items.reverse().map((args, index) => (
				<NewsCard key={index} {...args} />
			))}
		</>
	);
};
