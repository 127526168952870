import { Profiles } from 'application/repositories/profilesRepository';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Container, GridCell, Heading, Icon, Spinner } from 'ui/components';
import { formatDate } from 'helpers/date';
import { ReactComponent as IconEmail } from 'ui/icons/email-icon.svg';
import { ReactComponent as IconPersonCircle } from 'ui/icons/icon-person-circle.svg';
import { ReactComponent as IconPlugCircle } from 'ui/icons/icon-plug-circle.svg';
import { ReactComponent as IconWorkCircle } from 'ui/icons/icon-work-circle.svg';
import { ReactComponent as Minus } from 'ui/icons/icon-minus.svg';
import { ReactComponent as Plus } from 'ui/icons/icon-plus.svg';
import { ReactComponent as IconPhone } from 'ui/icons/phone-icon.svg';
import styles from './Profile.module.scss';
export interface ProfileProps {
	className?: string;
	pageHeading?: string;
	profiles: Profiles;
	memberNumberTitle?: string;
	personalSubHeading?: string;
	personalNameTitle?: string;
	personalAdressTitle?: string;
	personalZipCodeTitle?: string;
	personalCityTitle?: string;
	personalContactPrivateTitle?: string;
	personalContactWorkTitle?: string;
	personalContactWorkSecondaryValue?: string;
	workSubHeading?: string;
	workEmployerTitle?: string;
	workPositionTitle?: string;
	relationSubHeading?: string;
	relationMemberDateTitle?: string;
	relationdepartmentTitle?: string;
	relationAreaTitle?: string;
}

export const Profile: React.FC<ProfileProps> = ({
	className,
	profiles,
	pageHeading,
	memberNumberTitle,
	personalSubHeading,
	personalNameTitle,
	personalAdressTitle,
	personalZipCodeTitle,
	personalCityTitle,
	personalContactPrivateTitle,
	personalContactWorkTitle,
	workSubHeading,
	workEmployerTitle,
	workPositionTitle,
	relationSubHeading,
	relationMemberDateTitle,
	relationdepartmentTitle,
	relationAreaTitle,
}) => {
	const [dutiesOpen, setDutiesOpen] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const toggleShowMore = () => {
		setShowMore((prevValue) => !prevValue);
		setDutiesOpen(!dutiesOpen);
	};
	return (
		<>
			{!profiles ? (
				<Spinner />
			) : (
				<Container className={classNames(styles.Profile, className)} width="Full width">
					<GridCell>
						<section className={styles.Profile_top}>
							<Heading headingLevel="h1" className={styles.Profile_heading}>
								{pageHeading}
							</Heading>
							<section className={styles.Profile_memberNumber}>
								<span className={styles.Profile_memberNumberTitle}>{memberNumberTitle}</span>
								<span className={styles.Profile_memberNumberValue}>{profiles?.membership?.id}</span>
							</section>
						</section>

						<article className={styles.Profile_categories}>
							<Heading headingLevel="h2" className={styles.Profile_subHeading}>
								<Icon className={styles.Profile_iconCategory}>
									<IconPersonCircle />
								</Icon>
								<span>{personalSubHeading}</span>
							</Heading>
							<section className={styles.Profile_columnsWrapper}>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{personalNameTitle}</span>
										<span className={styles.Profile_dataValue}>
											{profiles?.firstName} {profiles?.lastName}
										</span>
									</section>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{personalAdressTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.address?.line1}</span>
										{profiles?.address?.line2 && <span className={styles.Profile_dataValue}>{profiles?.address?.line2}</span>}
									</section>
									<section className={classNames(styles.Profile_section, styles.Profile_section___location)}>
										<span className={styles.Profile_dataTitle}>{personalZipCodeTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.address?.postNumber}</span>
									</section>
									<section className={classNames(styles.Profile_section, styles.Profile_section___location)}>
										<span className={styles.Profile_dataTitle}>{personalCityTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.address?.city}</span>
									</section>
								</div>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={classNames(styles.Profile_dataTitle, styles.Profile_dataTitle___kontakt)}>
											{personalContactPrivateTitle}
										</span>

										<div className={classNames(styles.Profile_dataValue, styles.Profile_dataValue___kontakt)}>
											<Icon className={styles.Profile_iconPhone}>
												<IconPhone />
											</Icon>
											<span>{profiles?.privateContact?.phone}</span>
										</div>
										<div className={classNames(styles.Profile_dataValue, styles.Profile_dataValue___kontakt)}>
											<Icon className={styles.Profile_iconEmail}>
												<IconEmail />
											</Icon>
											<span>{profiles?.privateContact?.email}</span>
										</div>
									</section>
								</div>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={classNames(styles.Profile_dataTitle, styles.Profile_dataTitle___kontakt)}>
											{personalContactWorkTitle}
										</span>
										<div className={classNames(styles.Profile_dataValue, styles.Profile_dataValue___kontakt)}>
											<Icon className={styles.Profile_iconPhone}>
												<IconPhone />
											</Icon>
											<span>{profiles?.workContact?.phone}</span>
										</div>
										<div className={classNames(styles.Profile_dataValue, styles.Profile_dataValue___kontakt)}>
											<Icon className={styles.Profile_iconEmail}>
												<IconEmail />
											</Icon>
											<span>{profiles?.workContact?.email}</span>
										</div>
									</section>
								</div>
							</section>
						</article>

						<article className={styles.Profile_categories}>
							<Heading headingLevel="h2" className={styles.Profile_subHeading}>
								<Icon className={styles.Profile_iconCategory}>
									<IconWorkCircle />
								</Icon>
								<span>{workSubHeading}</span>
							</Heading>
							<section className={styles.Profile_columnsWrapper}>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{workEmployerTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.employment?.employer?.name}</span>
									</section>
								</div>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{workPositionTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.employment?.jobTitle}</span>
									</section>
								</div>
							</section>
						</article>

						<article className={styles.Profile_categories}>
							<Heading headingLevel="h2" className={styles.Profile_subHeading}>
								<Icon className={styles.Profile_iconCategory}>
									<IconPlugCircle />
								</Icon>
								<span>{relationSubHeading}</span>
							</Heading>
							<section className={styles.Profile_columnsWrapper}>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{relationMemberDateTitle}</span>
										<span className={styles.Profile_dataValue}>
											{formatDate(new Date(profiles?.membership?.startDate), 'dd.mm.yy')}
										</span>
									</section>
								</div>
								<div className={styles.Profile_columns}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{relationdepartmentTitle}</span>
										<span className={styles.Profile_dataValue}>{profiles?.branch?.name}</span>
									</section>
								</div>
								<div className={styles.Profile_columns} aria-expanded={dutiesOpen}>
									<section className={styles.Profile_section}>
										<span className={styles.Profile_dataTitle}>{relationAreaTitle}</span>

										{profiles?.duties.map((args, index) => {
											return (
												<p key={index} className={styles.Profile_duties}>
													{args}
												</p>
											);
										})}

										{profiles?.duties.length > 2 && (
											<aside className={styles.Profile_linkWrapper} onClick={toggleShowMore}>
												<button className={styles.Profile_link}>
													{!showMore && (
														<>
															<span>Vis mere</span>
															<Icon className={styles.Profile_linkIconPlus}>
																<Plus />
															</Icon>
														</>
													)}
													{showMore && (
														<>
															<span>Vis mindre</span>
															<Icon className={styles.Profile_linkIconMinus}>
																<Minus />
															</Icon>
														</>
													)}
												</button>
											</aside>
										)}
									</section>
								</div>
							</section>
						</article>
					</GridCell>
				</Container>
			)}
		</>
	);
};
