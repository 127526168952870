import classNames from 'classnames';
import styles from './SoMeLink.module.scss';
import { Icon, Link, Paragraph } from 'ui/components';
import { ReactComponent as FacebookIcon } from 'ui/icons/facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from 'ui/icons/linkedin-icon.svg';
import { ReactComponent as TwitterIcon } from 'ui/icons/twitter-icon.svg';

export interface SoMeLinkProps {
	className?: string;
	platform: 'facebook' | 'linkedin' | 'twitter';
	title?: string;
	url?: string;
	size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
	style?: 'primary' | 'secondary';
}

export const SoMeLink: React.FC<SoMeLinkProps> = ({ className, platform="twitter", title, url="#", size = 'lg', style }) => (
	<Link className={classNames(styles.SoMeLink, styles.Navigation_link, styles[`SoMeLink___${style}`], className)} url={url}>
		<Icon className={styles.SoMeLink_icon} size={size}>
			{platform.toLowerCase() === 'facebook' 
			? <FacebookIcon />
			: platform.toLowerCase() === 'linkedin' 
			? <LinkedinIcon />
			: <TwitterIcon />}
		</Icon>
		{title && <Paragraph className={classNames(styles.SoMeLink_text, styles[`SoMeLink_text___${size}`])} children={title}/>}
	</Link>
);
