import { ComponentProps } from 'react';
import classNames from 'classnames';
import styles from './AgreementCard.module.scss';
import { Container, FileStackCard, AgreementLinkCard } from 'ui/components';

export interface AgreementCardProps {
	className?: string;
	agreementFiles: {
		items: ComponentProps<typeof FileStackCard>['items'];
	};
	agreementLink: {
		link: ComponentProps<typeof AgreementLinkCard>['link'];
	};
}

export const AgreementCard: React.FC<AgreementCardProps> = ({ className, agreementFiles, agreementLink }) => {
	return (
		<Container width="Standard" className={classNames(styles.AgreementCard_container, className)}>
			<AgreementLinkCard link={agreementLink?.link} isActionLink={true} />
			<FileStackCard items={agreementFiles?.items} />
		</Container>
	);
};
