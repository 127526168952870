import classNames from 'classnames';

export interface GridCellProps extends Models.GridCell {
	className?: string;
	center?: boolean;
	mobileWidth?: Models.GridCellWidth;
	desktopWidth?: Models.GridCellWidth;
	style?: React.CSSProperties;
}

export const GridCell: React.FC<GridCellProps> = ({ className, mobileWidth = '100', desktopWidth = '100', children, center }) => (
	<div
		className={classNames(
			'u-grid__cell',
			'u-grid__cell--width-100',
			{ [`u-grid__cell--width-${mobileWidth}@xs`]: mobileWidth },
			{ [`u-grid__cell--width-${desktopWidth}@md`]: desktopWidth },
			{ 'u-grid__cell--center': center },
			className,
		)}
	>
		{children}
	</div>
);
