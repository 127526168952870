import classNames from 'classnames';
import styles from './AccordionCalendar.module.scss';
import React, { useState } from 'react';
import { AccordionCalendarItem } from './AccordionCalendarItem';

export interface AccordionCalendarProps {
	className?: string;
	accordionItems?: {
		title: string;
		tabSubTitle: string;
		items: Models.AccordionItem[];
	}[];
}

export const AccordionCalendar: React.FC<AccordionCalendarProps> = ({ className, accordionItems }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const activeTab = (itemNumber) => {
		setCurrentTab(itemNumber);
	};

	return (
		<div className={classNames(styles.AccordionCalendar, className)}>
			{accordionItems.map((accordionItem, index) => {
				return (
					<AccordionCalendarItem {...accordionItem} itemNumber={index} key={index} isActive={currentTab === index} activeTab={activeTab} />
				);
			})}
		</div>
	);
};
