import { NextApiRequest } from 'next';

/**
 * While this function is called getHostname, it really returns the origin of the request with a trailing slash instead.
 * It is called getHostname, as it is the terminology used in the Umbraco implementation; specifically the Umbraco backend requires a `hostname` request header with origin + "/" as the value.
 *
 * Origin/Hostname distinction can be found here:
 * - https://developer.mozilla.org/en-US/docs/Web/API/Location/origin
 * - https://developer.mozilla.org/en-US/docs/Web/API/Location/hostname
 */
export const getCMSHostname = (req?: NextApiRequest): string => {
	const origin = window ? window.location.origin : req?.headers?.origin;
	return origin + (origin.endsWith('/') ? '' : '/'); // Umbraco requires a trailing slash
};
