import classNames from 'classnames';
import { Container, Grid, GridCell, ShortcutCard, ShortcutCardProps } from 'ui/components';
import styles from './ListShortcut.module.scss';

export interface ListShortcutProps {
	className?: string;
	items: ShortcutCardProps[];
}

export const ListShortcut: React.FC<ListShortcutProps> = ({ className, items }) => {
	return (
		<section className={classNames(styles.ListShortcut, className)}>
			<Container width="Standard">
				<Grid full wrap>
					<GridCell className={styles.ListShortcut_gridcell}>
						{items.map((args, index) => (
							<ShortcutCard key={index} {...args} className={styles.ListShortcut_items} />
						))}
					</GridCell>
				</Grid>
			</Container>
		</section>
	);
};
