import type { AppProps as NextAppProps } from 'next/app';
import Script from 'next/script';
import { Preview } from 'ui/components/4-habitats/Preview';
import { Provider } from 'react-redux';
import { PersistGateSSR } from 'application/adapters/context/PersistGateSSR';
import { store } from '../application/adapters/store/store';
import { persistStore } from 'redux-persist';
import { SiteContext, Site } from 'application/adapters/context/SiteContext';
import { DictionaryContext, Dictionary } from 'application/adapters/context/Dictionary';
import { PageContext, Page as PageContent } from 'application/adapters/context/PageContext';

import 'ui/styles/globals.scss';
import { SessionProvider } from 'next-auth/react';
import { AppStateProvider } from 'application/adapters/appState/appState';
import { ProfileProvider } from 'application/adapters/context/Profile';

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	const { host, content, session } = pageProps ?? {};
	const { dictionary, page, root } = content ?? {};
	const { culture } = page ?? {};
	const { siteSettings } = root?.properties ?? {};

	const persistor = persistStore(store);

	const dictionaryProvider = new Dictionary(dictionary, culture);
	const site = new Site(root, host, culture, siteSettings);
	const pageContent: PageContent = new PageContent(content);

	return (
		<SessionProvider session={session} refetchInterval={2 * 60}>
			<Provider store={store}>
				<PersistGateSSR loading={null} persistor={persistor}>
					<SiteContext.Provider value={site}>
						<DictionaryContext.Provider value={dictionaryProvider}>
							<PageContext.Provider value={pageContent}>
								<Preview content={content}>
									<AppStateProvider>
										<ProfileProvider>
											<Component {...content} />
											<Script src="/js/cludo.js" strategy="afterInteractive" />
											<Script src="https://customer.cludo.com/scripts/bundles/search-script.js" strategy="afterInteractive" />
											<Script src="https://customer.cludo.com/assets/3101/13264/cludo-helper2024.js" strategy="lazyOnload" />
										</ProfileProvider>
									</AppStateProvider>
								</Preview>
							</PageContext.Provider>
						</DictionaryContext.Provider>
					</SiteContext.Provider>
				</PersistGateSSR>
			</Provider>
		</SessionProvider>
	);
};

export default App;
