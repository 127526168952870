import classNames from 'classnames';
import React from 'react';
import { ContactCard, Container, Heading } from 'ui/components';
import styles from './ResponsibleContact.module.scss';

export interface ResponsibleContactProps {
	className?: string;
	headline: string;
	hideContactInfo: boolean;
	cards: Array<{
		name: string;
		jobTitle: string;
		branch: string;
		phoneNumbers: string[];
		email: string;
		categories: string[];
		picture: Umbraco.Image | null;
	}>;
}

export const ResponsibleContact: React.FC<ResponsibleContactProps> = ({ className, headline, hideContactInfo, cards }) => {
	return (
		<Container width="Standard" className={classNames(styles.ResponsibleContact)}>
			<Heading className={styles.ResponsibleContact_heading} headingLevel="h2">
				{headline}
			</Heading>

			<div className={classNames(styles.ResponsibleContact_list)}>
				{cards?.map((contact, index) => (
					<ContactCard key={index} {...contact} hideContactInfo={hideContactInfo} />
				))}
			</div>
		</Container>
	);
};
