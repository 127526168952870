import React from 'react';

export const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean;
	wrapper: (children: React.ReactElement) => React.ReactElement;
	children: React.ReactElement;
}): React.ReactElement => (condition ? wrapper(children) : children);
