import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import styles from './Select.module.scss';
import { ReactComponent as ChevronDownIcon } from 'ui/icons/icon-chevron-down.svg';

type SelectProps = ComponentProps<'select'> & {
	options: Array<{
		value: string; // Value limited to string as React.ChangeEvent<HTMLSelectElement>'s target.value prop will always return type string.
		label: string;
	}>;
	selectRef?: React.Ref<HTMLSelectElement>;
};
export const Select: React.VFC<SelectProps> = ({ options, selectRef, className, ...restProps }) => {
	return (
		<div className={styles.container}>
			<select ref={selectRef} className={classNames(styles.select, className)} {...restProps}>
				{options.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			<ChevronDownIcon className={styles.icon} />
		</div>
	);
};
