import classNames from 'classnames';
import { RichText } from 'ui/components';
import styles from './FooterCard.module.scss';

export interface FooterCardProps {
	className?: string;
	text?: string;
	linkUrl?: string;
	email?: string;
}

export const FooterCard: React.FC<FooterCardProps> = ({ className, text, email, linkUrl }) => (
	<div className={classNames(styles.FooterCard, className)}>
		<a className={styles.FooterCard_link} href={linkUrl}>
			<div className={styles.FooterCard_textbox}>
				<RichText className={classNames(styles.FooterCard_text, 'RichText RichText___negative', className)} content={text} />
				{email && <p className={classNames(styles.FooterCard_text, styles.FooterCard_text___email)}>{email}</p>}
			</div>
		</a>
	</div>
);
