import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { Form, Spinner,Container } from 'ui/components';

import {netEasyCheckoutKey,netsEastCheckoutUrl} from 'features/Modules/Checkout/constants'
//import styles from './Contact.module.scss';

export interface CheckoutProps {
	className?: string;
	novalidPaymentRedirect?:Umbraco.Link;
	paymentCompletedPage?:Umbraco.Link;
}


export const Checkout: React.FC<CheckoutProps> = ({ novalidPaymentRedirect,paymentCompletedPage}) => {
	return (
		<>
			{
				<Container width="Standard" data-checkout-key="dsadsa">
               
			   <div id="parentDiv">
					<input type="hidden" id="checkoutkey" value={netEasyCheckoutKey}/>
					<input type="hidden" id="invalidredirect" value={novalidPaymentRedirect?.url}/>
					<input type="hidden" id="receipturl" value={paymentCompletedPage?.url}/>
			   </div>
				<div id="checkout-container-div">

				<script src={netsEastCheckoutUrl}></script>
      			<script src="/js/checkoutIframe.js"></script>
				</div>
				</Container>
            }
		</>
	);
};
