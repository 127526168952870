import classNames from 'classnames';
import styles from './FormfieldCheckbox.module.scss';
import { Label } from 'ui/components';

export interface FormFieldCheckboxProps {
	className?: string;
	defaultChecked?: boolean;
	defaultValue?: string;
	id: string;
	label: string;
	multiline?: boolean;
	name?: string;
	fieldGroup?: boolean;
	register?: any;
	onChange?: (e: any) => void;
	ref?: any;
	checked?: boolean;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
	};
}

export const FormFieldCheckbox: React.FC<FormFieldCheckboxProps> = ({
	className,
	fieldGroup,
	register,
	state,
	id,
	label,
	multiline,
	name,
	defaultChecked,
	defaultValue,
	ref,
	checked,
	onChange,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	return (
		<div
			className={classNames(
				styles.FormFieldCheckbox,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				{ [styles.FormFieldCheckbox___multiline]: multiline === true },
			)}
		>
			<input
				type="checkbox"
				className={classNames(styles.FormFieldCheckbox_input, styles.Input___choice, {
					[styles.hasError]: hasError,
				})}
				name={name}
				id={id}
				defaultChecked={defaultChecked}
				disabled={disabled}
				defaultValue={defaultValue}
				onChange={onChange}
				ref={ref}
				value={label}
				checked={checked}
				{...(register && { ...register(name, { required }) })}
			/>
			<Label id={id} className={styles.FormFieldCheckbox_label} state={labelState}>
				{label}{' '}
			</Label>
		</div>
	);
};

