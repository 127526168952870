import { AppStateContext } from 'application/adapters/appState/appState';
import classNames from 'classnames';
import { parallax } from 'helpers/parallax';
import React, { useContext, useEffect } from 'react';
import { Button, Container, Grid, GridCell, Heading, Icon, Link, LinkButton, Paragraph, Picture } from 'ui/components';
import { ReactComponent as IconKey } from 'ui/icons/icon-key.svg';
import styles from './Register.module.scss';

export interface RegisterProps {
	className?: string;
	registerHeading?: string;
	loginHeading?: string;
	image?: Umbraco.Image;
	registerText?: string;
	loginText?: string;
	registerPrimaryLink?: Umbraco.Link;
	registerSecondaryLink?: Umbraco.Link;
	loginPrimaryLinkText?: string;
	registerbottomLinkText?: string;
	bottomLink?: Umbraco.Link;
	style?: 'min';
	overlayAcceptText?: string;
}

export const Register: React.FC<RegisterProps> = ({
	className,
	registerHeading,
	loginHeading,
	image,
	registerText,
	loginText,
	registerPrimaryLink,
	registerSecondaryLink,
	loginPrimaryLinkText,
	registerbottomLinkText,
	bottomLink,
}) => {
	const { toggleShowLoginModal } = useContext(AppStateContext);

	useEffect(() => {
		parallax();
	}, []);

	return (
		<section className={classNames(styles.Register, className)}>
			<article className={styles.Register_primary}>
				<Container width="Standard" className={classNames(styles.Register_wrapper, styles.Register_wrapper___primary)}>
					<Grid wrap className={styles.Register_content}>
						<GridCell desktopWidth="100" className={styles.Register_contentWrapper}>
							<Heading headingLevel="h2" className={styles.Register_heading}>
								{registerHeading}
							</Heading>
							<Paragraph className={styles.Register_text}>{registerText}</Paragraph>
							<aside className={classNames(styles.Register_cta, styles.Register_cta___primary)}>
								{registerPrimaryLink && (
									<LinkButton className={styles.Register_linkPrimary} url={registerPrimaryLink.url} style="primary">
										{registerPrimaryLink.name}
									</LinkButton>
								)}
								{registerSecondaryLink && (
									<LinkButton className={styles.Register_linkSecondary} url={registerSecondaryLink.url} style="primary">
										{registerSecondaryLink.name}
									</LinkButton>
								)}
							</aside>
							<aside className={styles.Register_bottomLinkWrapper}>
								<span className={styles.Register_bottomLinkText}>{registerbottomLinkText}</span>
								<Link target={bottomLink?.target} url={`tel:${bottomLink?.url}`} className={styles.Register_bottomLink} ariaLabel={bottomLink?.name}>
									{bottomLink?.name}
								</Link>
							</aside>
						</GridCell>
					</Grid>
				</Container>
				{image && (
					<div className={styles.Register_mediabox_wrapper} data-parallax>
						<Picture
							relativeUrl={image?.relativeUrl}
							properties={image.properties}
							focalPoint={image.focalPoint}
							aspectRatio={0.79}
							sizes="(min-width: 960px) 50vw, 100vw"
							className={styles.Register_mediabox}
						/>
					</div>
				)}
			</article>
			<article className={styles.Register_secondary}>
				<Container width="Standard" className={classNames(styles.Register_wrapper, styles.Register_wrapper___secondary)}>
					<Grid wrap className={styles.Register_content}>
						<GridCell desktopWidth="100" className={styles.Register_contentWrapper}>
							<Heading headingLevel="h2" className={styles.Register_heading}>
								<Icon className={styles.Register_icon}>
									<IconKey />
								</Icon>
								{loginHeading}
							</Heading>
							<Paragraph className={styles.Register_text}>{loginText}</Paragraph>
							<aside className={styles.Register_cta}>
								{loginPrimaryLinkText && (
									<Button className={styles.Register_link} onClick={toggleShowLoginModal} style="primary">
										{loginPrimaryLinkText}
									</Button>
								)}
							</aside>
						</GridCell>
					</Grid>
				</Container>
			</article>
		</section>
	);
};
