import styles from './DownloadBar.module.scss';
import { LinkButton, Paragraph, Icon } from 'ui/components';
import { ReactComponent as Download } from 'ui/icons/icon-download.svg';

export interface DownloadBarProps {
	downloadBarLabel?: string;
	downloadText?: string;
	url?: string;
	target?: string;
}
export const DownloadBar: React.FC<DownloadBarProps> = ({ downloadBarLabel, downloadText, url, target }) => {
	return (
		<section className={styles.DownloadBar}>
			<div className={styles.DownloadBar_wrapper}>
				<Paragraph className={styles.DownloadBar_text}>{downloadBarLabel}</Paragraph>
				<LinkButton className={styles.DownloadBar_link} url={url} style="primary" target="_blank" download>
					{downloadText}
					<Icon className={styles.DownloadBar_icon} size="md">
						<Download />
					</Icon>
				</LinkButton>
			</div>
		</section>
	);
};
