import classNames from 'classnames';
import { GridCell, Picture } from 'ui/components';
import styles from './CtaCard.module.scss';

export interface CtaCardProps {
	className?: string;
	ctaText: string;
	cellWidths?: {
		mobileWidth?: Models.GridCellWidth;
		desktopWidth?: Models.GridCellWidth;
	};
	image: Umbraco.Image;
	imageSizes?: string;
	link: Umbraco.Link;
}

export const CtaCard: React.FC<CtaCardProps> = ({ className, ctaText, cellWidths, image, imageSizes, link }) => {
	return (
		<GridCell mobileWidth={cellWidths?.mobileWidth} desktopWidth={cellWidths?.desktopWidth}>
			<a className={classNames(styles.CtaCard, className)} href={link?.url} aria-label={link?.name}>
				<div className={styles.CtaCard_image}>
					<Picture sizes={imageSizes} {...image} aspectRatio={0.67} className={styles.CtaCard_imageElement} />
				</div>
				<p className={styles.CtaCard_text}>{ctaText}</p>
			</a>
		</GridCell>
	);
};
