import classNames from 'classnames';
import React, { useState } from 'react';
import { ContactCard, Spinner, FilterContacts, Container, FilterWrapper, Heading } from 'ui/components';
import useLocalStorage from 'helpers/usehooks-ts/useLocalStorage';
import styles from './ContactList.module.scss';
import { unique } from 'faker';

export interface ContactListProps {
	className?: string;
	headline: string;
	filterText: string;
	filterLabel: string;
	branch: {
		id: number;
		culture: string;
		name: string;
		url: string;
		documentType: string;
		template: string;
		createDate: string;
		sortOrder: number;
		properties: null;
	};
	disableFilter: boolean;
	contacts: Array<{
		id: number;
		name: string;
		jobTitle: string;
		branch: string;
		phoneNumbers: string[];
		email: string;
		categories: string[];
		picture: Umbraco.Image | null;
	}>;
}

export const ContactList: React.FC<ContactListProps> = ({ className, headline, filterText, disableFilter, contacts }) => {
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

	// Find all unique categories in contacts
	const uniqueCategories = contacts
		? contacts?.reduce((acc, contact) => {
				contact?.categories?.forEach((category) => {
					if (!acc.includes(category)) {
						acc.push(category);
					}
				});
				return acc;
		  }, [])
		: [];

	const filteredContacts = contacts
		? contacts?.filter((contact) => {
				if (selectedCategories.length === 0) return true;
				for (let i = 0; i < selectedCategories.length; i++) {
					if (contact.categories.includes(selectedCategories[i])) return true;
				}

				return false;
		  })
		: [];

	const handleChangeCategory = (e) => {
		if (e.target.checked) {
			setSelectedCategories((prevSelectedCategories) => [...prevSelectedCategories, e?.target?.value]);
		} else {
			setSelectedCategories((prevSelectedCategories) => prevSelectedCategories.filter((category) => category !== e?.target?.value));
		}
	};

	return (
		<>
			<Container width="Standard" className={styles.ContactList_wrapper}>
				{!disableFilter && uniqueCategories.length > 0 ? (
					<div className={styles.ContactList_filter}>
						<FilterWrapper width="narrow" filterText={filterText}>
							<FilterContacts
								categories={uniqueCategories}
								primaryLabel={'Kategorier'}
								onChangeCategory={handleChangeCategory}
								selectedCategories={selectedCategories}
							/>
						</FilterWrapper>
					</div>
				) : null}
				<Container width="Standard" className={classNames(styles.ContactList)}>
					<Heading className={styles.ContactList_heading} headingLevel="h2">
						{headline}
					</Heading>

					<div className={classNames(styles.ContactList_list)}>
						{filteredContacts.map((contact) => (
							<ContactCard key={contact.id} {...contact} />
						))}
					</div>
				</Container>
			</Container>
		</>
	);
};
