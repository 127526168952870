import classNames from 'classnames';
import styles from './ServiceNavigation.module.scss';
import { Link, Icon } from 'ui/components';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';

export interface ServiceNavigationProps {
	className?: string;
	serviceNav?: Umbraco.Link;
	style?: 'primary';
	serviceNavigationItems?: Models.ServiceNavigation;
}

export const ServiceNavigation: React.FC<ServiceNavigationProps> = ({ serviceNavigationItems }) => {
	return (
		<nav className={classNames(styles.ServiceNavigation, 'ServiceNavigation')} role="ServiceNavigation">
			<ul className={styles.ServiceNavigation_list}>
				{serviceNavigationItems &&
					serviceNavigationItems?.serviceNav?.map((navItem, index) => (
						<li key={index} className={classNames(styles.ServiceNavigation_item, 'u-grid__cell')}>
							<Link className={classNames(styles.ServiceNavigation_link)} url={navItem?.content?.properties?.link?.url}>
								{/* Because of umbraco implementation the svg is used as src on a img tag */}
								{navItem?.content.properties.icon?.url && (
									<img className={styles.ServiceNavigation_iconMember} src={navItem?.content.properties.icon?.url} alt="navigationIcon" />
								)}
								<span className={styles.ServiceNavigation_text}>{navItem?.content?.properties?.link?.name}</span>
								<Icon className={styles.ServiceNavigation_icon} size="md">
									<ChevronRight />
								</Icon>
							</Link>
						</li>
					))}
			</ul>
		</nav>
	);
};
