import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, RichText } from 'ui/components';
import styles from './AgreementContent.module.scss';

type AgreementContentProps = {
	content: string;
	expandButtonText: string;
	collapseButtonText: string;
};
export const AgreementContent: React.VFC<AgreementContentProps> = ({ content, expandButtonText, collapseButtonText }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleIsOpen = () => {
		setIsOpen((prevState) => !prevState);
	};

	const [elementHeight, setElementHeight] = useState(0);

	const contentRefd = useRef(null);

	useEffect(() => {
		setElementHeight(contentRefd.current.clientHeight);
	}, [content, elementHeight]);

	const contentIsTall = elementHeight > 400;

	return (
		<div className={styles.container}>
			<div className={classNames(styles['content-container'], { [styles['content-container___open']]: isOpen })}>
				<RichText ref={contentRefd} content={content} />
				{!isOpen && contentIsTall && <div className={classNames(styles.gradient)} />}
			</div>
			{contentIsTall && (
				<div>
					<Button className={classNames(styles.contentButton)} style="tertiary" onClick={toggleIsOpen}>
						{isOpen ? collapseButtonText : expandButtonText}
					</Button>
				</div>
			)}
		</div>
	);
};
