import styles from './ProductCard.module.scss';
import classNames from 'classnames';
import { GridCell, Picture } from 'ui/components';
import { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ProductCardProps {
	className?: string;
	columnWidth: Models.GridCellWidth;
	product: Models.Product;
	imageSizes: string;
}

export const ProductCard: React.FC<ProductCardProps> = ({ className, ...props }) => {
	const { product, imageSizes, columnWidth } = props || {};
	const dictionary = useContext(DictionaryContext);

	return (
		<GridCell desktopWidth={columnWidth} mobileWidth="50" className={classNames(styles.ProductCard, className)}>
			<a className={styles.ProductCard_link} href={product?.link?.url} aria-label={product?.link?.name}>
				<div className={styles.ProductCard_image}>
					<div className={styles.ProductCard_imageBox}>
						<Picture sizes={imageSizes} {...product?.image} aspectRatio={0.75} className={styles.ProductCard_imageElement} />
					</div>
				</div>
				<div className={styles.ProductCard_details}>
					<p className={styles.ProductCard_name}>{product?.name}</p>
					<p className={styles.ProductCard_category}>{product?.category}</p>
				</div>
			</a>
		</GridCell>
	);
};
