import classNames from 'classnames';
import styles from './SearchField.module.scss';
import { Button, FormfieldString } from 'ui/components';
export interface SearchFieldProps {
	className?: string;
	buttonText: string;
	id: string;
	name: string;
	label?: string;
	placeholder?: string;
	register?: any;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
		isHidden?: boolean;
	};
	errorMessage?: string;
}

export const SearchField: React.FC<SearchFieldProps> = ({
	className,
	buttonText,
	label,
	state,
	id,
	name,
	placeholder,
	register,
}) => {
	const { disabled, hasError } = state ?? {};
	state.isHidden = true;
	return (
		<div
			className={classNames(
				styles.SearchField,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<div className={styles.SearchField_wrapper}>
				<FormfieldString
					type="search"
					id={id}
					state={state}
					name={name}
					label={label}
					placeholder={placeholder}
					className={styles.SearchField_input}
					register={register}
				/>
				<Button type="submit" style="primary" disabled={disabled}>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};
