import classNames from 'classnames';
import { Arrangement } from 'application/repositories/arrangementsRepository';
import styles from './ArrangementsBanner.module.scss';
import { ArrangementCard, LinkButton } from 'ui/components';
import React, { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { Spinner } from 'ui/components';

export interface ArrangementsBannerProps {
	className?: string;
	heading?: string;
	arrangements?: Arrangement[];
	seeMoreLink?: Umbraco.Link;
	ctaOneLink?: Umbraco.Link;
	ctaTwoLink?: Umbraco.Link;
	hideComponent?: boolean;
	hasError?: boolean;
}

export const ArrangementsBanner: React.FC<ArrangementsBannerProps> = ({
	hideComponent = false,
	className,
	arrangements,
	heading,
	seeMoreLink,
	ctaOneLink,
	ctaTwoLink,
	hasError = false,
}) => {
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);
	// const { status } = useSession();
	const status = 'authenticated';

	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);

	return hideComponent ? null : (
		<div className={classNames(styles.ArrangementsBanner, className)}>
			<div className={styles.ArrangementsBanner_container}>
				<h2 className={styles.ArrangementsBanner_heading}>{heading}</h2>

				{arrangements.length > 0 && !hasError ? (
					<>
						<div className={styles.ArrangementsBanner_cardContainer}>
							{arrangements?.map((arrangement, index) => {
								return <ArrangementCard key={index} {...arrangement} isLoggedIn={isLoggedIn} />;
							})}
						</div>
						<div className={styles.ArrangementsBanner_buttonContainer}>
							{isLoggedIn && seeMoreLink?.url && (
								<LinkButton style="negative" url={seeMoreLink?.url}>
									{seeMoreLink?.name}
								</LinkButton>
							)}
							{!isLoggedIn && (
								<>
									{ctaOneLink?.url && (
										<LinkButton style="primary" url={ctaOneLink?.url}>
											{ctaOneLink?.name}
										</LinkButton>
									)}
									{ctaTwoLink?.url && (
										<LinkButton style="negative" url={ctaTwoLink?.url}>
											{ctaTwoLink?.name}
										</LinkButton>
									)}
								</>
							)}
						</div>
					</>
				) : hasError ? (
					<div style={{ margin: '0 auto', textAlign: 'center' }}>Arrangementer kunne ikke hentes...</div>
				) : (
					<></> // <Spinner style="largeThin" />
				)}
			</div>
		</div>
	);
};
