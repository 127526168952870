import classNames from 'classnames';
import { Heading, LinkButton, Paragraph, Picture } from 'ui/components';
import styles from './SituationCard.module.scss';
import { ReactComponent as Chevron } from 'ui/icons/icon-chevron-right.svg';

export interface SituationCardProps {
	heading?: string;
	text?: string;
	image?: Umbraco.Image;
	link?: Umbraco.Link;
	isHighlighted?: boolean;
	parent?: string;
}

export const SituationCard: React.FC<SituationCardProps> = ({ link, heading, text, image, isHighlighted, parent = '' }) => {
	const parentIsBanner = parent === 'SituationBanner';
	return (
		<LinkButton
			className={classNames(styles.SituationCard, styles.SituationCard___highlighted, parentIsBanner && styles.SituationCard_parentIsBanner)}
			url={link?.url}
			style="secondary"
		>
			{image && isHighlighted && (
				<div className={classNames(styles.SituationCard_media, 'SituationCard_media')}>
					<span className={styles.SituationCard_mediabox}>
						<Picture
							className={styles.SituationCard_picture}
							aspectRatio={0.7}
							sizes="(min-width: 960px) 50vw, 100vw"
							relativeUrl={image?.relativeUrl}
							properties={image?.properties}
							focalPoint={image?.focalPoint}
						/>
					</span>
				</div>
			)}
			<Heading className={styles.SituationCard_heading} headingLevel="h4">
				{heading}
			</Heading>
			<Paragraph className={styles.SituationCard_text}>{text}</Paragraph>

			{parentIsBanner ? (
				<Chevron className={classNames(styles.SituationCard_chevron, styles.SituationCard_parentIsBanner)} />
			) : (
				<>
					{!isHighlighted && <div className={styles.SituationCard_readMore}>Læs mere</div>}
					<Chevron className={styles.SituationCard_chevron} />
				</>
			)}
		</LinkButton>
	);
};
