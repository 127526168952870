import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import styles from './Header.module.scss';
import { Logo, SkipToMain, Navigation, ServiceNavigation, Container, Grid, GridCell, SoMeLink, SoMeLinkProps } from 'ui/components';

export interface HeaderProps {
	className?: string;
	navHeading?: string;
	serviceNavigationItems?: Models.ServiceNavigation;
	navigationItems?: Models.NavigationItem[];
	navSoMeLinks?: SoMeLinkProps[];
	registerLink?: Models.RegisterBar;
}

export type navState = boolean;

export const Header: React.FC<HeaderProps> = ({ className, navHeading, navigationItems, serviceNavigationItems, navSoMeLinks, registerLink }) => {
	const [navOpen, setNavOpen] = useState<navState>(false);
	const checkboxRef = useRef(null);

	const handleKeyboardNav = (keyDownEvent) => {
		keyDownEvent.preventDefault();
		if (keyDownEvent.keyCode === 32) {
			if (!checkboxRef.current.checked) {
				checkboxRef.current.checked = true;
				setNavOpen(true);
			} else {
				checkboxRef.current.checked = false;
				setNavOpen(false);
			}
		}
	};

	const { status } = useSession();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (status === 'authenticated') {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, [status]);

	const signOutClick = () => {
		signOut({
			redirect: false,
		}).then(() => {
			const redirectUrl = registerLink?.logOutLink?.url ?? window.location.href;
			window.location.href = `https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${redirectUrl}`;
		});
	};
	return (
		<header className={classNames(styles.Header, className)} role="banner">
			<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
				Skip to content
			</SkipToMain>
			<Container width="Full width">
				<Grid nogutter={true} center={true} className={classNames(styles.Header_navigation)}>
					<Logo className={styles.Header_logo} withText={true} />

					{navigationItems && (
						<>
							<input
								onChange={(event: any) => (event.target.checked ? setNavOpen(true) : setNavOpen(false))}
								className={styles.Header_navController}
								type="checkbox"
								id="nav-controller"
								data-ref="header__navController"
								ref={checkboxRef}
							/>
							<label className={styles.Header_navToggle} htmlFor="nav-controller" aria-expanded={navOpen}>
								<span
									className={styles.Header_icon}
									role="navigation"
									aria-haspopup="true"
									aria-expanded={navOpen}
									tabIndex={0}
									onKeyDown={(e) => handleKeyboardNav(e)}
								>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
								</span>
							</label>
							<div className={classNames(styles.Header_nav, navOpen && styles.Header_nav___active)} data-ref="header__navPanel">
								<Grid nogutter={true} wrap={true} className={styles.Header_navWrapperContainer}>
									<GridCell className={styles.Header_navContainer}>
										<Navigation heading={navHeading} navigationItems={navigationItems} />
										<ServiceNavigation serviceNavigationItems={serviceNavigationItems} style="primary" />
									</GridCell>
								</Grid>
								{isLoggedIn && (
									<>
										{registerLink?.logOutLink?.name && (
											<a className={styles.Header_memberLink} onClick={signOutClick}>
												{registerLink?.logOutLink?.name}
											</a>
										)}
									</>
								)}
								<GridCell className={styles.Header_someContainer}>
									{navSoMeLinks &&
										navSoMeLinks.map((navSoMeLink: SoMeLinkProps, index) => (
											<SoMeLink
												key={index}
												platform={navSoMeLink.platform}
												style={navSoMeLink.style}
												title={navSoMeLink.title}
												size={navSoMeLink.size}
											/>
										))}
								</GridCell>
							</div>
						</>
					)}
				</Grid>
			</Container>
		</header>
	);
};
