import styles from './Main.module.scss';
import classNames from 'classnames';
import { PageContext, Page as PageContent } from 'application/adapters/context/PageContext';
import { useContext } from 'react';
import { Breadcrumbs } from 'ui/components/2-molecules/Breadcrumbs';
import { Container } from 'ui/components/4-habitats/Container';

export interface MainProps {
	header?: React.ReactNode;
	children: React.ReactNode;
	className?: string;
	width?: Umbraco.SectionWidth;
	isHeaderBanner?:boolean;
}

export const Main: React.FC<MainProps> = ({ header, children, className,width,isHeaderBanner }) => {
	const page = useContext(PageContext);
	const lang = page?.pageType?.page?.culture;
	
	if (!width)
	{
		width = page?.content?.pageSections[0]?.settings?.properties?.width 
	} 

	// Breadcrumb is wrapped in a standard container, as DEF does'nt want actual full width breadcrumbs
	const breadcrumbs = (
        <Container width="Standard">
            <Breadcrumbs sectionWidth={width} />
        </Container>
    );
	// If the header is a banner, we need the breadcrumb to be below the header
return (
		<main id="main" className={classNames(styles.Main, className)} lang={lang}>
			{!isHeaderBanner && page.name != "Login" && 
			breadcrumbs}
			{header && <header>{header}</header>}
			{ isHeaderBanner && page.name != "Login" && 
			breadcrumbs}
			<article className={classNames(styles.Main_article, 'Main_article')}>
			{children}</article>
		</main>
	);
};
