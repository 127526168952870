export const parallax = () => {
	const setWindowSize = () => {
		const windowSize = document.body.offsetWidth.toString();
		document.documentElement.style.setProperty('--window-size', windowSize);
	};
	setWindowSize();
	window.addEventListener('resize', setWindowSize);
	window.onscroll = () => {
		// parallax class assigns 20rem of extra height
		const containerPatallax = document.querySelectorAll('[data-parallax]');

		containerPatallax.forEach((el) => {
			const targetPatallax = el.querySelector('[data-parallax] picture') as HTMLElement;
			// parallax class assigns 30rem of extra height
			const scrollSpan = 20;
			const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
			const elementDimensions = el.getBoundingClientRect();
			const elementTopPosition = elementDimensions.y;
			const elementBottomPosition = elementTopPosition + elementDimensions.height;

			let relativeTopPosition = elementTopPosition / viewportHeight;
			if (relativeTopPosition > 1) relativeTopPosition = 1;
			if (relativeTopPosition < 0) relativeTopPosition = 0;

			let relativeBottomPosition = elementBottomPosition / viewportHeight;
			if (relativeBottomPosition > 1) relativeBottomPosition = 1;
			if (relativeBottomPosition < 0) relativeBottomPosition = 0;

			const averageRelativePosition = 1 - (relativeTopPosition + relativeBottomPosition) / 2;
			const scrollPosition = scrollSpan * averageRelativePosition - scrollSpan / 2;
			targetPatallax.style.top = scrollPosition + 'rem';
		});
	};
};
