import classNames from 'classnames';
import styles from './SuperHeroCalendarCard.module.scss';
import { Heading, Picture, Icon, AccordionCalendar, RichText } from 'ui/components';
import { ReactComponent as PatternFour } from 'ui/icons/icon-pattern-four.svg';

export interface SuperHeroCalendarCardProps {
	cardLinkUrl?: string;
	className?: string;
	heading?: string;
	image?: Umbraco.Image;
	backgroundImage?: Umbraco.Image;
	module?: {
		title: string;
		tabSubTitle: string;
		items: Models.AccordionItem[];
	}[];
	text?: string;
}

export const SuperHeroCalendarCard: React.FC<SuperHeroCalendarCardProps> = ({ className, heading, image, module, backgroundImage, text }) => {
	return (
		<>
			<div className={classNames(styles.SuperHeroCalendarCard, className)} data-container>
				<Icon className={styles.SuperHeroCalendarCard_pattern}>
					<PatternFour />
				</Icon>
				{/* <a className={styles.SuperHeroCalendarCard_link} href={cardLinkUrl}></a> */}

				<div className={styles.SuperHeroCalendarCard_mediaboxTop_wrapper} data-image>
					<Picture relativeUrl={backgroundImage?.relativeUrl} sizes="100vw" className={styles.SuperHeroCalendarCard_mediaboxTop} />
				</div>

				<div className={styles.SuperHeroCalendarCard_textbox}>
					<Heading headingLevel="h1" style="xl" className={styles.SuperHeroCalendarCard_heading}>
						{heading}
					</Heading>
					<RichText className={classNames(styles.SuperHeroCalendarCard_text, 'RichText RichText___negative', className)} content={text} />
				</div>
				<div className={styles.SuperHeroCalendarCard_mediabox}>
					<AccordionCalendar accordionItems={module} />
				</div>
			</div>
			<section className={styles.SuperHeroCalendarCard_bottomPadding}></section>
		</>
	);
};
