import { Button, Form, FormFieldCheckbox, FormfieldString } from 'ui/components';
import styles from './NewsletterForm.module.scss';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
	email: string;
};

export interface NewsletterFormProps {
	emailPlaceholder?: string;
	emailLabel: string;
	signupButton: boolean;
	signupLabel: string;
	newsletterFormTypes?: string[];
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
	emailLabel,
	emailPlaceholder,
	signupButton = true,
	signupLabel,
	newsletterFormTypes,
}) => {
	const { register, handleSubmit, reset } = useForm<FormData>();
	const onSubmit = (data) => {
		alert(`Thank for submitting, ${JSON.stringify(data)}`);
		reset();
	};
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<p className={styles.NewsletterForm_text}>{emailLabel}</p>
			{newsletterFormTypes ? renderNewsletterFormTypes(newsletterFormTypes) : null}
			{signupButton && (
				<Button type="submit" style="primary">
					{signupLabel}
				</Button>
			)}
		</Form>
	);
};

function renderNewsletterFormTypes(newsletterFormTypes: string[]): ReactElement<any, string> {
	return (
		<div className="u-bottom-margin--sm">
			{newsletterFormTypes.map((renderNewsletterFormType, index) => {
				return (
					<FormFieldCheckbox id={renderNewsletterFormType} key={index} name={renderNewsletterFormType} label={renderNewsletterFormType} />
				);
			})}
		</div>
	);
}

