import classNames from 'classnames';
import styles from './LinkButton.module.scss';

export interface LinkButtonProps {
	children: React.ReactNode;
	state?: 'inactive';
	style?: 'primary' | 'secondary' | 'inactive' | 'sm' | 'tertiary' | 'negative';
	title?: string;
	className?: string;
	url?: string;
	onClick?: any;
	download?: any;
	target?: string;
	ref?: any;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ children, style, title, className, state, url, onClick, download, target, ref }) => (
	<a
		href={url}
		title={title}
		className={classNames(styles.LinkButton, styles[`LinkButton___${style}`], styles[`LinkButton___${state}`], className)}
		download={download}
		target={target}
		ref={ref}
		onClick={onClick}
	>
		{children}
	</a>
);
