import { GetAgreementFilesResult } from 'application/adapters/agreements/agreementsAdapter';
import classNames from 'classnames';
import React from 'react';
import { Container, DownloadBar, GridCell, Icon, Link, Spinner } from 'ui/components';
import { ReactComponent as Plus } from 'ui/icons/icon-plus.svg';
import styles from './DownloadBarContainer.module.scss';

export interface DownloadBarContainerProps {
	className?: string;
	downloadText?: string;
	agreementFiles: GetAgreementFilesResult;
	link?: {
		url: string;
		name: string;
	};
}

export const DownloadBarContainer: React.FC<DownloadBarContainerProps> = ({ className, link, downloadText, agreementFiles }) => {
	return (
		<>
			{!agreementFiles ? (
				<Spinner />
			) : (
				<Container width="Standard" className={classNames(styles.DownloadBarContainer, className)}>
					<GridCell>
						{agreementFiles.agreementFiles.items.map((item, index) => (
							<DownloadBar downloadBarLabel={item.name} url={item.url} downloadText={downloadText} key={index} {...item} />
						))}
						<aside className={styles.DownloadBarContainer_linkWrapper}>
							<Link className={styles.DownloadBarContainer_link} url={link?.url} style="sm" ariaLabel={link?.name}>
								{link?.name}
								<Icon className={styles.DownloadBarContainer_linkIcon}>
									<Plus />
								</Icon>
							</Link>
						</aside>
					</GridCell>
				</Container>
			)}
		</>
	);
};
