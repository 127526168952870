import React from 'react';
import styles from './cludoSearchResults.module.scss';

export interface CludoSearchResultsProps {
	heading?: string;
}

export const CludoSearchResults: React.FC<CludoSearchResultsProps> = ({ heading }) => {
	return (
		<div className={styles.CludoSearchResults}>
			{heading && <h2 className={styles.CludoSearchResults_heading}>{heading}</h2>}
			<div id="cludo-search-results" className="cludo-search-results">
				<div className="cludo-input-form" role="search" id="cludo-search-input">
					<input
						className="cludo-input-form__input"
						title="Search the page"
						name="searchrequest"
						type="search"
						autoComplete="off"
						placeholder="Søg på siden"
						aria-autocomplete="list"
						aria-haspopup="true"
					/>
					<button type="submit" title="Search" className="cludo-input-form__search-button">
						<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M16.991 16.992l8.384 8.383M11.033 19.44a8.408 8.408 0 100-16.815 8.408 8.408 0 000 16.815z"
								stroke="#fff"
								strokeWidth="2"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							></path>
						</svg>
					</button>
				</div>

				<div className="cludo-search-results__layout">
					<div className="cludo-search-results__did-you-mean search-did-you-mean"></div>
					<div className="cludo-search-results__facets search-filters" aria-controls="search-results"></div>

					<div className="cludo-search-results__results-wrapper">
						<div className="cludo-search-results__search-result-count search-result-count" role="status"></div>
						<div
							className="cludo-search-results__results search-results"
							role="region"
							id="search-results"
							aria-live="polite"
							aria-label="Search results"
						></div>
						<div className="cludo-hidden" id="cludo-load-more">
							<a href="javascript:void(0)" className="view-more cludo-search__top-hits-view-more cludo-view-all-button" type="button">
								Vis flere
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
