import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { Icon, LinksProps, Link } from 'ui/components';
import { ReactComponent as IconChevronDown } from 'ui/icons/icon-chevron-down.svg';
import { ReactComponent as IconGlobe } from 'ui/icons/icon-globe.svg';
import styles from './LanguageBar.module.scss';

export interface LanguageBarProps {
	languageBarItems?: Models.LanguageBar;
	languageBarTitle?: Models.LanguageBar;
}
export const LanguageBar: React.FC<LanguageBarProps> = ({ languageBarItems, languageBarTitle }) => {
	const languageRef = useRef(null);
	const [languageListOpen, setLanguageListOpen] = useState(false);
	const openLanguageList = () => {
		setLanguageListOpen(!languageListOpen);
	};
	const handleClickClose = (event) => {
		const element = languageRef.current;
		if (!element.contains(event.target)) {
			setLanguageListOpen(false);
		}
	};

	useEffect(() => {
		if (languageListOpen) {
			document.addEventListener('click', handleClickClose);
		}
		return () => document.removeEventListener('click', handleClickClose);
	}, [languageListOpen]);
	return (
		<>
			<div ref={languageRef} className={styles.LanguageBar_languageLink} onClick={openLanguageList} aria-selected={languageListOpen}>
				<Icon className={styles.LanguageBar_iconGlobe} size="md">
					<IconGlobe />
				</Icon>
				<span>{languageBarTitle.languageTitle}</span>
				<Icon className={styles.LanguageBar_iconChevron} size="md">
					<IconChevronDown />
				</Icon>
			</div>
			{languageListOpen && (
				<aside className={styles.LanguageBar_languageContainer}>
					<ul className={styles.LanguageBar_languageItemContainer}>
						{languageBarItems &&
							languageBarItems.languageNav.map((navItem, index) => (
								<li key={index} className={styles.LanguageBar_languageItems}>
									<Link url={navItem.url} className={styles.LanguageBar_link}>
										<span className={styles.LanguageBar_text}>{navItem.name}</span>
									</Link>
								</li>
							))}
					</ul>
				</aside>
			)}
		</>
	);
};
