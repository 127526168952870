import React from 'react';
import classNames from 'classnames';
import styles from './Slider.module.scss';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderItem, SliderItemProps } from './SliderItem/SliderItem';
import { Heading, Container, Grid, GridCell } from 'ui/components';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export interface SliderProps {
	className?: string;
	sliderHeading: string;
	slides: SliderItemProps[];
	styleRevert?: boolean;
}

export const Slider: React.FC<SliderProps> = ({ slides, sliderHeading, styleRevert }) => {
	return (
		<section className={classNames(styles.Slider_wrapper, { ['Slider_wrapper___revert']: styleRevert })}>
			<Container width="Standard">
				<Heading className={styles.Slider_heading} style="lg" headingLevel="h3">
					{sliderHeading}
				</Heading>
				<Swiper
					speed={160}
					spaceBetween={50}
					slidesPerView={3}
					navigation
					pagination={{
						clickable: true,
						type: 'bullets',
					}}
					className={styles.Slider}
					breakpoints={{
						// when window width is >= 320px
						320: {
							slidesPerView: 1.5,
							spaceBetween: 20,
						},
						// when window width is >= 640px
						640: {
							slidesPerView: 2.5,
							spaceBetween: 40,
						},
						768: {
							slidesPerView: 3.5,
							spaceBetween: 40,
						},
						960: {
							slidesPerView: 4.5,
							spaceBetween: 40,
						},
					}}
				>
					{slides.map((slide, index) => (
						<SwiperSlide key={index} className={styles.Slider_slide}>
							<SliderItem {...slide} hideImage={styleRevert} />
						</SwiperSlide>
					))}
				</Swiper>
			</Container>
		</section>
	);
};
