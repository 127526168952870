import Script from 'next/script';
import { useState } from 'react';
import styles from './IframeFak.module.scss';

export interface IframeFakProps {
	className?: string;
	iframeFakSource?: string;
	bodyScript?: string;
}

export const IframeFak: React.FC<IframeFakProps> = ({ className, iframeFakSource, bodyScript }) => {
	const [jQueryisLoaded, setjQueryisLoaded] = useState(false);
	return (
		<>
			<Script
				src="https://code.jquery.com/jquery-2.2.4.min.js"
				// integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="
				strategy="afterInteractive"
				onLoad={() => {
					setjQueryisLoaded(true);
				}}
				id="jQuery"
			/>
			{jQueryisLoaded && <Script id="iframeScript" dangerouslySetInnerHTML={{ __html: bodyScript }}></Script>}
			<iframe id="dmsIframe" className={styles.IframeFak} src={iframeFakSource} />
		</>
	);
};
