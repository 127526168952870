import classNames from 'classnames';
import styles from './SuperHeroTertiaryCard.module.scss';
import { Heading, Picture, Icon, RichText } from 'ui/components';
import React, { useEffect } from 'react';
import { parallax } from 'helpers/parallax';
import { ReactComponent as PatternFour } from 'ui/icons/icon-pattern-four.svg';

export interface SuperHeroTertiaryCardProps {
	cardActionLinkText?: string;
	cardActionLinkTitle?: string;
	cardActionLinkUrl?: string;
	cardLinkUrl?: string;
	className?: string;
	heading?: string;
	image?: Umbraco.Image;
	backgroundImage?: Umbraco.Image;
	kicker?: string;
	text?: string;
}

export const SuperHeroTertiaryCard: React.FC<SuperHeroTertiaryCardProps> = ({ className, heading, image, backgroundImage, text }) => {
	useEffect(() => {
		parallax;
	}, []);
	return (
		<>
			<div className={classNames(styles.SuperHeroTertiaryCard, className)} data-container>
				<Icon className={styles.SuperHeroTertiaryCard_pattern}>
					<PatternFour />
				</Icon>
				{/* <a className={styles.SuperHeroTertiaryCard_link} href={cardLinkUrl}></a> */}
				{image && (
					<div className={styles.SuperHeroTertiaryCard_mediaboxTop_wrapper} data-image>
						<Picture
							relativeUrl={backgroundImage?.relativeUrl}
							focalPoint={image.focalPoint}
							properties={image.properties}
							sizes="100vw"
							className={styles.SuperHeroTertiaryCard_mediaboxTop}
							aspectRatio={0.56}
						/>
					</div>
				)}
				{image && (
					<Picture relativeUrl={image?.relativeUrl} height={430} properties={image.properties} sizes="100vw" 
					className={styles.SuperHeroTertiaryCard_mediabox} focalPoint={image.focalPoint} aspectRatio={.46} />
				)}
				<div className={styles.SuperHeroTertiaryCard_textbox}>
					<Heading headingLevel="h1" style="xl" className={styles.SuperHeroTertiaryCard_heading}>
						{heading}
					</Heading>
					<RichText className={classNames(styles.SuperHeroTertiaryCard_text, 'RichText RichText___negative', className)} content={text} />
				</div>
			</div>
			<section className={styles.SuperHeroTertiaryCard_bottomPadding}></section>
		</>
	);
};
