import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { DesktopVerticalTabMenuItem } from './DesktopVerticalTabMenuItem/DesktopVerticalTabMenuItem';
import styles from './DesktopVerticalTabMenu.module.scss';

export type DesktopVerticalTabMenuItemValue = string;

type DesktopVerticalTabMenuProps = ComponentProps<'div'> & {
	items: Array<{
		value: DesktopVerticalTabMenuItemValue;
		label: string;
	}>;
	selectedItemValue: DesktopVerticalTabMenuItemValue;
	onItemClick: (value: DesktopVerticalTabMenuItemValue) => void;
};
export const DesktopVerticalTabMenu: React.VFC<DesktopVerticalTabMenuProps> = ({
	items,
	selectedItemValue,
	className,
	onItemClick,
	...restProps
}) => {
	return (
		<div className={classNames(styles.container, className)} {...restProps}>
			{items.map((item) => (
				<DesktopVerticalTabMenuItem key={item.value} selected={selectedItemValue === item.value} onClick={() => onItemClick(item.value)}>
					{item.label}
				</DesktopVerticalTabMenuItem>
			))}
		</div>
	);
};
