import classNames from 'classnames';
import styles from './ShortcutCard.module.scss';
import { Heading, Paragraph, Icon, LinkButton } from 'ui/components';
import { ReactComponent as IconChevronCircle } from 'ui/icons/icon-chevron-circle.svg';

export interface ShortcutCardProps {
	className?: string;
	moduleLink?: Umbraco.Link;
	heading?: string;
	text?: string;
}

export const ShortcutCard: React.FC<ShortcutCardProps> = ({ className, moduleLink, heading, text }) => {
	return (
		<LinkButton
			className={classNames(styles.ShortcutCard, className)}
			url={moduleLink?.url}
			style="secondary"
			target={moduleLink?.target}
			title={moduleLink?.name}
		>
			<Heading className={styles.ShortcutCard_heading} headingLevel="h4">
				{heading}
			</Heading>
			<Paragraph className={styles.ShortcutCard_text}>{text}</Paragraph>
			<Icon className={styles.ShortcutCard_icon}>
				<IconChevronCircle />
			</Icon>
		</LinkButton>
	);
};
