import axios from 'axios';
import { getAccessTokenBrowser } from 'helpers/auth';
import { getApiUrl } from './constants';

export type GetProfilesResult = {
	firstName: string;
	lastName: string;
	address: {
		line1: string;
		line2: string;
		postNumber: string;
		city: string;
		country: string;
		secretAddress: boolean;
	};
	membership: {
		id: string;
		startDate: string;
		category: string;
	};
	branch: {
		id: string;
		name: string;
	};
	notificationCount: number;
	education: {
		start: string;
		completed: string;
		expectedCompleted: string;
		name: string;
		school: string;
	};
	employment: {
		date: string;
		employer: {
			pNumber: string;
			postCode: string;
			cvr: string;
			name: string;
			agreement: {
				id: number;
				name: string;
				link: string;
			};
		};
		jobTitle: string;
	};
	duties: string[];
	privateContact: {
		email: string;
		phone: string;
	};
	workContact: {
		email: string;
		phone: string;
	};
	insurance: string;
	unionRepresentative: {
		firstname: string;
		lastname: string;
		duty: string;
		workContactInformation: {
			email: string;
			phone: string;
		};
		privateContactInformation: {
			email: string;
			phone: string;
		};
	};
	healthAndSafetyRepresentative: {
		firstname: string;
		lastname: string;
		duty: string;
		workContactInformation: {
			email: string;
			phone: string;
		};
		privateContactInformation: {
			email: string;
			phone: string;
		};
	};
	canSeeUnionRepresentativeWebsite: boolean;
};

export type RequestHeaders = {
	hostname: string;
	Authorization?: string;
};

export const getProfilesAdapter = async (hostname: string): Promise<GetProfilesResult> => {
	const accessToken = await getAccessTokenBrowser();
	const headers: RequestHeaders = {
		hostname,
	};
	if (accessToken) {
		headers.Authorization = 'Bearer ' + accessToken;
	}
	const { href } = getApiUrl('Profile');
	const { data: profiles } = await axios.get<GetProfilesResult>(href, { headers: headers });
	return profiles;
};
