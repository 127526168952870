import classNames from 'classnames';
import styles from './Navigation.module.scss';
import { Heading, Link } from 'ui/components';

export interface NavigationProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItems?: Models.NavigationItem[];
}

export const Navigation: React.FC<NavigationProps> = ({ className, style, heading, navigationItems }) => {
	return (
		<nav className={classNames(styles.Navigation, styles[`Navigation___${style}`], className)} role="navigation">
			<Heading headingLevel="h6" className={styles.Navigation_heading}>
				{heading}
			</Heading>
			<ul className={styles.Navigation_list}>
				{navigationItems &&
					navigationItems.map((navigationItem: Models.NavigationItem, index) => (
						<li key={index} className={classNames(styles.Navigation_item, 'u-grid__cell')}>
							<Link
								className={classNames(styles.Navigation_link, {
									[styles.isActive]: navigationItem.state === 'isActive',
								})}
								url={navigationItem.url}
							>
								{navigationItem.title}
							</Link>
						</li>
					))}
			</ul>
		</nav>
	);
};
