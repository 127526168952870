import classNames from 'classnames';
import { Link, Icon, Button } from 'ui/components';
import { useRef, useState, useContext, useEffect } from 'react';
import { ReactComponent as IconLoop } from 'ui/icons/icon-loop.svg';
import { ReactComponent as IconLock } from 'ui/icons/icon-lock.svg';
import { AppStateContext } from 'application/adapters/appState/appState';
import styles from './NavigationDef.module.scss';

export interface NavigationDefProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItemsDef?: Models.NavigationItem[];
	handleOpenSearch?: () => void;
}

export const NavigationDef: React.FC<NavigationDefProps> = ({ className, style, navigationItemsDef, handleOpenSearch }) => {
	const { toggleShowLoginModal } = useContext(AppStateContext);
	return (
		<nav className={classNames(styles.NavigationDef, styles[`NavigationDef___${style}`], className)} role="NavigationDef">
			<ul className={styles.NavigationDef_list}>
				{navigationItemsDef &&
					navigationItemsDef.map((navigationDefItem: Models.NavigationItem, index) => (
						<li key={index} className={classNames(styles.NavigationDef_item)}>
							<Link
								className={classNames(styles.NavigationDef_link, {
									[styles.isActive]: navigationDefItem.state === 'isActive',
								})}
								url={navigationDefItem.url}
								title={navigationDefItem.title}
							>
								<span className={styles.NavigationDef_title}>{navigationDefItem.title}</span>
							</Link>
						</li>
					))}

				<li
					className={classNames(styles.NavigationDef_item, styles.NavigationDef_item___secondary, 'u-grid__cell')}
					onClick={handleOpenSearch}
				>
					<Link className={styles.NavigationDef_link}>
						<span className={styles.NavigationDef_title}>søg</span>
						<Icon className={styles.Navigation_iconLoop} size="md">
							<IconLoop />
						</Icon>
					</Link>
				</li>
				<li className={classNames(styles.NavigationDef_item, styles.NavigationDef_item___secondary, 'u-grid__cell')}>
					<Button className={styles.NavigationDef_link} onClick={toggleShowLoginModal} style="primary">
						<span className={styles.NavigationDef_title}>LOG IN</span>
						<Icon className={styles.Navigation_iconLock} size="md">
							<IconLock />
						</Icon>
					</Button>
				</li>
			</ul>
		</nav>
	);
};
