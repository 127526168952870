import classNames from 'classnames';
import styles from './CardLabel.module.scss';
import { Heading } from 'ui/components';

export interface CardLabelProps {
	className?: string;
	title?: string;
	backgroundColor?: string;
}

export const CardLabel: React.FC<CardLabelProps> = ({ className, title, backgroundColor }) => {
	return (
		<div
			className={classNames(styles.CardLabel_container, className)}
			style={{
				backgroundColor: `${backgroundColor}`,
			}}
		>
			<Heading headingLevel="h6" style="default" className={styles.CardLabel_title}>
				{title}
			</Heading>
		</div>
	);
};

