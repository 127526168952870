import classNames from 'classnames';
import styles from './ShoppingCartItem.module.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Picture } from 'ui/components';
import { ReactComponent as DeleteIcon } from 'ui/icons/icon-trash.svg';
import { getProductById } from 'application/adapters/store/slices/productsSlice';
import { useSelector } from 'react-redux';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ShoppingCartItemProps {
	className?: string;
	cartProduct: Models.CartProduct;
	removeProduct: (cartProduct: Models.CartProduct) => void;
	addProduct: (cartProduct: Models.CartProduct) => void;
	decrementProduct: (cartProduct: Models.CartProduct) => void;
}

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
	className,
	cartProduct,
	removeProduct,
	addProduct,
	decrementProduct,
}) => {
	const product = useSelector(getProductById(cartProduct.productId));
	const [price, setPrice] = useState(0);

	const dictionary = useContext(DictionaryContext);

	const variant = product?.variants?.find((variant) => {
		return variant?.id === cartProduct?.variantId;
	});

	useEffect(() => {
		const price = variant?.price * cartProduct?.quantity;
		setPrice(price);
	}, [cartProduct, variant?.price]);

	return (
		<div className={classNames(styles.ShoppingCartItem, className)}>
			<a className={styles.ShoppingCartItem_image} href={product?.link?.url} aria-label={product?.link?.name}>
				<Picture
					sizes="33vw"
					{...product?.image}
					aspectRatio={0.75}
					className={styles.ProductCard_imageElement}
				/>
			</a>
			<div className={styles.ShoppingCartItem_info}>
				<div className={styles.ShoppingCartItem_section}>
					<p className={styles.ShoppingCartItem_title}>{product?.name}</p>
					<button
						className={styles.ShoppingCartItem_deleteButton}
						onClick={() => removeProduct(cartProduct)}
						aria-label={dictionary.getValue('Product.RemoveAll', null, 'Remove all products')}
					>
						<Icon size="md">
							<DeleteIcon />
						</Icon>
					</button>
				</div>
				<p>{variant?.name}</p>
				<div className={styles.ShoppingCartItem_section}>
					<div className={styles.ShoppingCartItem_quantity}>
						<button
							className={styles.ShoppingCartItem_quantityButton}
							onClick={() => decrementProduct(cartProduct)}
							aria-label={dictionary.getValue('Product.Remove', null, 'Remove a product')}
						>
							-
						</button>
						<p>
							{dictionary.getValue('Product.Quantity', null, 'Quantity')}: {cartProduct?.quantity}
						</p>
						<button
							className={styles.ShoppingCartItem_quantityButton}
							onClick={() => addProduct(cartProduct)}
							aria-label={dictionary.getValue('Product.Add', null, 'Add a product')}
						>
							+
						</button>
					</div>
					<p>
						{price} {dictionary.getValue('Price.Currency', null, 'DKK')}
					</p>
				</div>
			</div>
		</div>
	);
};
