// filter component that handles popup logic and passes its props to child component
import React, { useState } from 'react';
import { Form, Icon } from 'ui/components';
import styles from './FilterWrapper.module.scss';
import { ReactComponent as CloseIcon } from 'ui/icons/icon-close.svg';
import { ReactComponent as FilterIcon } from 'ui/icons/icon-filter.svg';
import classNames from 'classnames';

export interface FilterWrapperProps {
	children: React.ReactNode;
	className?: string;
	filterText?: string;
	width?: 'narrow' | 'wide';
}

export const FilterWrapper: React.FC<FilterWrapperProps> = ({ children, width = 'wide', filterText = 'Filter listevisning' }) => {
	const [showOverlay, setShowOverlay] = useState(false);

	const overlayClick = () => {
		setShowOverlay(!showOverlay);
	};

	return (
		<>
			<div className={styles.FilterWrapper_overlay} aria-expanded={showOverlay} onClick={() => overlayClick()}></div>
			{!showOverlay && (
				<button className={classNames(styles.FilterWrapper_button, width == 'narrow' && styles.FilterWrapper_button_narrow)} onClick={overlayClick}>
					<span className={styles.FilterWrapper_buttonText}>{filterText}</span>
					<Icon className={styles.FilterWrapper_iconOpen} size="md" aria-label="Open dialog">
						<FilterIcon />
					</Icon>
				</button>
			)}
			{showOverlay && (
				<div className={classNames(styles.FilterWrapper, width == 'narrow' && styles.FilterWrapper__narrow)}>
					<button onClick={overlayClick} className={styles.FilterWrapper_button}>
						<span className={styles.FilterWrapper_buttonText}>{filterText}</span>
						<Icon className={styles.FilterWrapper_iconClose} size="md" aria-label="Close dialog">
							<CloseIcon />
						</Icon>
					</button>
					{children}
				</div>
			)}
		</>
	);
};
