import styles from './ArticleDate.module.scss';
import { formatDate, formatTime } from 'helpers/date';

export interface ArticleDateProps {
	className?: string;
	date: string;
	updatedDate: Date;
}

export const ArticleDate: React.FC<ArticleDateProps> = ({ date, updatedDate }) => {
	// Formating date to make a string comparision for null value
	const formatedDate = formatDate(updatedDate, 'dd.mm.yyyy');
	const nullDate = formatedDate === '31.12.0' || formatedDate === '01.01.1';

	return (
		<>
			<p className={styles.ArticleDate}>
				{Intl.DateTimeFormat('da-DK', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}).format(new Date(date))}
			</p>
			{!nullDate && (
				<p className={styles.ArticleUpdatedDate}>
					Opdateret{' '}
					{Intl.DateTimeFormat('da-DK', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					}).format(new Date(updatedDate))}{' '}
					kl. {formatTime(updatedDate, 'hh.mm')}
				</p>
			)}
		</>
	);
};
