import { ContentCard } from 'ui/components/2-molecules';

export interface SliderItemProps {
	link?: {
		url: string;
		name: string;
		target?: string;
	};
	image?: Umbraco.Image;
	heading: string;
	text: string;
	// This props is used to hide the image in the Slider component
	hideImage?: boolean;
}

export const SliderItem: React.FC<SliderItemProps> = ({ heading, text, image, link, hideImage }) => (
	<ContentCard heading={heading} text={text} image={image} link={link} hideImage={hideImage} />
);
