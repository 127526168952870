import classNames from 'classnames';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
	style?: 'large' | 'largeThin' | 'slow' | 'error';
	color?: 'white' | 'blue';
	className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ style, className, color = 'blue' }) => (
	<>
		<div className={classNames(styles.Spinner_screenReader, styles.Spinner_loadingMsg)} role="alert">
			loading...
		</div>
		<div className={classNames(styles.Spinner, styles[`Spinner___${style}`], styles[`Spinner___${color}`], className)} aria-hidden="true"></div>
	</>
);
