import classNames from 'classnames';
import styles from './Timeline.module.scss';

export interface TimelineProps {
	className?: string;
	timelineText?: string;
	time?: string;
}

export const Timeline: React.FC<TimelineProps> = ({ className, timelineText, time }) => {
	return (
		<li className={classNames(styles.Timeline, className)}>
			<span className={styles.Timeline_time}>{time}</span>
			<span className={styles.Timeline_content}>{timelineText}</span>
		</li>
	);
};
