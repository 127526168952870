import { RichText } from 'ui/components/1-atoms/RichText';
import { useState } from 'react';
import styles from './AccordionFaqItem.module.scss';
import { generateId } from 'helpers/id';
import { ReactComponent as AccordianIconOpen } from 'ui/icons/accordian-icon-open.svg';
import { ReactComponent as AccordianIconClosed } from 'ui/icons/accordian-icon-closed.svg';

export type AccordionFaqItemProps = {
	openByDefault?: boolean;
	title: string;
	htmlContent: string;
};

// TODO: Mangler håndtering af allowMultiple: true/false
export const AccordionFaqItem: React.FC<AccordionFaqItemProps> = ({ title, htmlContent, openByDefault = false }) => {
	const id = generateId();
	const [open, isOpen] = useState(openByDefault);
	const toggle = () => isOpen(!open);

	return (
		<>
			<h3 className={styles.AccordionFaqItem_triggerWrapper} aria-expanded={open}>
				<button
					onClick={toggle}
					aria-expanded={open}
					className={styles.AccordionFaqItem_trigger}
					aria-controls={`panel-${id}`}
					id={`trigger-${id}`}
				>
					<span className={styles.AccordionFaqItem_title}>
						{title}
						{open ? (
							<AccordianIconOpen className={styles.AccordionFaqItem_icon} />
						) : (
							<AccordianIconClosed className={styles.AccordionFaqItem_icon} />
						)}
					</span>
				</button>
			</h3>
			<article id={`panel-${id}`} role="region" aria-labelledby={`trigger-${id}`} className={styles.AccordionFaqItem_panel} hidden={!open}>
				<RichText content={htmlContent} className={styles.AccordionFaqItem_content} />
			</article>
		</>
	);
};
