import classNames from 'classnames';
import styles from './ArticleMedia.module.scss';
import { Container, Grid, GridCell, Picture } from 'ui/components';

export interface ArticleMediaProps {
	className?: string;
	image: Umbraco.Image;
	caption: string;
}

export const ArticleMedia: React.FC<ArticleMediaProps> = ({ className, image, caption }) => (
	<GridCell>
		<figure>
			{image && (
				<span className={styles.ArticleMedia_mediabox}>
					<Picture
						aspectRatio={0.4}
						focalPoint={image.focalPoint}
						relativeUrl={image.relativeUrl}
						properties={image.properties}
						sizes="100vw"
						className={styles.ArticleMedia_mediabox}
					/>
				</span>
			)}
			<div className="">
				<figcaption className={styles.ArticleMedia_caption}>{caption}</figcaption>
			</div>
		</figure>
	</GridCell>
);
