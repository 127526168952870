import { Profiles } from 'application/repositories/profilesRepository';
import { Arrangement } from 'application/repositories/arrangementsRepository';
import classNames from 'classnames';
import { formatDate } from 'helpers/date';
import React from 'react';
import { Container, Heading, Spinner, Link, GridCell } from 'ui/components';
import styles from './WelcomeCardProfile.module.scss';
export interface WelcomeCardProfileProps {
	className?: string;
	profiles: Profiles;
	arrangements?: Arrangement[];
	welcomeTitle?: string;
	memberNumberTitle?: string;
	contactTitle?: string;
	workContactTitle?: string;
	arrangmentContactTitle?: string;
	chairedTitle?: string;
	environmentTitle?: string;
	companyRepresentTitle?: string;
	primaryLink?: Umbraco.Link;
	secondaryLinkText?: string;
	agreementDefaultLink?:Umbraco.Link;
	tertiaryLink?: Umbraco.Link;
	branchUrl?: string;
}

export const WelcomeCardProfile: React.FC<WelcomeCardProfileProps> = ({
	className,
	profiles,
	arrangements,
	welcomeTitle,
	memberNumberTitle,
	contactTitle,
	workContactTitle,
	arrangmentContactTitle,
	chairedTitle,
	environmentTitle,
	companyRepresentTitle,
	primaryLink,
	secondaryLinkText,
	agreementDefaultLink,
	tertiaryLink,
	branchUrl
}) => {
	return (
		<>
			{!profiles ? (
				<Spinner />
			) : (
				<Container className={classNames(styles.WelcomeCardProfile, className)} width="Full width">
					<GridCell>
						<section className={styles.WelcomeCard_top}>
							<div className={styles.WelcomeCard_name}>
								<Heading headingLevel="h2" className={styles.WelcomeCard_heading}>
									<span className={styles.WelcomeCard_greeting}>{welcomeTitle}</span>
									<span className={styles.WelcomeCard_name}>{profiles?.firstName}</span>
								</Heading>
							</div>
							<div className={styles.WelcomeCardProfile_memberNumber}>
								<span className={styles.WelcomeCardProfile_memberNumberTitle}>{memberNumberTitle}</span>
								<span className={styles.WelcomeCardProfile_memberNumberValue}>{profiles?.membership?.id}</span>
							</div>
						</section>
					</GridCell>
					<section className={styles.WelcomeCardProfile_sectionWrapper}>
						<section className={styles.WelcomeCardProfile_section}>
							<p className={styles.WelcomeCardProfile_sectionTitle}>{contactTitle}</p>
							<aside className={styles.WelcomeCardProfile_content}>
								<p className={styles.WelcomeCardProfile_contentTitle}>{chairedTitle}</p>
								<span className={styles.WelcomeCardProfile_contentValue}>
									{profiles?.unionRepresentative?.firstname} {profiles?.unionRepresentative?.lastname}
								</span>
							</aside>
							<aside className={styles.WelcomeCardProfile_content}>
								<p className={styles.WelcomeCardProfile_contentTitle}>{environmentTitle}</p>
								<span className={styles.WelcomeCardProfile_contentValue}>
									{profiles?.healthAndSafetyRepresentative?.firstname} {profiles?.healthAndSafetyRepresentative?.lastname}
								</span>
							</aside>
							<div className={styles.WelcomeCardProfile_linkWrapper}>
								<Link className={styles.WelcomeCardProfile_link} url={branchUrl ?branchUrl: primaryLink?.url} style="sm" ariaLabel={tertiaryLink?.name}>
									{primaryLink?.name}
								</Link>
							</div>
						</section>
						<section className={styles.WelcomeCardProfile_section}>
							<p className={styles.WelcomeCardProfile_sectionTitle}>{workContactTitle}</p>
							<aside className={classNames(styles.WelcomeCardProfile_content, styles.WelcomeCardProfile_content___workPrimary)}>
								<span className={styles.WelcomeCardProfile_contentValue}>{profiles?.employment?.employer?.name}</span>
							</aside>
							<aside className={classNames(styles.WelcomeCardProfile_content, styles.WelcomeCardProfile_content___workSecondary)}>
								<p className={styles.WelcomeCardProfile_contentTitle}>{companyRepresentTitle}</p>
								<span className={classNames(styles.WelcomeCardProfile_contentValue, styles.WelcomeCardProfile_contentValue___details)}>
									{profiles?.employment?.employer?.agreement?.name}
								</span>
							</aside>
							<div className={styles.WelcomeCardProfile_linkWrapper}>
								{secondaryLinkText && (
									<a className={styles.WelcomeCardProfile_link} href={profiles?.employment?.employer?.agreement?.link ||agreementDefaultLink?.url}>
										{secondaryLinkText}
									</a>
								)}
							</div>
						</section>
						<section className={styles.WelcomeCardProfile_section}>
							<p className={styles.WelcomeCardProfile_sectionTitle}>{arrangmentContactTitle}</p>

							{arrangements &&
								arrangements.slice(0, 2).map((item) => (
									<aside
										key={item.title}
										className={classNames(styles.WelcomeCardProfile_content, styles.WelcomeCardProfile_content___subscriptionPrimary)}
									>
										<p className={styles.WelcomeCardProfile_contentTitle}>{item.date}</p>
										<span className={styles.WelcomeCardProfile_contentValue}>{item.title}</span>
									</aside>
								))}

							<div className={styles.WelcomeCardProfile_linkWrapper}>
								<Link className={styles.WelcomeCardProfile_link} url={tertiaryLink?.url} style="sm" ariaLabel={tertiaryLink?.name}>
									{tertiaryLink?.name}
								</Link>
							</div>
						</section>
					</section>
				</Container>
			)}
		</>
	);
};
