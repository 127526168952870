import styles from './FilterContacts.module.scss';
import { useState } from 'react';
import { Form, FormFieldCheckbox } from 'ui/components';
import { Member } from 'application/repositories/membersRepository';

export interface FilterContactsProps {
	className?: string;
	primaryLabel?: string;
	onChangeCategory: (e) => void;
	categories?: Array<string>;
	selectedCategories?: string[];
}

export const FilterContacts: React.FC<FilterContactsProps> = ({ primaryLabel, onChangeCategory, categories, selectedCategories, className }) => {
	return (
		<>
			<Form className={styles.FilterContacts}>
				<fieldset className={styles.FilterContacts_fieldset}>
					<aside className={styles.FilterContacts_primary}>
						<legend className={styles.FilterContacts_legend}>{primaryLabel}</legend>
						{categories.map((category, index) => {
							return (
								<FormFieldCheckbox
									key={index}
									id={category}
									name={category}
									label={category}
									checked={selectedCategories.includes(category)}
									onChange={onChangeCategory}
									className={styles.FilterContacts_checkbox}
								/>
							);
						})}
					</aside>
				</fieldset>
			</Form>
		</>
	);
};
