export const scrollDetector = () => {
	let scrollpos = window.scrollY;
	const header = document.querySelector('header');
	const main = document.querySelector('main');

	const scrollChange = 100;
	if (main) {
		header.style.transition = 'background-color .5s';
		header.style.backgroundColor = 'transparent';
		main.style.marginTop = '-192px';
	}

	const add_class_on_scroll = () => (header.style.backgroundColor = '#000064');
	const remove_class_on_scroll = () => (header.style.backgroundColor = 'transparent');

	window.addEventListener('scroll', function () {
		scrollpos = window.scrollY;

		if (scrollpos >= scrollChange) {
			add_class_on_scroll();
		} else {
			remove_class_on_scroll();
		}
	});
};
