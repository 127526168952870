import classNames from 'classnames';
import { Heading, Picture, Link, RichText } from 'ui/components';
import styles from './ContentCard.module.scss';

export interface ContentCardProps {
	link?: {
		url: string;
		name: string;
		target?: string;
	};
	image?: Umbraco.Image;
	heading: string;
	text: string;
	// This props is used to hide the image in the Slider component
	hideImage?: boolean;
}

export const ContentCard: React.FC<ContentCardProps> = ({ heading, text, image, link, hideImage = false }) => {
	const ContentWithoutLink = (
	  <>
		{image && !hideImage && (
		  <div className={classNames(styles.ContentCard_media, 'ContentCard_media')}>
			<span className={styles.ContentCard_mediabox}>
			  <Picture
				className={styles.ContentCard_picture}
				aspectRatio={0.75}
				relativeUrl={image.relativeUrl}
				properties={image.properties}
				focalPoint={image.focalPoint}
			  />
			</span>
		  </div>
		)}
		<article className={classNames(styles.ContentCard_textbox, 'ContentCard_textbox')}>
		  <RichText className={classNames(styles.ContentCard_text, 'RichText')} content={text} />
		  <Heading className={styles.ContentCard_heading} headingLevel="h3">
			{heading}
		  </Heading>
		</article>
	  </>
	);
  
	return (
	  <div>
		{link ? (
		  <Link target={link.target} url={link.url} className={styles.ContentCard_link} ariaLabel={link.name}>
			{ContentWithoutLink}
		  </Link>
		) : (
		  <div>
			{ContentWithoutLink}
		  </div>
		)}
	  </div>
	);
  };
  
